import { Actions, ActionTypes, DeleteTaxTypesAction, DeleteTaxTypesCompleteAction, DeleteTaxTypesFailureAction, DeleteTaxTypesSuccessAction, GetSettingHistory, GetSettingHistoryFailure, GetSettingHistorySuccess, ResetGlobalSettingsStatus, ResetSettingHistoryStatus, UpdateTaxTypeAction, UpdateTaxTypeCompleteAction, UpdateTaxTypeFailureAction, UpdateTaxTypeSuccessAction } from '../actions/settings';
import {
  SuccessStatus,
  GSettingsTabSelection,
  DayOfWeek,
  AccountActive
} from '../../shared/enums';
import { TaxType, UserSettings } from '../../models/user-settings';
import { ExternalInvoice } from '../../models/external-invoice';
import { createReducer, on } from '@ngrx/store';
import { PagedResponse } from 'app/models/pagination';
import { SettingHistoryItem } from 'app/models/setting-history';

export interface State {
  showProfileAndGlobalSettingsCard: boolean;
  showChangePasswordCard: boolean;
  showInvoiceDialog: boolean;
  loading: SuccessStatus;
  inputFieldStatuses: [SuccessStatus];
  globalSettingsModel: UserSettings;
  selectedTab?: GSettingsTabSelection;
  selectedInvoiceProvider?: ExternalInvoice;
  weekEnd: DayOfWeek;
  receivedSettingsFromServer: boolean;
  pageAccessDisabled: boolean;
  uploadLogoStatus: SuccessStatus;
  taxtTypesStatus: SuccessStatus
  logoUrl: string;
  settingHistory: PagedResponse<SettingHistoryItem>,
  settingHistoryStatus: SuccessStatus
}

export const initialState: State = {
  showProfileAndGlobalSettingsCard: true,
  showChangePasswordCard: false,
  showInvoiceDialog: false,
  loading: null,
  inputFieldStatuses: [null],
  globalSettingsModel: new UserSettings({ weekStart: DayOfWeek.Sunday }),
  selectedTab: GSettingsTabSelection.Profile,
  selectedInvoiceProvider: new ExternalInvoice(),
  weekEnd: null,
  receivedSettingsFromServer: false,
  pageAccessDisabled: false,
  uploadLogoStatus: null,
  taxtTypesStatus: null,
  logoUrl: null,
  settingHistory: new PagedResponse<SettingHistoryItem>(),
  settingHistoryStatus: SuccessStatus.Enabled
};

const settingsReducer = createReducer(
  initialState,
  on(
    UpdateTaxTypeAction,
    DeleteTaxTypesAction,
    (state, action) => ({
      ...state,
      taxtTypesStatus: SuccessStatus.IsLoading
    })
  ),
  on(
    UpdateTaxTypeSuccessAction,
    (state, action) => {
      const taxType = new TaxType(action.payload);
      let taxTypes = state.globalSettingsModel.taxTypes;
      taxTypes = taxTypes.some(s => s.id === taxType.id) 
          ? taxTypes.map(s => s.id === taxType.id ? taxType : s)
          : [...taxTypes, taxType];
  
      return {
        ...state,
        taxtTypesStatus: SuccessStatus.Success,
        globalSettingsModel: {
          ...state.globalSettingsModel,
          taxTypes: taxTypes
        }
      };
    }
  ),
  on(
    DeleteTaxTypesSuccessAction,
    (state, action) => {
      let taxTypes = state.globalSettingsModel.taxTypes;
      taxTypes = taxTypes.filter(s => action.payload.every(t => t.id !== s.id));
      return {
        ...state,
        taxtTypesStatus: SuccessStatus.Success,
        globalSettingsModel: {
          ...state.globalSettingsModel,
          taxTypes: taxTypes
        }
      };
    }
  ),
  on(
    UpdateTaxTypeFailureAction,
    DeleteTaxTypesFailureAction,
    (state, action) => ({
      ...state,
      taxtTypesStatus: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
    })
  ),
  on(
    UpdateTaxTypeCompleteAction,
    DeleteTaxTypesCompleteAction,
    (state, action) => ({
      ...state,
      taxtTypesStatus: null
    })
  ),
  on(
    GetSettingHistory,
    (state) => ({
      ...state,
      settingHistoryStatus: SuccessStatus.IsLoading
    })
  ),
  on(
    GetSettingHistorySuccess,
    (state, action) => ({
      ...state,
      settingHistoryStatus: SuccessStatus.Success,
      settingHistory: action.history
    })
  ),
  on(
    GetSettingHistoryFailure,
    (state, action) => ({
      ...state,
      loading: !action.error.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
    })
  ),
  on(
    ResetSettingHistoryStatus,
    (state) => ({
      ...state,
      settingHistoryStatus: SuccessStatus.Enabled
    })
  ),
  on(
    ResetGlobalSettingsStatus,
    (state) => ({
      ...state,
      loading: null
    })
  )
)

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    /*
      This action gets called when the user opens the dialog, if the current state of
      the invoice connection status is 'null' or 'disconnect', it means the user has
      never connected to such service, therefore it sets the state of the invoice connection
      to 'disconnect', otherwise to 'connect'
     */
    case ActionTypes.OPENINVOICEDIALOG:
    case ActionTypes.OPENINVOICEDIALOGSUCCESS: {
      // let updatedInvoiceProvider = new ExternalInvoice();
      // let connStatus = null;
      // if (state.selectedInvoiceProvider) {
      //   updatedInvoiceProvider = Object.assign({}, state.selectedInvoiceProvider);
      //   const invoiceName = state.selectedInvoiceProvider.name;
      //   if (invoiceName === action.payload.name) { // user clicks on the same invoice provider
      //     connStatus = state.selectedInvoiceProvider.connectionStatus;
      //   }
      //   updatedInvoiceProvider = Object.assign({
      //     name: action.payload.name,
      //     connectionStatus: connStatus,
      //     isLoading: SuccessStatus.IsLoading
      //   });
      // }

      return Object.assign({}, state, {
        showProfileAndGlobalSettingsCard: true,
        showChangePasswordCard: false,
        showInvoiceDialog: true,
        // selectedInvoiceProvider: updatedInvoiceProvider,
      });
    }
    /* When the user clicks on an invoice, the dialog pops up, this action
    * is for the event when the user decides to connect/disconnect the current
    * selected invoice dialog and updates accordingly. */

    case ActionTypes.CLOSEINVOICEDIALOG: {
      let updatedInvoiceProvider = new ExternalInvoice();
      if (state.globalSettingsModel) {
        updatedInvoiceProvider = Object.assign({}, state.selectedInvoiceProvider);
      }
      return Object.assign({}, state, {
        showInvoiceDialog: false,
        selectedInvoiceProvider: updatedInvoiceProvider,
      });
    }

    case ActionTypes.UPDATEGLOBALSETTINGS: {
      return Object.assign({}, state, {
        loading: SuccessStatus.IsLoading,
        globalSettingsModel: action.payload
      });
    }
    case ActionTypes.UPDATEGLOBALSETTINGSSUCCESS: {
      return Object.assign({}, state, {
        loading: SuccessStatus.Success,
        receivedSettingsFromServer: true
      });
    }
    case ActionTypes.UPDATEGLOBALSETTINGSFAILED: {
      return Object.assign({}, state, {
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
      });
    }
    case ActionTypes.UPDATEGLOBALUSERSETTINGSTAB: {
      const tab = action.payload;
      if (tab === undefined) {
        return Object.assign({}, state, {
          selectedTab: GSettingsTabSelection.Profile,
        });
      }
      return Object.assign({}, state, {
        selectedTab: tab,
      });
    }

    case ActionTypes.GETUSERSETTINGSSUCCESS: {
      let gSettingsModel = new UserSettings(action.payload);
      gSettingsModel.isTrialBannerHidden = false;
      gSettingsModel = Object.assign({}, state.globalSettingsModel, gSettingsModel);
      return Object.assign({}, state, {
        loading: false,
        globalSettingsModel: gSettingsModel,
        receivedSettingsFromServer: true
      });
    }

    case ActionTypes.GETUSERSETTINGS: {
      return {
        ...state,
        receivedSettingsFromServer: false
      }
    }

    case ActionTypes.HIDETRIALBANNER: {
      const gSettingsModel = Object.assign({}, state.globalSettingsModel);
      gSettingsModel.isTrialBannerHidden = true;
      return Object.assign({}, state, {
        globalSettingsModel: gSettingsModel
      })
    }

    case ActionTypes.SUBMITCLOSEACCOUNT: {
      return Object.assign({}, state, { loading: SuccessStatus.IsLoading });
    }

    case ActionTypes.SUBMITCLOSEACCOUNTSUCCESS: {
      return Object.assign({}, state, { loading: SuccessStatus.Success });
    }

    case ActionTypes.SUBMITCLOSEACCOUNTFAILURE: {
      return Object.assign({}, state, {
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
      });
    }

    case ActionTypes.SHOWREACTIVATEACCOUNTDIALOGSUCCESS: {
      return {
        ...state,
        pageAccessDisabled: action.payload
      };
    }

    case ActionTypes.HIDEREACTIVATEACCOUNTDIALOG: {
      return {
        ...state,
        pageAccessDisabled: false
      }
    }

    case ActionTypes.UPLOADLOGOREQUEST: {
      return {
        ...state,
        uploadLogoStatus: SuccessStatus.IsLoading,
        logoUrl: null
      };
    }

    case ActionTypes.UPLOADLOGOSUCCESS: {
      return {
        ...state,
        uploadLogoStatus: SuccessStatus.Success,
        logoUrl: action.payload.logoUrl
      };
    }

    case ActionTypes.UPLOADLOGOFAILURE: {
      return {
        ...state,
        uploadLogoStatus: SuccessStatus.Error,
        logoUrl: null
      };
    }

    default:
      return settingsReducer(state, action);;
  }
}

export const getProfileAndGlobalState = (state: State) => state.showProfileAndGlobalSettingsCard;
export const getChangePasswordState = (state: State) => state.showChangePasswordCard;
export const getGSettingsInvoiceDialog = (state: State) => state.showInvoiceDialog;
export const getGSettingsIsLoading = (state: State) => state.loading;
export const getGSettingsLoadingFields = (state: State) => state.inputFieldStatuses;
export const getGSettingsGlobalSettingsModel = (state: State) => state.globalSettingsModel;
export const getGSettingsActiveTab = (state: State) => state.selectedTab;
export const getGSettingsSelectedInvoiceProvider = (state: State) => state.selectedInvoiceProvider;
export const getGSettingsWeekEnd = (state: State) => state.weekEnd;
export const getGSettingsReceivedFromServer = (state: State) => state.receivedSettingsFromServer;
export const getGSettingsPageAccessDisabled = (state: State) => state.pageAccessDisabled;
export const getGSettingsUploadLogoUrl = (state: State): string => state.logoUrl;
export const getGSettingsUploadLogoStatus = (state: State): SuccessStatus => state.uploadLogoStatus;
export const getGSettingsTaxTypes = (state: State): TaxType[] => state.globalSettingsModel.taxTypes;
export const getGSettingsTaxTypesStatus = (state: State): SuccessStatus => state.taxtTypesStatus;
export const getGSettingsAccountActive = (state: State): boolean => state.globalSettingsModel.accountActive === AccountActive.active;
export const getGSettingsHistoryStatus = (state: State) => state.settingHistoryStatus;
export const getGSettingsHistory = (state: State) => state.settingHistory;

