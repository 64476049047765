import { Client } from '../../models/Client';
import { Project } from '../../models/project';
import {
  EntriesWithDateRange,
  RunningTimer,
  TimeEntry
} from '../../models/timeentry';
import { Actions, ActionTypes } from '../actions/timer';

export interface State {
  loading: boolean;
  timerRunning: boolean;
  currentTimeEntry?: TimeEntry;
  entries: TimeEntry[];
  selectedClient?: Client;
  selectedProject?: Project;
  error?: string;
}

export const initialState: State = {
  loading: false,
  timerRunning: false,
  entries: Array<TimeEntry>()
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.STARTTIMER: {
      const entry = action.payload[0];
      const selectedClient = action.payload[1];
      return Object.assign({}, state, {
        loading: true,
        // maybe set this in the success state?
        timerRunning: true,
        currentTimeEntry: entry,
        selectedClient: selectedClient
        // entries: [...state.entries, entry]
      });
    }

    case ActionTypes.STARTTIMERSUCCESS: {
      const entry = Object.assign({}, state.currentTimeEntry, action.payload);
      return Object.assign({}, state, {
        currentTimeEntry: entry,
        entries: [...state.entries, entry],
        loading: false
      });
    }

    case ActionTypes.STARTTIMERFAILED: {
      return Object.assign({}, state, {
        loading: false
      });
    }

    case ActionTypes.SUBMITTIMEENTRY: {
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    }

    case ActionTypes.SUBMITTIMEENTRYSUCCESSFUL: {
      const timeEntry = action.payload;
      return Object.assign({}, state, {
        entries: [...state.entries, timeEntry],
        loading: false
      });
    }

    case ActionTypes.SUBMITTIMEENTRYFAILED: {
      const error = !action.payload.accountInActive ? action.payload : '';
      return Object.assign({}, state, {
        loading: false,
        error
      });
    }

    case ActionTypes.OPENTIMERDIALOGSUCCESS: {
      const entry = action.payload;
      return Object.assign({}, state, {
        currentTimeEntry: entry
      });
    }

    case ActionTypes.CHECKTIMERRUNNINGOONSERVERSUCCESSFUL: {
      const runningTimer: RunningTimer = new RunningTimer(action.payload);
      const entry = runningTimer.entry;
      if (entry) {
        const entryPosition = state.entries
          .map(e => e.timeEntryId)
          .indexOf(entry.timeEntryId);
        let updatedEntries = [];
        if (entryPosition >= 0) {
          updatedEntries = [
            ...state.entries.slice(0, entryPosition),
            Object.assign({}, state.entries[entryPosition], entry),
            ...state.entries.slice(entryPosition + 1)
          ];
        } else {
          updatedEntries = [...state.entries, entry];
        }
        return Object.assign({}, state, {
          timerRunning: true,
          loading: false,
          entries: updatedEntries,
          currentTimeEntry: entry,
          selectedClient: runningTimer.client
        });
      }
      return state;
    }

    case ActionTypes.CHECKTIMERRUNNINGONSERVERFAILED: {
      const error = !action.payload.accountInActive ? action.payload : '';
      return Object.assign({}, state, {
        error
      });
    }

    case ActionTypes.TIMERSTOPPEDFROMSERVER: {
      const newTimeEntry = action.payload;
      const entryPosition = state.entries
        .map(e => e.timeEntryId)
        .indexOf(newTimeEntry.timeEntryId);

      let updatedEntries = [];
      if (entryPosition >= 0) {
        updatedEntries = [
          ...state.entries.slice(0, entryPosition),
          { ...state.entries[entryPosition], ...newTimeEntry },
          ...state.entries.slice(entryPosition + 1)
        ];
      } else {
        updatedEntries = [...state.entries, newTimeEntry];
      }

      return {
        ...state,
        loading: false,
        timerRunning: false,
        currentTimeEntry: undefined,
        entries: updatedEntries
      };
    }

    case ActionTypes.ENDTIMER: {
      const entry = new TimeEntry(action.payload);
      const newTimeEntry = Object.assign({}, state.currentTimeEntry, entry);

      const entryPosition = state.entries
        .map(e => e.timeEntryId)
        .indexOf(newTimeEntry.timeEntryId);
      let updatedEntries = [];
      if (entryPosition >= 0) {
        updatedEntries = [
          ...state.entries.slice(0, entryPosition),
          Object.assign({}, state.entries[entryPosition], newTimeEntry),
          ...state.entries.slice(entryPosition + 1)
        ];
      } else {
        updatedEntries = [...state.entries, newTimeEntry];
      }

      return Object.assign({}, state, {
        loading: false,
        timerRunning: false,
        currentTimeEntry: undefined,
        entries: updatedEntries
      });
    }

    case ActionTypes.DELETETIMEENTRY: {
      return Object.assign({}, state, {
        loading: true
      });
    }

    case ActionTypes.DELETETIMEENTRYSUCCESSFUL: {
      const deletedTimeEntry: TimeEntry = action.payload;
      const newTimeEntries = state.entries.map(timeEntry => {
        if (timeEntry.timeEntryId === deletedTimeEntry.timeEntryId) {
          return deletedTimeEntry;
        }
        return timeEntry;
      });
      return Object.assign({}, state, {
        loading: false,
        entries: newTimeEntries
      });
    }

    case ActionTypes.DELETETIMEENTRYFAILED: {
      const error = !action.payload.accountInActive ? action.payload : '';
      return Object.assign({}, state, {
        loading: false,
        error
      });
    }

    case ActionTypes.EDITTIMEENTRIES: {
      return Object.assign({}, state, {
        loading: true
      });
    }


    case ActionTypes.ENDTIMERNODISPTACHSUCCESS: {
      const updatedTimeEntry = action.payload;
      const newTimeEntries = state.entries.map(entry => {
        if (entry.timeEntryId === updatedTimeEntry.timeEntryId) {
          return updatedTimeEntry;
        }
        return entry;
      });
      return Object.assign({}, state, {
        loading: false,
        timerRunning: false,
        entries: newTimeEntries
      });
    }

    case ActionTypes.EDITTIMEENTRIESFAILED: {
      const error = !action.payload.accountInActive ? action.payload : '';
      return Object.assign({}, state, {
        loading: false,
        error
      });
    }

    case ActionTypes.SAVETIMEENTRYTOLOCALDBFAILED: {
      const error = !action.payload.accountInActive ? action.payload : '';
      return Object.assign({}, state, {
        error
      });
    }

    case ActionTypes.EDITTIMEENTRIESSUCCESSFUL:
    case ActionTypes.LOADTIMEENTRIESFROMSERVERSUCCESSFUL: {
      let timeEntries;
      if (action.payload instanceof EntriesWithDateRange) {
        timeEntries = action.payload.entries;
      } else {
        timeEntries = action.payload;
      }

      return Object.assign({}, state, {
        loading: false,
        entries: timeEntries
      });
    }

    default:
      return state;
  }
}

export const getLoading = (state: State) => state.loading;
export const getTimerState = (state: State) => state.timerRunning;
export const getCurrentTimeEntry = (state: State) => state.currentTimeEntry;
export const getTimeEntries = (state: State) => state.entries;
export const getTimerSelectedClient = (state: State) => state.selectedClient;
