import { Action } from '@ngrx/store';
import { User } from '../../models/user';
import { User as GoogleAuthUser } from '@codetrix-studio/capacitor-google-auth';
import { type } from '../../util';
import { ChangePassword } from '../../models/registeruser';
import { UserCompanyRole } from '@cartwheel/web-components';
import { ApiErrorResponse } from 'app/models/apierror';
/**
 * These are the action types that are exported for the LoginUser action
 */
export const ActionTypes = {
  LOGIN: type('[Login] Login'),
  LOGOUT: type('[Login] Logout'),
  GOOGLELOGIN: type('[Login] Google Login'),
  GOOGLELOGINSUCCESS: type('[Login] Google Login Successful'),
  GOOGLELOGINCOMPLETE: type('[Login] Google Login Completed and Validated'),
  FORGOTPASSWORD: type('[Login] Forgot Password'),
  LOGINSUCCESS: type('[Login] Login Success'),
  LOGINFAILURE: type('[Login] Login Failure'),
  LOGGEDIN: type('[Login] Logged In'),
  AUTHENTICATED: type('[Login] Authenticated'),
  SETEMAIL: type('[Login] Set Email'),
  OPENLOGOUTDIALOG: type('[Login] open logout dialog'),
  OPENLOGOUTDIALOGSUCCESS: type('[Login] Opening logout dialog was successful'),
  OPENLOGOUTDIALOGFAILED: type('[Login] Opening logout dialog failed'),
  LOGOUTSUCCESS: type('[Login] Logging out was successful'),
  LOGOUTFAILURE: type('[Login] Logging out failed'),
  CHANGEPASSWORDSUBMIT: type('[Login] Submitting Change Password'),
  CHANGEPASSWORDSUBMITSUCCESSFUL: type('[Login] Submitting Change Password successful'),
  CHANGEPASSWORDSUBMITERROR: type('[Login] Submitting Change Password error'),
  GETUSERROLES: type('[Login] Get user roles'),
  GETUSERROLESSUCCESSFUL: type('[Login] Get user roles was successful'),
  GETUSERROLESFAILED: type('[Login] Getting user roles failed'),
  SETUSERVIEWROLE: type('[Login] Set user view role'),
  SETACCESSTOKEN: type('[Login] Set access token'),
  RESETSETTINGS: type('[Login] Reset values to initial state'),
  CHANGEFORGOTTENPASSWORD: type('[Login] Change forgotten password'),
  CHANGEFORGOTTENPASSWORDSUCCESS: type('[Login] Successfully changed password')
};

/**
 * Action that is fired when a user is attempting to Login. This would return a token that will be stored locally.
 */
export class LoginAction implements Action {
  type = ActionTypes.LOGIN;

  constructor(public payload: User) { }
}

export class LoggedInAction implements Action {
  type = ActionTypes.LOGGEDIN;

  constructor(public payload: { authenticated: boolean, token: string } | boolean) { }
}

/**
 * When a user has forgotten their password, we allow them to pass that user class,
 * to re-use the entered email address.
 */
// TODO: Move the "forgot password" actions and reducers to their own file, since single responsibility
export class ForgotPasswordAction implements Action {
  type = ActionTypes.FORGOTPASSWORD;

  constructor(public payload: string) { }
}

export class SetEmailAction implements Action {
  type = ActionTypes.SETEMAIL;

  constructor(public payload: string) { }
}

export class GetUserRolesAction implements Action {
  type = ActionTypes.GETUSERROLES;

  constructor() { }
}

export class ResetSettingsAction implements Action {
  type = ActionTypes.RESETSETTINGS;

  constructor(public payload?: any) { }
}

export class SetUserViewRoleAction implements Action {
  type = ActionTypes.SETUSERVIEWROLE;

  constructor(public payload: UserCompanyRole) { }
}

export class GetUserRolesSuccessfulAction implements Action {
  type = ActionTypes.GETUSERROLESSUCCESSFUL;

  constructor(public payload: UserCompanyRole[]) { }
}

export class GetUserRolesFailedAction implements Action {
  type = ActionTypes.GETUSERROLESFAILED;

  constructor(public payload?: any) { }
}

export class SetAccessTokenAction implements Action {
  type = ActionTypes.SETACCESSTOKEN;

  constructor(public payload: string) { }
}

export class LoginSuccessAction implements Action {
  type = ActionTypes.LOGINSUCCESS;

  constructor(public payload: { access_token: string }) { }
}

export class GoogleLoginAction implements Action {
  type = ActionTypes.GOOGLELOGIN;

  constructor(public payload: any = 'test') { }
}

export class LogoutAction implements Action {
  type = ActionTypes.LOGOUT;
  constructor(public payload: any) { }
}
export class GoogleLoginSuccessAction implements Action {
  type = ActionTypes.GOOGLELOGINSUCCESS;

  constructor(public payload: GoogleAuthUser) { }
}

export class GoogleLoginCompleteAction implements Action {
  type = ActionTypes.GOOGLELOGINCOMPLETE;

  constructor(public payload: any) { }
}
/**
 * This is a generic handler for dealing with any error that may occur from a login action
 * It's catching an exception and handling it.
 */
export class LoginFailureAction implements Action {
  type = ActionTypes.LOGINFAILURE;

  constructor(public payload: any) { }
}


export class OpenLogOutDialogAction implements Action {
  type = ActionTypes.OPENLOGOUTDIALOG;

  constructor(public payload?: any) { }
}

export class OpenLogoutDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENLOGOUTDIALOGSUCCESS;

  constructor(public payload: any) { }
}

export class OpenLogoutDialogFailedAction implements Action {
  type = ActionTypes.OPENLOGOUTDIALOGFAILED;

  constructor(public payload: any) { }
}

export class LogoutSuccess implements Action {
  type = ActionTypes.LOGOUTSUCCESS;

  constructor(public payload?: any) { }
}

export class LogoutFailure implements Action {
  type = ActionTypes.LOGOUTFAILURE;

  constructor(public payload: any) { }
}

export class SubmitChangeForgottenPasswordAction implements Action {
  type = ActionTypes.CHANGEFORGOTTENPASSWORD;

  constructor(public payload: ChangePassword) { }
}

export class SubmitChangeForgottenPasswordSuccessAction implements Action {
  type = ActionTypes.CHANGEFORGOTTENPASSWORDSUCCESS;

  constructor(public payload: any) { }
}

export class SubmitChangePasswordAction implements Action {
  type = ActionTypes.CHANGEPASSWORDSUBMIT;

  constructor(public payload: ChangePassword) { }
}

export class SubmitChangePasswordSuccessAction implements Action {
  type = ActionTypes.CHANGEPASSWORDSUBMITSUCCESSFUL;

  constructor(public payload: any) { }
}

export class SubmitChangePasswordErrorAction implements Action {
  type = ActionTypes.CHANGEPASSWORDSUBMITERROR;

  constructor(public payload: ApiErrorResponse) { }
}


export type Actions = LoginAction | ForgotPasswordAction | SetEmailAction | GoogleLoginSuccessAction | GetUserRolesFailedAction |
  GetUserRolesSuccessfulAction | LogoutAction | GoogleLoginCompleteAction |
  GoogleLoginAction | LoginSuccessAction | LoginFailureAction | LoggedInAction | SubmitChangePasswordAction |
  SubmitChangePasswordSuccessAction | SubmitChangePasswordErrorAction | SetUserViewRoleAction | SetAccessTokenAction |
  ResetSettingsAction | SubmitChangeForgottenPasswordAction | SubmitChangeForgottenPasswordSuccessAction;
