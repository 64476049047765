import { Approver } from '../../models/approver';
import { Client, ClientExpense, ClientWithRole } from '../../models/Client';
import { SubTabSelection, TabSelection, SuccessStatus } from '../../shared/enums';
import { Actions, ActionTypes } from '../actions/client';
import * as clientActions from '../actions/client';
import { createReducer, on } from '@ngrx/store';

export interface State {
  loading: boolean | SuccessStatus;
  clients: Client[];
  selectedClient?: Client;
  error?: string;
  loadingMessage?: string;
  tableData?: Client[];
  tabActive?: TabSelection;
  subTabActive?: SubTabSelection;
  activePage?: number;
  clientDropDown?: string;
  invoiceEmails?: { name: string }[];
  enterpriseClients?: Client[];
  dates?: { value: string; display: string }[];
  roundingOptions?: { value: string; display: string }[];
  timeOptions?: { value: string; display: string }[];
  clientSettings?: Approver;
  clientExpenses?: ClientExpense[];
  timesheetTemplate?:{status: SuccessStatus, error?: string};
  timesheetExample?:{status: SuccessStatus, error?: string};
}

export const initialState: State = {
  loading: false,
  clients: new Array<Client>(),
  selectedClient: null,
  clientExpenses: new Array<ClientExpense>(),
  timesheetTemplate: {status: SuccessStatus.Enabled, error: ''},
  timesheetExample: {status: SuccessStatus.Enabled, error: ''}
};

const clientReducer = createReducer(initialState,
  on(
    clientActions.DownloadCompanyTimeSheetTemplateAction,
    clientActions.DownloadClientTimeSheetTemplateAction,
    clientActions.UploadTimeSheetTemplateAction,
    (state) => ({
      ...state,
      timesheetTemplate: {
        status: SuccessStatus.IsLoading,
        error: ''
      }
    })
  ),
  on(
    clientActions.DownloadCompanyTimeSheetTemplateSuccessAction,
    clientActions.DownloadClientTimeSheetTemplateSuccessAction,
    clientActions.UploadTimeSheetTemplateSuccessAction,
    (state) => ({
      ...state,
      timesheetTemplate: {
        status: SuccessStatus.Success,
        error: ''
      }
    })
  ),
  on(
    clientActions.DownloadCompanyTimeSheetTemplateFailureAction,
    clientActions.DownloadClientTimeSheetTemplateFailureAction,
    clientActions.UploadTimeSheetTemplateFailureAction,
    (state, action) => ({
      ...state,
      timesheetTemplate: {
        status: action.error?.accountInActive ? SuccessStatus.AccountInActive : SuccessStatus.Error,
        error: action.error?.error
      }
    })
  ),
  on(
    clientActions.ResetTimeSheetTemplateStatusAction,
    (state) => ({
      ...state,
      timesheetTemplate: {
        status: SuccessStatus.Enabled,
        error: ''
      }
    })
  ),
  on(
    clientActions.DownloadTimeSheetExampleAction,
    (state) => ({
      ...state,
      timesheetExample: {
        status: SuccessStatus.IsLoading,
        error: ''
      }
    })
  ),
  on(
    clientActions.DownloadTimeSheetExampleSuccessAction,
    (state) => ({
      ...state,
      timesheetExample: {
        status: SuccessStatus.Success,
        error: ''
      }
    })
  ),
  on(
    clientActions.DownloadTimeSheetExampleFailureAction,
    (state, action) => ({
      ...state,
      timesheetExample: {
        status: action.error?.accountInActive ? SuccessStatus.AccountInActive : SuccessStatus.Error,
        error: action.error?.error
      }
    })
  ),
  on(
    clientActions.ResetTimeSheetExampleStatusAction,
    (state) => ({
      ...state,
      timesheetExample: {
        status: SuccessStatus.Enabled,
        error: ''
      }
    })
  )
)

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SUBMITCLIENT: {
      const clientAndRole = action.payload as ClientWithRole;
      const client = clientAndRole.client
      return Object.assign({}, state, {
        loading: true,
        selectedClient: client,
        clients: [...state.clients, client]
      });
    }

    case ActionTypes.SELECTCLIENT: {
      let client = null;
      if (action.payload) {
        const clientId = action.payload.clientID;
        const clientInState = state.clients.find((a) => (a.clientID === clientId));
        if (clientInState) {
          client = clientInState;
        } else {
          client = action.payload;
        }
        return Object.assign({}, state, {
          selectedClient: client
        });


      } else {
        return { ...state, selectedClient: null };
      }
    }

    case ActionTypes.LOADCLIENTSWITHOUTBILLEDRATES: {
      return Object.assign({}, state, {
        loading: true,
        error: null,
        loadingMessage: 'Please wait while we prepare your clients'
      });
    }

    case ActionTypes.EDITCLIENTSUCCESSFUL: {
      const current = action.payload;
      const clientList = state.clients;
      if (current && current.clientID) {
        const clientIdx = clientList.findIndex((c) => (c.clientID === current.clientID));
        const clients = [
          ...state.clients.slice(0, clientIdx),
          action.payload,
          ...state.clients.slice(clientIdx + 1)
        ];
        return {
          ...state,
          loading: <SuccessStatus>SuccessStatus.Success,
          clients,
          selectedClient: action.payload
        };
      }
      return state;
    }

    case ActionTypes.EDITCLIENTFAILED: {
      return Object.assign({}, state, {
        loading: !action.payload.error.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
        error: !action.payload.error.accountInActive ? action.payload : null
      });
    }

    case ActionTypes.EDITCLIENT: {
      return Object.assign({}, state, {
        loading: <SuccessStatus>SuccessStatus.IsLoading
      });
    }

    case ActionTypes.UPDATECLIENTSSUCCESSFUL:
    case ActionTypes.LOADCLIENTSFROMDBSUCCESSFUL:
    case ActionTypes.LOADCLIENTSFROMSERVERSUCCESSFUL:
    case ActionTypes.LOADCLIENTSWITHOUTBILLEDRATESSUCCESSFUL: {
      const clients = (action.payload) ? action.payload.map(e => new Client(e)) : [];
      const sorted = clients.sort((a, b) => (new Date(b.creationDateTime).getTime() - new Date(a.creationDateTime).getTime()));
      return Object.assign({}, state, {
        loading: false,
        error: null,
        clients: sorted,
      });
    }

    case ActionTypes.LOADENTERPRISECLIENTSSUCCESS: {
      const clients = action.payload;
      // Ensure that an already selected client will be updated with assigned users
      const selectedClient = (state.selectedClient) ?
        clients.find((c) => c.clientID === state.selectedClient.clientID) : state.selectedClient
      return Object.assign({}, state, {
        loading: false,
        error: null,
        clients,
        selectedClient
      });
    }

    case ActionTypes.SUBMITCLIENTFAILED: {
      return Object.assign({}, state, {
        loading: !action.payload.accountInActive ? false : SuccessStatus.AccountInActive,
        selectedClient: {}
      });
    }

    case ActionTypes.SUBMITCLIENTSUCCESSFUL: {
      const client = action.payload as Client;
      const clientPosition = state.clients.map(c => c.clientID).indexOf(client.clientID);
      const newSelectedClient = client;
      let newClientList = null;
      if (clientPosition >= 0) {
        // payload clientID exists
        newClientList = [
          ...state.clients.slice(0, clientPosition),
          newSelectedClient,
          ...state.clients.slice(clientPosition + 1)
        ];
      } else {
        // payload clientID does not exist
        const clientWithoutID = state.clients.findIndex(
          e => !e.clientID && e.billedRate === client.billedRate && e.clientName === client.clientName
        );
        const updatedClient = Object.assign({}, newSelectedClient);
        if (clientWithoutID >= 0) {
          newClientList = [
            ...state.clients.slice(0, clientWithoutID),
            newSelectedClient,
            ...state.clients.slice(clientWithoutID + 1)
          ];
        } else {
          newClientList = Object.assign([], state.clients, client);
        }
      }
      newClientList = newClientList.sort((a, b) => (new Date(b.creationDateTime).getTime() - new Date(a.creationDateTime).getTime()));

      return Object.assign({}, state, {
        clients: newClientList,
        selectedClient: newSelectedClient,
        loading: false
      });
    }

    case ActionTypes.UPDATECLIENTPAGE: {
      return Object.assign({}, state, {
        loading: false
      });
    }

    case ActionTypes.UPDATECLIENTS: {
      const clients: Client[] = action.payload;
      const newClients = Object.assign([], state.clients, ...clients);
      return Object.assign({}, state, {
        clients: [...newClients],
        loading: true,
        loadingMessage: 'Importing your clients with their billed rates!'
      });
    }

    case ActionTypes.UPDATECLIENTSSUCCESSFUL: {
      // set loading to false, and empty loading message
      return Object.assign({}, state, {
        loading: false,
        loadingMessage: ''
      });
    }

    case ActionTypes.UPDATECLIENTTABLE: {
      return Object.assign({}, state, {
        tableData: action.payload
      });
    }
    case ActionTypes.UPDATECLIENTTAB: {
      return Object.assign({}, state, {
        tabActive: action.payload
      });
    }
    case ActionTypes.UPDATECLIENTTABLEACTIVEPAGE: {
      return Object.assign({}, state, {
        activePage: action.payload
      });
    }
    case ActionTypes.UPDATECLIENTDROPDOWN: {
      return Object.assign({}, state, {
        clientDropDown: action.payload
      });
    }

    case ActionTypes.UPDATECLIENTCARDSUBTAB: {
      return Object.assign({}, state, {
        subTabActive: action.payload
      });
    }
    case ActionTypes.UPDATECLIENTCARDSETTINGS: {
      const settings = action.payload;
      return Object.assign({}, state, {
        clientSettings: settings
      });
    }
    case ActionTypes.LOADCLIENTEXPENSESSUCCESS: {
      return {
        ...state,
        clientExpenses: action.payload
      }
    }
    case ActionTypes.LOADCLIENTEXPENSESFAILURE: {
      return {
        ...state,
        clientExpenses: []
      }
    }
    default:
      return clientReducer(state, action);
  }
}

export const getClients = (state: State) => state.clients;
export const getEnterpriseClients = (state: State) => state.enterpriseClients;
export const getClientsLoading = (state: State) => state.loading;
export const getClientError = (state: State) => state.error;
export const getSelectedClient = (state: State) => state.selectedClient;
export const getClientLoadingMsg = (state: State) => state.loadingMessage;
export const getClientSubTab = (state: State) => state.subTabActive;
export const getClientTab = (state: State) => state.tabActive;
export const getInvoiceEmails = (state: State) => state.invoiceEmails;
export const getClientDates = (state: State) => state.dates;
export const getClientExpenses = (state: State) => state.clientExpenses;
export const getRoundingOptions = (state: State) => state.roundingOptions;
export const getTimeOptions = (state: State) => state.timeOptions;
export const getDetailedClientCardInputs = (state: State) => state.clientSettings;
export const getTimeSheetTemplateStatus = (state: State) => state.timesheetTemplate.status;
export const getTimeSheetTemplateError = (state: State) => state.timesheetTemplate.error;
export const getTimeSheetExampleStatus = (state: State) => state.timesheetExample.status;
export const getTimeSheetExampleError = (state: State) => state.timesheetExample.error;