import { Action } from '@ngrx/store';
import { type } from '../../util';
import { UserCompanyRole } from '@cartwheel/web-components';

export const ActionTypes = {
  LOADDASHBOARD: type('[Dashboard] Load Dashboard with date'),
  CHANGECURRENTROLE: type('[Dashboard] Change Current Role'),
  OPENTEAMSELECTOR: type('[Dashboard] Open team selector dialog'),
  GOTODASHBOARD: type('[Dashboard] Go to dashboard')
};

export class LoadDashboardAction implements Action {
  type = ActionTypes.LOADDASHBOARD;

  constructor(public payload: Date[]) { }
}

export class ChangeCurrentRoleAction implements Action {
  type = ActionTypes.CHANGECURRENTROLE;

  constructor(public payload: UserCompanyRole) { }
}

export class GoToDashboardAction implements Action {
  type = ActionTypes.GOTODASHBOARD;

  constructor(public payload: string[] = []) { }
}
export class OpenTeamSelectorAction implements Action {
  type = ActionTypes.OPENTEAMSELECTOR;

  constructor(public payload?: any) { }
}

export type Actions =
  LoadDashboardAction |
  ChangeCurrentRoleAction |
  OpenTeamSelectorAction;

