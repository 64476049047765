import { RegisterUser, ResendConfirmation } from '../../models/registeruser';
import { Action, createAction } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
  SUBMITLEAD: type('[Sign Up] Submit Lead to server'),
  LEADSUBMITSUCCESS: type('[Sign Up] Lead Submission Successful'),
  LEADSUBMITERROR: type('[Sign Up] Lead Submission had an error'),
  RESENDCONFIRMATION: type('[Sign Up] Resend Confirmation Email'),
  CHECKCONFIRMATIONCODE: type('[Sign Up] Checking Confirmation Code'),
  CONFIRMATIONCODEVALID: type('[Sign Up] Confirmation Code is valid'),
  CONFIRMATIONCODEINVALID: type('[Sign Up] Invalid Confirmation code submitted'),
  REGISTRATIONSUBMIT: type('[Sign Up] Submitting Registration'),
  REGISTRATIONERROR: type('[Sign Up] Error during submitting registration'),
  REGISTRATIONSUCCESSFUL: type('[Sign Up] Registration Successful'),
  GOOGLEVERIFICATIONSUBMIT: type('[Sign Up] Submitting Google Verification'),
  GOOGLEREGISTRATIONSUBMIT: type('[Sign Up] Submitting Google Registration'),
  GOOGLEREGISTRATIONSUCCESSFUL: type('[Sign Up] Google Registration Successful'),
  GOOGLEVERIFICATIONSUCCESSFUL: type('[Sign Up] Google Verification Successful'),
  GOOGLEVERIFICATIONCOMPLETE: type('[Sign Up] Google Verification Process Complete'),
  GOOGLEREGISTRATIONERROR: type('[Sign Up] Error during Google Registration'),
  GOOGLEVERIFICATIONERROR: type('[Sign Up] Error during Google Verification'),
  RESETLEADSUBMIT: type('[Sign Up] Reset Lead Submit'),
  DIRECTTOFTUX: type('[Sign Up] Direct To FTUX')
};

export const ResetLeadSubmit = createAction(ActionTypes.RESETLEADSUBMIT);

export class DirectToFtuxAction implements Action {
  type = ActionTypes.DIRECTTOFTUX;

  constructor(public payload?: boolean) { }
}

export class ResendConfirmationAction implements Action {
  type = ActionTypes.RESENDCONFIRMATION;

  constructor(public payload: ResendConfirmation) { }
}

export class SubmitLeadAction implements Action {
  type = ActionTypes.SUBMITLEAD;

  constructor(public payload: string) { }
}

export class LeadSubmitSuccessAction implements Action {
  type = ActionTypes.LEADSUBMITSUCCESS;

  constructor(public payload: string) { }
}

export class LeadSubmitErrorAction implements Action {
  type = ActionTypes.LEADSUBMITERROR;

  constructor(public payload: string) { }
}

export class CheckConfirmationAction implements Action {
  type = ActionTypes.CHECKCONFIRMATIONCODE;

  constructor(public payload: string) { }
}

export class ConfirmationValidAction implements Action {
  type = ActionTypes.CONFIRMATIONCODEVALID;

  constructor(public payload: RegisterUser) { }
}

export class ConfirmationInvalidAction implements Action {
  type = ActionTypes.CONFIRMATIONCODEINVALID;

  constructor(public payload: string) { }
}

export class SubmitRegistrationAction implements Action {
  type = ActionTypes.REGISTRATIONSUBMIT;

  constructor(public payload: RegisterUser) { }
}

export class SubmitGoogleVerificationAction implements Action {
  type = ActionTypes.GOOGLEVERIFICATIONSUBMIT;

  constructor(public payload: any) { }
}

export class SubmitGoogleRegistrationAction implements Action {
  type = ActionTypes.GOOGLEREGISTRATIONSUBMIT;

  constructor(public payload: RegisterUser) { }
}

export class SubmitGoogleVerificationCompleteAction implements Action {
  type = ActionTypes.GOOGLEVERIFICATIONCOMPLETE;

  constructor(public payload: RegisterUser) { }
}

export class SubmitRegistrationSuccessAction implements Action {
  type = ActionTypes.REGISTRATIONSUCCESSFUL;

  constructor(public payload: RegisterUser) { }
}

export class SubmitGoogleRegistrationSuccessAction implements Action {
  type = ActionTypes.GOOGLEREGISTRATIONSUCCESSFUL;

  constructor(public payload: any) { }
}

export class SubmitGoogleVerificationSuccessAction implements Action {
  type = ActionTypes.GOOGLEVERIFICATIONSUCCESSFUL;

  constructor(public payload: string) { }
}

export class SubmitRegistrationErrorAction implements Action {
  type = ActionTypes.REGISTRATIONERROR;

  constructor(public payload: string) { }
}

export class SubmitGoogleRegistrationErrorAction implements Action {
  type = ActionTypes.GOOGLEREGISTRATIONERROR;

  constructor(public payload: string) { }
}

export class SubmitGoogleVerificationErrorAction implements Action {
  type = ActionTypes.GOOGLEVERIFICATIONERROR;

  constructor(public payload: string) { }
}

export type Actions = SubmitLeadAction | SubmitRegistrationSuccessAction | ResendConfirmationAction | SubmitRegistrationErrorAction |
  SubmitGoogleVerificationAction | DirectToFtuxAction | SubmitGoogleRegistrationAction |
  SubmitGoogleRegistrationErrorAction | SubmitGoogleRegistrationSuccessAction | LeadSubmitErrorAction | SubmitRegistrationAction |
  SubmitGoogleVerificationCompleteAction | SubmitGoogleVerificationSuccessAction | SubmitGoogleVerificationErrorAction
  | LeadSubmitSuccessAction | CheckConfirmationAction | ConfirmationInvalidAction | ConfirmationValidAction | typeof ResetLeadSubmit;

