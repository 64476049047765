import { Action, createAction, props } from '@ngrx/store';
import { type } from '../../util';
import { CompanyUser, CompanyUserAndCompanyId, CompanyUserPagedRequest, TeamUserListModel } from '../../models/CompanyUser';
import { ApiErrorResponse } from 'app/models/apierror';
import { PagedResponse } from 'app/models/pagination';
import { AddUserRequest } from '../../models/CompanyUser';


export const ActionTypes = {
  GETCOMPANYUSERSACTION: type('[Company] Get Company Users'),
  GETCOMPANYUSERSSUCCESSACTION: type('[Company] Get Company Users Success Action'),
  GETCOMPANYUSERSFAILUREACTION: type('[Company] Get Company Users Failure Action'),
  UPDATEUSERINCOMPANY: type('[Company] Change user\' information relative to a specific company'),
  UPDATEUSERINCOMPANYSUCCESS: type('[Company] Successfully changed user information in a specific company'),
  UPDATEUSERINCOMPANYFAILURE: type('[Company] Failed to change user information in a specific company'),
  SHOWADDUSERDIALOG: type('[User] Open Add User dialog'),
  SHOWADDUSERDIALOGSUCCESS: type('[User] Open add user dialog success'),
  SHOWADDUSERDIALOGFAILURE: type('[User] Open add user dialog failure'),
  SUBMITADDNEWUSER: type('[User] Submit add new user'),
  SUBMITADDNEWUSERSUCCESS: type('[User] Submit add new user success'),
  SUBMITADDNEWUSERFAILURE: type('[User] Submit add new user failure'),
  DELETECOMPANYUSERS: type('[Company] Delete company Users'),
  RESETUSERLOADINGACTION: type('[User] Reset loading status'),
  CLEARADDUSERERROR: type('[User] Clear add user error'),
  RESENDTEAMUSERCONFIRMATION: type('[User] Resend Team User Confirmation'),
  RESENDTEAMUSERCONFIRMATIONSUCCEEDED: type('[User] Resending Team user confirmation succeeded'),
  RESENDTEAMUSERCONFIRMATIONFAILED: type('[User] Resending Team user confirmation failed'),
  RESETUSERLIST: type('[User] Reset user list'),
  OPENUSERINFORMATIONDIALOG: type('[User] Open user information dialog'),
  OPENUSERINFORMATIONDIALOGSUCCESS: type('[User] Open user information dialog success'),
  OPENUSERINFORMATIONDIALOGFAILED: type('[User] Open user information dialog failed'),
};

export class ResendUserConfirmationAction implements Action {
  type = ActionTypes.RESENDTEAMUSERCONFIRMATION;
  constructor(public payload: CompanyUserAndCompanyId) { }
}

export const OpenUserInformationDialogAction = createAction(ActionTypes.OPENUSERINFORMATIONDIALOG, props<{ user: TeamUserListModel, filterOptions: CompanyUserPagedRequest }>());
export const OpenUserInformationDialogSuccessAction = createAction(ActionTypes.OPENUSERINFORMATIONDIALOGSUCCESS);
export const OpenUserInformationDialogFailedAction = createAction(ActionTypes.OPENUSERINFORMATIONDIALOGFAILED);
export const ResendUserConfirmationSucceededAction = createAction(ActionTypes.RESENDTEAMUSERCONFIRMATIONSUCCEEDED, props<{ result: string }>())

export class ResendUserConfirmationFailedAction implements Action {
  type = ActionTypes.RESENDTEAMUSERCONFIRMATIONFAILED;
  constructor(public payload?: any) { }
}

export class ShowAddUserDialogAction implements Action {
  type = ActionTypes.SHOWADDUSERDIALOG;
  constructor(public payload: CompanyUserPagedRequest) { }
}

export class ClearAddUserErrorAction implements Action {
  type = ActionTypes.CLEARADDUSERERROR;
  constructor(public payload?: any) { }
}

export class ShowAddUserDialogSuccessAction implements Action {
  type = ActionTypes.SHOWADDUSERDIALOGSUCCESS;
  constructor(public payload?: any) { }
}

export class ShowAddUserDialogFailureAction implements Action {
  type = ActionTypes.SHOWADDUSERDIALOGFAILURE;
  constructor(public payload?: any) { }
}

export class SubmitAddNewUserAction implements Action {
  type = ActionTypes.SUBMITADDNEWUSER;
  constructor(public payload?: {addUserRequest: AddUserRequest, filterOptions: CompanyUserPagedRequest}) { }
}

export const SubmitAddNewUserSuccessAction = createAction(ActionTypes.SUBMITADDNEWUSERSUCCESS,props<{user:CompanyUser, filterOptions: CompanyUserPagedRequest}>());

export class SubmitAddNewUserFailureAction implements Action {
  type = ActionTypes.SUBMITADDNEWUSERFAILURE;
  constructor(public payload?: ApiErrorResponse) { }
}

export const GetCompanyUsersAction = createAction(ActionTypes.GETCOMPANYUSERSACTION, props<CompanyUserPagedRequest>());

export const GetCompanyUsersSuccessAction = createAction(ActionTypes.GETCOMPANYUSERSSUCCESSACTION, props<{users: PagedResponse<CompanyUser>}>());

export const ResetUserListAction = createAction(ActionTypes.RESETUSERLIST);

export class GetCompanyUsersFailureAction implements Action {
  type = ActionTypes.GETCOMPANYUSERSFAILUREACTION;
  constructor(public payload?: ApiErrorResponse) { }
}

export class ResetUserLoadingAction implements Action {
  type = ActionTypes.RESETUSERLOADINGACTION;
  constructor(public payload: any = {}) { }
}

export class DeleteCompanyUsersAction implements Action {
  type = ActionTypes.DELETECOMPANYUSERS;

  constructor(public payload: {users: CompanyUser[], filterOptions: CompanyUserPagedRequest}) { }
}

export class UpdateUserInCompanyAction implements Action {
  type = ActionTypes.UPDATEUSERINCOMPANY;

  constructor(public payload: {user: CompanyUser, filterOptions: CompanyUserPagedRequest}) { }
}

export class UpdateUserInCompanySuccessAction implements Action {
  type = ActionTypes.UPDATEUSERINCOMPANYSUCCESS;

  constructor(public payload: CompanyUser) { }
}

export class UpdateUserInCompanyFailureAction implements Action {
  type = ActionTypes.UPDATEUSERINCOMPANYFAILURE;

  constructor(public payload: ApiErrorResponse) { }
}

export type Actions =
  ShowAddUserDialogAction |
  ShowAddUserDialogSuccessAction |
  ShowAddUserDialogFailureAction |
  SubmitAddNewUserAction |
  typeof SubmitAddNewUserSuccessAction |
  SubmitAddNewUserFailureAction |
  GetCompanyUsersFailureAction |
  ResendUserConfirmationAction |
  ResendUserConfirmationFailedAction |
  ResetUserLoadingAction |
  typeof ResetUserListAction |
  ClearAddUserErrorAction |
  DeleteCompanyUsersAction |
  UpdateUserInCompanyAction |
  UpdateUserInCompanySuccessAction |
  UpdateUserInCompanyFailureAction |
  typeof OpenUserInformationDialogAction |
  typeof OpenUserInformationDialogSuccessAction |
  typeof OpenUserInformationDialogFailedAction;
