
import { Actions, ActionTypes } from '../actions/alerts';
import { Alert } from 'app/models/alerts';

export interface State {
  alerts: Alert[];
  flag: boolean;
  indexer: number;
}

export const initialState: State = {
  alerts: [],
  flag: false,
  indexer: 0
};

export function reducer(state = initialState, action: Actions): State {

  switch (action.type) {

    case ActionTypes.ADDALERT: {
      const alert = new Alert(action.payload);
      const id = state.indexer + 1;
      alert.id = id;
      const existingAlert = state.alerts.find(a =>
        (a.applicationType) ?
          a.clientId === alert.clientId && a.type === alert.type :
          a.providerType === alert.providerType
      );
      const alerts = (existingAlert) ? state.alerts : state.alerts.concat(alert);
      return { ...state, alerts, indexer: id };
    }

    case ActionTypes.REMOVEALERT: {
      const currentAlert = new Alert(action.payload);
      const index = state.alerts.findIndex((alert) => alert.id === currentAlert.id);
      return { ...state, alerts: state.alerts.slice(0, index).concat(state.alerts.slice(index + 1)) };
    }

    case ActionTypes.REMOVEALLALERTS: {
      return { ...state, alerts: [] }
    }

    case ActionTypes.SETALERTFLAG: {
      const flag = <boolean>action.payload;
      return { ...state, flag: flag };
    }

    default:
      return state;
  }

}

export const getAlerts = (state: State) => state.alerts;
export const getAlertFlag = (state: State) => state.flag;
