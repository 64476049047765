import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Wagethreshold } from 'app/models/wagethreshold';
import { ApiErrorResponse } from 'app/models/apierror';

export const ReportsAction = createActionGroup({
  source: 'Reports',
  events: {
    loadWageThresholdDataAction: emptyProps(),
    loadWageThresholdDataSuccessAction: props<{ data: Wagethreshold[] }>(),
    loadWageThresholdDataFailureAction: props<{ error: ApiErrorResponse }>(),
    saveWageThresholdDataAction: props<{ data: Wagethreshold[] }>(),
    saveWageThresholdDataSuccessAction: props<{ data: Wagethreshold[] }>(),
    saveWageThresholdDataFailureAction: props<{ error: ApiErrorResponse }>(),
    resetWageThresholdStatusAction: emptyProps()
  }
});

type loadWageThresholdDataAction = ReturnType<typeof ReportsAction.loadWageThresholdDataAction>;
type loadWageThresholdDataSuccessAction = ReturnType<typeof ReportsAction.loadWageThresholdDataSuccessAction>;
type loadWageThresholdDataFailureAction = ReturnType<typeof ReportsAction.loadWageThresholdDataFailureAction>;
type saveWageThresholdDataAction = ReturnType<typeof ReportsAction.saveWageThresholdDataAction>;
type saveWageThresholdDataSuccessAction = ReturnType<typeof ReportsAction.saveWageThresholdDataSuccessAction>;
type saveWageThresholdDataFailureAction = ReturnType<typeof ReportsAction.saveWageThresholdDataFailureAction>;
type resetWageThresholdStatusAction = ReturnType<typeof ReportsAction.resetWageThresholdStatusAction>;

export type ReportsActions = 
  | loadWageThresholdDataAction
  | loadWageThresholdDataSuccessAction
  | loadWageThresholdDataFailureAction
  | saveWageThresholdDataAction
  | saveWageThresholdDataSuccessAction
  | saveWageThresholdDataFailureAction
  | resetWageThresholdStatusAction;