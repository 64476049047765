import dayjs from 'dayjs';
import { Client } from './Client';
import { Status, ApprovalStatus } from '../shared/enums';
import { UUID } from 'angular2-uuid';

export class TimeEntry {
  timeEntryId?: string;
  startTime: Date;
  endTime?: Date;
  description: string;
  projectId?: string;
  createdByUserId?: string;
  clientId: string;
  status: Status;
  externalId?: UUID;
  timesheetId: UUID;
  approvalStatus: ApprovalStatus;
  creationDateTime: Date;
  invoiceSagaId?: string;
  userInvoiceId?: string;
  clientInvoiceId?: string;
  userHourlyRate?: number;
  billedRate?: number;
  creationLocation: EntryCreationLocation;
  otHourlyRate?: number;
  get elapsed(): number {
    if (this.endTime) {
      const end = dayjs(this.endTime);
      const start = dayjs(this.startTime);
      const difference = end.diff(start, 'seconds');
      return difference;
    }
    return dayjs().diff(dayjs(this.startTime), 'seconds');
  }
  constructor(obj?: TimeEntry | any) {
    if (obj) {
      if (obj.clientId) {
        this.clientId = obj.clientId;
      }
      if (obj.createdByUserId) {
        this.createdByUserId = obj.createdByUserId;
      }

      if (obj.description) {
        this.description = obj.description;
      }

      if (obj.endTime) {
        this.endTime = obj.endTime;
      }

      if (obj.projectId) {
        this.projectId = obj.projectId;
      }
      if (obj.externalId) {
        this.externalId = obj.externalId;
      }

      if (obj.startTime) {
        this.startTime = obj.startTime;
      }

      if (obj.timeEntryId) {
        this.timeEntryId = obj.timeEntryId;
      }

      if (obj.status !== null && obj.status !== undefined) {
        this.status = obj.status;
      }

      if (obj.timesheetId) {
        this.timesheetId = obj.timesheetId;
      }

      if (obj.invoiceSagaId) {
        this.invoiceSagaId = obj.invoiceSagaId;
      }

      if (obj.clientInvoiceId) {
        this.clientInvoiceId = obj.clientInvoiceId;
      }

      if (obj.userInvoiceId) {
        this.userInvoiceId = obj.userInvoiceId;
      }

      if (obj.billedRate !== null && obj.billedRate !== undefined) {
        this.billedRate = obj.billedRate;
      }

      if (obj.userHourlyRate !== null && obj.userHourlyRate !== undefined) {
        this.userHourlyRate = obj.userHourlyRate;
      }

      if (obj.creationLocation && obj.creationLocation != null) {
        this.creationLocation = obj.creationLocation;
      }

      if (obj.otHourlyRate !== null && obj.otHourlyRate !== undefined) {
        this.otHourlyRate = obj.otHourlyRate;
      }

    }
  }

}

export class EntriesWithDateRange {
  entries: TimeEntry[];
  range: [Date, Date];
}

export class RunningTimer {
  entry: TimeEntry;
  client: Client;
  constructor(data?: any) {
    if (data) {
      if (data.entry) {
        this.entry = new TimeEntry(data.entry);
      }

      if (data.client) {
        this.client = new Client(data.client);
      }
    }
  }
}

export enum EntryCreationLocation {
  Timer,
  AddTimeModal,
  Seed,
  AutomatedCreation
}

export class EntryRequest {
  startDate: Date;
  endDate: Date;
  constructor(data?: any) {
    if (data) {
      if (data.startDate) {
        this.startDate = data.startDate;
      }

      if (data.endDate) {
        this.endDate = data.endDate;
      }
    }
  }

}
