import { UUID } from 'angular2-uuid';

export class RegisterUser {
  email: string;
  password?: string;
  confirmPassword?: string;
  firstName: string;
  lastName: string;
  companyName: string;
  confirmationCode?: string;
  userId: UUID;
  isAddedByAdmin?: boolean;
  timeZone?: string;

  constructor(data?: RegisterUser) {
    if (data) {
     if (data.email) {
       this.email = data.email;
     }
     if (data.firstName) {
       this.firstName = data.firstName;
     }
     if (data.lastName) {
       this.lastName = data.lastName;
     }
     if (data.companyName) {
       this.companyName = data.companyName;
     }
     if (data.isAddedByAdmin) {
       this.isAddedByAdmin = data.isAddedByAdmin;
     }
     if (data.timeZone) {
       this.timeZone = data.timeZone;
     }
    }
   }
}

export class ResendConfirmation {
  email: string;
  confirmationCode: string;
  constructor(email: string, confirmation: string) {
    this.email = email;
    this.confirmationCode = confirmation;
  }
}
export class ChangePassword {
  oldPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  resetToken?: string;
  email: string;
}
