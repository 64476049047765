import { CustomFieldEntryModel, CustomFieldSchemaModel, ValidationProblemDetails } from "app/models/custom-fields";
import { 
   CustomFieldActions } from '../actions/customfields';
import { createReducer, on } from "@ngrx/store";
import { SuccessStatus } from "@cartwheel/web-components";

export interface State {
  schemas: CustomFieldSchemaModel[];
  entries: CustomFieldEntryModel[];
  loading: SuccessStatus;
  error?: ValidationProblemDetails;
}

export const initialState: State = {
  schemas: [],
  entries: [],
  loading: null,
  error: null,
};

export const customFieldsReducer = createReducer(
  initialState,
  on(CustomFieldActions.createCustomFieldSchema, (state, { schemaToAdd, tempId }) => ({
    ...state,
    schemas: [...state.schemas, { ...schemaToAdd, createdAt: new Date(), tempId }], // Add optimistically with tempId
  })),
  on(CustomFieldActions.createCustomFieldSchemaFailure, (state, { tempId, error }) => ({
    ...state,
    schemas: state.schemas.filter(schema => schema.tempId !== tempId), // Remove the optimistic entry on failure
    error: { ...error },
  })),
  on(CustomFieldActions.createCustomFieldSchemaSuccess, (state, { addedSchema, tempId }) => ({
    ...state,
    schemas: state.schemas.map(schema => schema.tempId === tempId ? addedSchema : schema), // Replace temp entry with confirmed data
    error: null,
  })),
  on(CustomFieldActions.deleteCustomFieldSchema, (state) => ({ ...state })),
  on(CustomFieldActions.deleteCustomFieldSchemaFailure, (state, { error }) => ({ ...state, error })),
  on(CustomFieldActions.deleteCustomFieldSchemaSuccess, (state, { success, index }) => {
    if (success && index > -1) {
      return {
        ...state,
        schemas: [...state.schemas.slice(0, index), ...state.schemas.slice(index + 1)],
        error: null,
      }
    } else {
      return {
        ...state,
        error: null,
      }
    }
  }),
  on(CustomFieldActions.getAllCustomFieldSchemas, (state) => ({ ...state })),
  on(CustomFieldActions.getAllCustomFieldSchemasFailure, (state, { error }) => ({ ...state, error })),
  on(CustomFieldActions.getAllCustomFieldSchemasSuccess, (state, { schemas }) => 
    ({ ...state, schemas, error: null })),
  on(CustomFieldActions.getCustomFieldSchemasByEntity, (state) => ({ ...state })),
  on(CustomFieldActions.getCustomFieldSchemasByEntityFailure, (state, { error }) => ({ ...state, error })),
  on(CustomFieldActions.getCustomFieldSchemasByEntitySuccess, (state, { schemas }) => 
    ({  ...state, schemas, error: null })),
  on(CustomFieldActions.updateCustomFieldSchema, (state, { originalSchema, schemaToUpdate, index }) => ({
    ...state,
    schemas: [
      ...state.schemas.slice(0, index),
      { ...originalSchema, fieldName: schemaToUpdate.newFieldName, defaultFieldValue: schemaToUpdate?.defaultFieldValue }, // Apply optimistic update
      ...state.schemas.slice(index + 1)
    ]
  })),
  on(CustomFieldActions.updateCustomFieldSchemaFailure, (state, { originalSchema, index, error }) => ({
    ...state,
    schemas: [
      ...state.schemas.slice(0, index),
      originalSchema, // Revert to original schema on failure
      ...state.schemas.slice(index + 1)
    ],
    error: { ...error }
  })),
  on(CustomFieldActions.updateCustomFieldSchemaSuccess, (state, { updatedSchema, index }) => ({
    ...state,
    schemas: [
      ...state.schemas.slice(0, index),
      updatedSchema, // Confirm the successful update
      ...state.schemas.slice(index + 1)
    ],
    error: null,
  })),
  on(CustomFieldActions.createCustomFieldEntry, (state, { entryToAdd }) => ({
    ...state,
    entries: [...state.entries.map(entry => entry.fieldName === entryToAdd.fieldName && entry.entityId === entryToAdd.entityId
      ? { ...entry, fieldValue: entryToAdd.fieldValue } : entry)], // Optimistically add entry
    loading: SuccessStatus.IsLoading,
  })),
  on(CustomFieldActions.createCustomFieldEntryFailure, (state, { error, originalEntry }) => {
    if (originalEntry) {
      return {
        ...state,
        entries: [...state.entries.map(entry => entry.fieldName === originalEntry.fieldName && entry.entityId === originalEntry.entityId
          ? originalEntry : entry)], // Remove the optimistic entry on failure
        loading: SuccessStatus.Error,
        error: { ...error }
        }
    } else {
      return {
        ...state,
        error: { ...error}
      }
    }
  }),
  on(CustomFieldActions.createCustomFieldEntrySuccess, (state, { addedEntry }) => ({
    ...state,
    entries: [...state.entries.map(entry => entry.fieldName === addedEntry.fieldName && entry.entityId === addedEntry.entityId
      ? addedEntry : entry)], // Replace temp entry with confirmed data
    loading: SuccessStatus.Success,
    error: null,
  })),
  on(CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIds, (state) => ({ ...state, loading: SuccessStatus.IsLoading })),
  on(CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIdsFailure, (state, { error }) => ({ ...state, loading: SuccessStatus.Error, error })),
  on(CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIdsSuccess, (state, { entries }) =>
    ({ ...state, entries, loading: SuccessStatus.Success, error: null })),
  on(CustomFieldActions.updateCustomFieldEntry, (state, { entryToUpdate }) => ({
     ...state,
    entries: [
      ...state.entries.map(entry => entry.fieldName === entryToUpdate.fieldName && entry.entityId === entryToUpdate.entityId
        ? { ...entry, fieldValue: entryToUpdate.fieldValue } : entry)], // Optimistic add
    loading: SuccessStatus.IsLoading,
    })),
  on(CustomFieldActions.updateCustomFieldEntryFailure, (state, { originalEntry, error }) => ({
     ...state,
     entries: [
      ...state.entries.map(entry => entry.fieldName === originalEntry.fieldName && entry.entityId === originalEntry.entityId
        ? { ...entry, fieldValue: originalEntry.fieldValue } : entry)], // Replace optimistic entry on failure with original data
    loading: SuccessStatus.Error,
     error: { ...error }
    })),
  on(CustomFieldActions.updateCustomFieldEntrySuccess, (state, { updatedEntry }) => ({
     ...state,
     entries: [...state.entries.map(entry => entry.fieldName === updatedEntry.fieldName && entry.entityId === updatedEntry.entityId
      ? updatedEntry : entry)],
    loading: SuccessStatus.Success,
     error: null,
    })),
  on(CustomFieldActions.deleteCustomFieldEntry, (state, { entryToDelete, defaultFieldValue }) => ({
    ...state,
    entries: [...state.entries.map(entry => entry.fieldName === entryToDelete.fieldName && entry.entityId === entryToDelete.entityId
       ? { ...entry, fieldValue: defaultFieldValue } : entry // Optimistic remove actually just replaces the value; there are technically 'entries'
    )],
    loading: SuccessStatus.IsLoading,
  })),
  on(CustomFieldActions.deleteCustomFieldEntryFailure, (state, { originalEntry, error }) => ({
    ...state,
    entries: [...state.entries.map(entry => entry.fieldName === originalEntry.fieldName && entry.entityId === originalEntry.entityId
      ? { ...entry, fieldValue: originalEntry.fieldName } : entry // Undo optimistic delete on failure with original entry
    )],
    loading: SuccessStatus.Error,
    error: { ...error }
  })),
  on(CustomFieldActions.deleteCustomFieldEntrySuccess, (state, { originalEntry, success }) => {
    if (success) {  
      return {
        ...state, // Nothing to change; optimistic update should be fine
        loading: SuccessStatus.Success,
        error: null,
      }
    } else {
      return {
        ...state,
        entries: [...state.entries.map(entry => entry.fieldName === originalEntry.fieldName && entry.entityId === originalEntry.entityId
          ? { ...entry, fieldValue: originalEntry.fieldName } : entry // Undo optimistic delete on failure with original entry
        )],
        loading: SuccessStatus.Success,
        error: null,
      }
    }
  }),
);

export const getCustomFieldSchemas = (state: State) => state.schemas;
export const getCustomFieldEntries = (state: State) => state.entries;
export const getCustomFieldEntriesLoading = (state: State) => state.loading;