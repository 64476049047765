import { createReducer, on } from '@ngrx/store';
import { RegisterUser } from '../../models/registeruser';
import * as actions from '../actions/signup';
import { Actions, ActionTypes } from '../actions/signup';
import { UUID } from 'angular2-uuid';
import { SuccessStatus } from 'app/shared/enums';

export interface State {
  error?: string;
  loading: SuccessStatus;
  User: RegisterUser;
  completed: boolean;
  googleregistration: boolean;
}

export const initialState: State = {
  User: new RegisterUser(),
  loading: null,
  completed: false,
  googleregistration: false
};

export const signupReducer = createReducer(initialState,
  on(actions.ResetLeadSubmit, (state) => ({
      ...state,
      loading: null,
      error: null
  }))
);

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SUBMITLEAD: {
      return Object.assign({}, state, {
        User: {
          email: action.payload
        },
        error: null,
        loading: SuccessStatus.IsLoading,
        googleregistration: false
      });
    }

    case ActionTypes.RESENDCONFIRMATION: {
      return {
        ...state,
        loading: SuccessStatus.IsLoading,
      }
    }

    case ActionTypes.LEADSUBMITSUCCESS: {
      const confirmation = action.payload;
      return Object.assign({}, state, {
        User: {
          email: state.User.email,
          confirmationCode: confirmation
        },
        error: null,
        loading: SuccessStatus.Enabled,
        completed: true
      });
    }

    case ActionTypes.LEADSUBMITERROR: {
      const error = action.payload || 'There was an error submitting your account';

      return Object.assign({}, state, {
        loading: SuccessStatus.Error,
        error: error
      });
    }

    case ActionTypes.GOOGLEVERIFICATIONERROR:
    case ActionTypes.REGISTRATIONERROR: {
      const error = action.payload;
      let errorMsg = 'There was an error';
      if (typeof error === 'string') {
        errorMsg = error;
      }

      return Object.assign({}, state, {
        loading: SuccessStatus.Error,
        error: errorMsg
      });
    }

    case ActionTypes.REGISTRATIONSUBMIT: {

      const currentUser = action.payload as RegisterUser;
      const newUserwithid = Object.assign({}, currentUser, {
        userId: UUID.UUID()
      });
      return Object.assign({}, state, {
        User: {
          email: currentUser.email,
          ConfirmationCode: state.User.confirmationCode
        },
        loading: SuccessStatus.IsLoading,
        error: null
      });
    }

    case ActionTypes.GOOGLEREGISTRATIONSUBMIT: {
      const user = action.payload as RegisterUser;
      return Object.assign({}, state, {
        User: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          companyName: user.companyName
        },
        loading: SuccessStatus.IsLoading
      });
    }

    case ActionTypes.GOOGLEREGISTRATIONSUCCESSFUL: {
      return state;
    }

    case ActionTypes.GOOGLEVERIFICATIONSUBMIT: {
      const testUser = action.payload;
      return Object.assign({}, state, {
        googleregistration: true,
        loading: SuccessStatus.IsLoading,
        error: null
      });
    }

    case ActionTypes.GOOGLEVERIFICATIONCOMPLETE: {
      const user = action.payload as RegisterUser;
      return Object.assign({}, state, {
        error: null,
        loading: SuccessStatus.Success,
        User: user
      });
    }

    case ActionTypes.REGISTRATIONSUCCESSFUL: {
      return Object.assign({}, state, {
        error: null
      });
    }

    case ActionTypes.GOOGLEVERIFICATIONSUCCESSFUL: {
      return Object.assign({}, state, {
        error: null
      });
    }

    case ActionTypes.CONFIRMATIONCODEINVALID: {
      const error = action.payload;
      return Object.assign({}, state, {
        User: {
          confirmationCode: null
        },
        loading: SuccessStatus.Error,
        error: error
      });
    }

    case ActionTypes.GOOGLEVERIFICATIONERROR: {
      const error = action.payload;
      return Object.assign({}, state, {
        loading: SuccessStatus.Error,
        error: error
      });
    }

    case ActionTypes.CONFIRMATIONCODEVALID: {
      const newRegisterUser = new RegisterUser(action.payload);
      newRegisterUser.confirmationCode = state.User.confirmationCode;
      return Object.assign({}, state, {
        User: newRegisterUser,
        loading: SuccessStatus.Success,
        error: null
      });
    }

    case ActionTypes.CHECKCONFIRMATIONCODE: {
      return Object.assign({}, state, {
        User: {
          confirmationCode: action.payload
        },
        loading: SuccessStatus.IsLoading,
        error: null
      });
    }

    default: {
      return signupReducer(state, action);
    }

  }
}

export const getSignUpUser = (state: State) => state.User;
export const getSignUpLoading = (state: State) => state.loading;
export const getSignUpError = (state: State) => state.error;
export const getSignUpCompleted = (state: State) => state.completed;
export const getSignUpGoogleReg = (state: State) => state.googleregistration;
