import { createReducer, on } from '@ngrx/store';
import { Actions, ActionTypes } from '../actions/user';
import * as userActions from '../actions/user';

import { CompanyUser } from 'app/models/CompanyUser';

import { SuccessStatus } from 'app/shared/enums';
import { PagedResponse } from 'app/models/pagination';

export interface State {
  loading: SuccessStatus;
  companyUsers?: PagedResponse<CompanyUser>;
  error: string;
}

export const initialState: State = {
  loading: null,
  companyUsers: new PagedResponse<CompanyUser>(),
  error: null
}

const usersReducer = createReducer(
  initialState,
  on(userActions.GetCompanyUsersAction, (state) => ({ ...state, loading: SuccessStatus.IsLoading })),
  on(userActions.GetCompanyUsersSuccessAction, (state, {users}) => ({
    ...state,
    companyUsers: users,
    loading: SuccessStatus.Success
  })),
  on(userActions.SubmitAddNewUserSuccessAction, (state) => ({
    ...state,
    loading: SuccessStatus.Success
  })),
  on(userActions.ResetUserListAction, (state) => ({
    ...state,
    companyUsers: new PagedResponse<CompanyUser>()
  }))
);

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SUBMITADDNEWUSERSUCCESS: {
      return {
        ...state,
        loading: SuccessStatus.Success
      }
    }

    case ActionTypes.SUBMITADDNEWUSER:
    case ActionTypes.DELETECOMPANYUSERS:
    case ActionTypes.UPDATEUSERINCOMPANY: {
      return {
        ...state,
        loading: SuccessStatus.IsLoading
      }
    }

    case ActionTypes.GETCOMPANYUSERSFAILUREACTION: {
      return {
        ...state,
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
      }
    }
    case ActionTypes.UPDATEUSERINCOMPANYSUCCESS: {
      const index = state.companyUsers.data.findIndex((user) => user.userId === action.payload.userId);
      const beginning = state.companyUsers.data.slice(0, index);
      const end = state.companyUsers.data.slice(index + 1, state.companyUsers.data.length);
      return {
        ...state,
        loading: SuccessStatus.Success,
        companyUsers: {
          ...state.companyUsers,
          data: beginning.concat(action.payload).concat(end)
        }
      }
    }

    case ActionTypes.UPDATEUSERINCOMPANYFAILURE: {
      return {
        ...state,
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
        companyUsers: {
          ...state.companyUsers,
          data: state.companyUsers.data.concat([]) // returns UI back to normal
        }
      }
    }

    case ActionTypes.SUBMITADDNEWUSERFAILURE: {
      return Object.assign({}, state, {
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
        error: !action.payload.accountInActive ? action.payload : ''
      });
    }

    case ActionTypes.RESETUSERLOADINGACTION: {
      return {
        ...state,
        loading: null
      }
    }

    case ActionTypes.CLEARADDUSERERROR: {
      return Object.assign({}, state, { error: null });
    }
    default:
      return usersReducer(state, action);

  }
}

export const getUsers = (state: State) => state;
export const getUsersList = (state: State) => state.companyUsers;
export const getUsersLoading = (state: State) => state.loading;
export const getUsersError = (state: State) => state.error;
