export class PagedResponse<T> {
  limit: number = 8;
  total: number = 0
  page: number = 0;
  totalPages: number = 0;
  data: T[] = [];

  constructor(data?: any) {
    if (data?.limit !== null && data?.limit !== undefined) {
      this.limit = data.limit;
    }
    if (data?.total !== null && data?.total !== undefined) {
      this.total = data.total;
    }
    if (data?.page !== null && data?.page !== undefined) {
      this.page = data.page;
    }
    if (data?.totalPages) {
      this.totalPages = data.totalPages;
    }
    if (data?.data) {
      this.data = data.data;
    }
  }
}