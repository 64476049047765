import { ExternalInvoice } from 'app/models/external-invoice';
import { createAction, props } from '@ngrx/store';
import { ExternalInvoicingSystems } from 'app/shared/enums';
import { InvoiceDataSourcePasswordAuthForm, SageIntacctDataSourceAuthForm } from 'app/models/invoice-data-source';
import { ApiErrorResponse } from 'app/models/apierror';

export const invoiceDataSourceLoginAction = createAction(
  '[Invoice Data Source] Login in to  datasource',
  props<{invoiceDataSourceAuthData: InvoiceDataSourcePasswordAuthForm}>()
);

export const invoiceDataSourceLoginSuccessAction = createAction(
  '[Invoice Data Source] Successfully logged in to  datasource',
  props<{ invoiceDataSourceLoginResult: { dataSource: string, result: any } }>()
);

export const invoiceDataSourceLoginFailureAction = createAction(
  '[Invoice Data Source] Failed to log in to  datasource',
  props<{ invoiceDataSourceLoginResult: { dataSource: string, error: ApiErrorResponse } }>()
);

export const sageIntacctDataSourceLoginAction = createAction(
  '[Invoice Data Source] Login in to sage',
  props<{ sageIntacctDataSourceAuthData: SageIntacctDataSourceAuthForm }>()
);

export const invoiceDataSourceLoginCompleteAction = createAction(
  '[Invoice Data Source] Log in attempt complete (resetting UI)',
  props<{ invoiceDataSourceDialog: { dataSource: string, closeDialog: boolean }}>()
);

export const openInvoiceDataSourceDialogAction = createAction(
  '[Invoice Data Source] Open invoice data source dialog',
  props<{ invoiceDataSource: ExternalInvoice}>()
);

export const openInvoiceDataSourceDialogSuccessAction = createAction(
  '[Invoice Data Source] Open invoice data source dialog success',
  props<{ invoiceDataSource: ExternalInvoice}>()
);

export const openInvoiceDataSourceDialogFailureAction = createAction(
  '[Invoice Data Source] Open invoice data source dialog failed',
);

export const closeInvoiceDataSourceDialogAction = createAction(
  '[Invoice Data Source] Close invoice data source dialog',
);

export const disableInvoiceDatasourcesAction = createAction(
  '[Invoice Data Source] Disable invoice data sources',
);

export const getCurrentInvoiceDataSourceSuccessAction = createAction(
  '[Invoice Data Source] Get current invoice data sources successful',
  props<{invoicingSystems: ExternalInvoicingSystems[]}>()
);

export const disconnectInvoiceDataSourceAction = createAction(
  '[Invoice Data Source] Disconnect invoice data source',
  props<{invoicingSystems: ExternalInvoicingSystems}>()
);

export const disconnectInvoiceDataSourceSuccessAction = createAction(
  '[Invoice Data Source] Disconnect invoice data source success',
  props<{invoicingSystems: ExternalInvoicingSystems}>()
);

export const disconnectInvoiceDataSourceFailureAction = createAction(
  '[Invoice Data Source] Disconnect invoice data source failure',
  props<{invoicingSystems: ExternalInvoicingSystems}>()
);
