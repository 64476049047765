import { Action } from '@ngrx/store';
import { type } from '../../util';
import { PasswordAuthForm } from '../../models/approver';
import { ApplicationTypes } from '../../shared/enums';
import { Client } from '../../models/Client';;
import { ExternalInvoice } from 'app/models/external-invoice';
import { ApiErrorResponse } from 'app/models/apierror';
/**
 * These are the action types that are exported for the Approver action
 */
export const ActionTypes = {
    HARVESTLOGIN: type('[Approver] Harvest Login'),
    HARVESTLOGINSUCCESS: type('[Approver] Harvest Login Successful'),
    HARVESTLOGINFAILURE: type('[Approver] Harvest failure action'),
    TOGGLOPENDIALOG: type('[Approver] Open Toggl Dialog'),
    TOGGLDIALOGFAILURE: type('[Approver] Failure Toggl Dialog'),
    TOGGLDIALOGSUCCESS: type('[Approver] Success Toggl Dialog'),
    APPROVERAPPLICATIONLOGIN: type('[Approver] Application Login'),
    APPROVERAPPLICATIONLOGINSUCCESS: type('[Approver] Toggl Login Successful'),
    APPROVERAPPLICATIONLOGINCOMPLETE: type('[Approver] Toggl Login Completed and Validated'),
    APPROVERAPPLICATIONLOGINFAILURE: type('[Approver] Toggl failure action'),
    GETAUTHSTATUS: type('[Approver] Fetch auth status from server'),
    GETAUTHSTATUSSUCCESS: type('[Approver] Fetched status successfully'),
    GETAUTHSTATUSFAILURE: type('[Approver] Failure to fetch auth status'),
    LOADCLIENTSFROMPROVIDER: type('[Approver] Load clients from application'),
    LOADCLIENTSFROMPROVIDERSUCCESS: type('[Approver] Successfully loaded clients from application'),
    LOADCLIENTSFROMPROVIDERFAILURE: type('[Approver] Failure to load clients from application'),
    LOADPROJECTSFROMPROVIDERFAILURE: type('[Approver] Failure tp lpad projects for specified client'),
    APPROVERCHOICECOMPLETE: type('[Approver] Client from external provider was chosen successfully'),
    RESETAPPROVERPROCESS: type('[Approver] Reset flag for approver choice completion')
};

export class GetAuthStatusAction {
  type = ActionTypes.GETAUTHSTATUS;

  constructor(public payload?: any) { }
}

export class GetAuthStatusSuccessAction {
  type = ActionTypes.GETAUTHSTATUSSUCCESS;

  constructor(public payload?: string[]) { }
}

export class GetAuthStatusFailureAction {
  type = ActionTypes.GETAUTHSTATUSFAILURE;

  constructor(public payload?: ApiErrorResponse) { }
}

export class HarvestLoginAction implements Action {
  type = ActionTypes.HARVESTLOGIN;

  constructor(public payload: any = {}) { }
}

export class HarvestLoginSuccessAction implements Action {
  type = ActionTypes.HARVESTLOGINSUCCESS;

  constructor(public payload: string) { }
}

export class HarvestLoginFailureAction implements Action {
  type = ActionTypes.HARVESTLOGINFAILURE;

  constructor(public payload: ApiErrorResponse) { }
}

export class TogglOpenDialogAction implements Action {
  type = ActionTypes.TOGGLOPENDIALOG;

  constructor(public payload: ExternalInvoice) { }
}

export class TogglDialogSuccessAction implements Action {
  type = ActionTypes.TOGGLDIALOGSUCCESS;

  constructor(public payload: any = {}) { }
}

export class TogglDialogFailAction implements Action {
  type = ActionTypes.TOGGLDIALOGFAILURE;

  constructor(public payload: any = {}) { }
}

export class ApproverApplicationLoginAction implements Action {
  type = ActionTypes.APPROVERAPPLICATIONLOGIN;

  constructor(public payload: PasswordAuthForm) { }
}

export class ApproverApplicationLoginSuccessAction implements Action {
  type = ActionTypes.APPROVERAPPLICATIONLOGINSUCCESS;

  constructor(public payload: string) { }
}

export class ApproverApplicationLoginCompleteAction implements Action {
  type = ActionTypes.APPROVERAPPLICATIONLOGINCOMPLETE;

  constructor(public payload: { result: any, closeDialog: boolean }) { }
}

export class ApproverApplicationLoginFailureAction implements Action {
  type = ActionTypes.APPROVERAPPLICATIONLOGINFAILURE;

  constructor(public payload: { provider: string, error: ApiErrorResponse }) { }
}

export class LoadClientsFromProviderAction implements Action {
    type = ActionTypes.LOADCLIENTSFROMPROVIDER;

    constructor(public payload: ApplicationTypes) { }
}

export class LoadClientsFromProviderSuccessAction implements Action {
    type = ActionTypes.LOADCLIENTSFROMPROVIDERSUCCESS;

    constructor(public payload: { provider: ApplicationTypes, clients: Client[] }) { }
}

export class LoadClientsFromProviderFailureAction implements Action {
    type = ActionTypes.LOADCLIENTSFROMPROVIDERFAILURE;

    constructor(public payload: { provider: ApplicationTypes, error: ApiErrorResponse }) { }
}

export class LoadProjectsFromProviderFailureAction implements Action {
    type = ActionTypes.LOADPROJECTSFROMPROVIDERFAILURE;

    constructor(public payload: { provider: ApplicationTypes, error: ApiErrorResponse }) { }
}

export class ApproverChoiceCompleteAction implements Action {
    type = ActionTypes.APPROVERCHOICECOMPLETE;

    constructor(public payload: ApplicationTypes) { }
}

export class ResetApproverProcessAction implements Action {
    type = ActionTypes.RESETAPPROVERPROCESS;

    constructor(public payload: any = {}) { }
}

export type Actions =
    HarvestLoginAction |
    HarvestLoginSuccessAction |
    HarvestLoginFailureAction |
    ApproverApplicationLoginAction |
    ApproverApplicationLoginCompleteAction |
    ApproverApplicationLoginFailureAction |
    ApproverApplicationLoginSuccessAction |
    TogglOpenDialogAction |
    TogglDialogSuccessAction |
    TogglDialogFailAction |
    GetAuthStatusAction |
    GetAuthStatusSuccessAction |
    GetAuthStatusFailureAction |
    LoadClientsFromProviderAction |
    LoadClientsFromProviderSuccessAction |
    LoadClientsFromProviderFailureAction |
    LoadProjectsFromProviderFailureAction |
    ApproverChoiceCompleteAction |
    ResetApproverProcessAction
