import { createReducer, on } from '@ngrx/store';
import { ExternalInvoicingSystems, SuccessStatus } from '../../shared/enums';
import { InvoiceDataSource, InvoiceDataSourceState } from 'app/models/invoice-data-source';
import * as InvoiceDataSourceActions from '../actions/invoicedatasource';

export interface State {
  dataSources: InvoiceDataSourceState;
  showInvoiceDataSourceDialog: boolean;
  error: string;
  loading: boolean;
  complete: boolean;
}

export const initialState: State = {
  dataSources: null,
  showInvoiceDataSourceDialog: false,
  error: null,
  loading: false,
  complete: null
};

const invoiceDataSourceReducer = createReducer(
  initialState,
  on(InvoiceDataSourceActions.sageIntacctDataSourceLoginAction, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    showInvoiceDataSourceDialog: false,
    dataSources: Object.assign({}, state.dataSources, {
      [ExternalInvoicingSystems.SageIntacct]: {
        connectionStatus: SuccessStatus.IsLoading
      }
    })
  })),
  on(InvoiceDataSourceActions.invoiceDataSourceLoginAction, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    showInvoiceDataSourceDialog: false,
    dataSources: Object.assign({}, state.dataSources, {
      [action.invoiceDataSourceAuthData.InvoicingSystems]: {
        connectionStatus: SuccessStatus.IsLoading
      }
    })
  })),
  on(InvoiceDataSourceActions.invoiceDataSourceLoginSuccessAction, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    showInvoiceDataSourceDialog: true,
    dataSources: Object.assign({}, state.dataSources, {
      [ExternalInvoicingSystems[action.invoiceDataSourceLoginResult.dataSource]]: {
        connectionStatus: SuccessStatus.Success
      }
    }),
    complete: true
  })),
  on(InvoiceDataSourceActions.invoiceDataSourceLoginFailureAction, (state, action) => ({
    ...state,
    error: !action.invoiceDataSourceLoginResult.error.accountInActive ? 'Error connecting data source. Please try again.' : '',
    loading: false,
    showInvoiceDataSourceDialog: true,
    dataSources: Object.assign({}, state.dataSources, {
      [ExternalInvoicingSystems[action.invoiceDataSourceLoginResult.dataSource]]: {
        connectionStatus: !action.invoiceDataSourceLoginResult.error.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
      }
    }),
    complete: false
  })),
  on(InvoiceDataSourceActions.invoiceDataSourceLoginCompleteAction, (state, action) => {
    const dataSource = state.dataSources[ExternalInvoicingSystems[action.invoiceDataSourceDialog.dataSource]] as InvoiceDataSource;
    let connectionStatus = (dataSource.connectionStatus === SuccessStatus.AccountInActive)
      ? SuccessStatus.Disabled
      : SuccessStatus.Enabled;

    if (dataSource.connectionStatus === SuccessStatus.Success) {
      connectionStatus = SuccessStatus.Success;
    }

    return {
      ...state,
      error: null,
      loading: false,
      showInvoiceDataSourceDialog: false,
      dataSources: Object.assign({}, state.dataSources, {
        [ExternalInvoicingSystems[action.invoiceDataSourceDialog.dataSource]]: { connectionStatus }
      }),
      complete: null
    }
  }),
  on(InvoiceDataSourceActions.openInvoiceDataSourceDialogAction, (state, action) => ({
    ...state,
    showInvoiceDataSourceDialog: true
  })),
  on(InvoiceDataSourceActions.openInvoiceDataSourceDialogSuccessAction, (state, action) => ({
    ...state,
    showInvoiceDataSourceDialog: true
  })),
  on(InvoiceDataSourceActions.closeInvoiceDataSourceDialogAction, (state, action) => ({
    ...state,
    showInvoiceDataSourceDialog: false
  })),
  on(InvoiceDataSourceActions.disableInvoiceDatasourcesAction, (state, action) => ({
    ...state,
    loading: true,
    showInvoiceDataSourceDialog: false,
    dataSources: Object.assign({}, {
      [ExternalInvoicingSystems.Paidiem]: {
        connectionStatus: SuccessStatus.Disabled
      },
      [ExternalInvoicingSystems.SageIntacct]: {
        connectionStatus: SuccessStatus.Disabled
      }
    })
  })),
  on(InvoiceDataSourceActions.getCurrentInvoiceDataSourceSuccessAction, (state, action) => {
    const connectedDataSources = action.invoicingSystems.map(externalInvoiceSystem => ({
      [externalInvoiceSystem]: {
        connectionStatus: SuccessStatus.Success
      }
    }));

    return {
      ...state,
      error: null,
      loading: false,
      showInvoiceDataSourceDialog: false,
      dataSources: Object.assign({}, {
        [ExternalInvoicingSystems.Paidiem]: {
          connectionStatus: SuccessStatus.Enabled
        },
        [ExternalInvoicingSystems.SageIntacct]: {
          connectionStatus: SuccessStatus.Enabled
        },
      }, ...connectedDataSources)
    }
  }),
  on(InvoiceDataSourceActions.disconnectInvoiceDataSourceAction, (state, action) => ({
    ...state,
    loading: true,
    showInvoiceDataSourceDialog: false,
    dataSources: {
      ...state.dataSources,
      [ExternalInvoicingSystems[action.invoicingSystems]]: {
        connectionStatus: SuccessStatus.IsLoading
      }
    }
  })),
  on(InvoiceDataSourceActions.disconnectInvoiceDataSourceSuccessAction, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    showInvoiceDataSourceDialog: false,
    dataSources: {
      ...state.dataSources,
      [ExternalInvoicingSystems[action.invoicingSystems]]: {
        connectionStatus: SuccessStatus.Enabled
      }
    }
  })),
  on(InvoiceDataSourceActions.disconnectInvoiceDataSourceFailureAction, (state, action) => ({
    ...state,
    loading: false,
    error: 'Error disconnecting data source. Please try again.',
    showInvoiceDataSourceDialog: false,
    dataSources: {
      ...state.dataSources,
      [ExternalInvoicingSystems[action.invoicingSystems]]: {
        connectionStatus: SuccessStatus.Success
      }
    }
  })),
);

export function reducer(state = initialState, action): State {
  switch (action.type) {
    default: {
      return invoiceDataSourceReducer(state, action);
    }
  }
}

export const getInvoiceDataSources = (state: State) => state.dataSources;
export const getInvoiceDataSourcesLoading = (state: State) => state.loading;
export const getInvoiceDataSourceError = (state: State) => state.error;
export const getInvoiceDataSourceComplete = (state: State) => state.complete;
