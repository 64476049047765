
import { Actions, ActionTypes } from '../actions/invoice';
import { ClientDSO, CompanyCollection, CompanyDSO, CompanyInvoice, CompanyInvoiceHistoryItem } from 'app/models/company-invoice';
import { InvoiceStatus, SuccessStatus } from 'app/shared/enums';
import { createReducer, on } from '@ngrx/store';

import * as invoiceActions from '../actions/invoice';
import { PagedResponse } from 'app/models/pagination';

export interface State {
  loading: SuccessStatus;
  invoices: PagedResponse<CompanyInvoice>,
  isHistoryLoading: SuccessStatus,
  history: PagedResponse<CompanyInvoiceHistoryItem>,
  companyDSO: CompanyDSO,
  clientDSO: Array<ClientDSO>,
  companyCollection: CompanyCollection,
  isCompanyCollectionLoading: SuccessStatus;
}

export const initialState: State = {
  loading: null,
  invoices: new PagedResponse<CompanyInvoice>(),
  isHistoryLoading: null,
  history: new PagedResponse<CompanyInvoiceHistoryItem>(),
  companyDSO: new CompanyDSO(),
  clientDSO: new Array<ClientDSO>(),
  companyCollection: new CompanyCollection(),
  isCompanyCollectionLoading: null
}

const invoiceReducer = createReducer(initialState,
  on(
    invoiceActions.CreateManualInvoiceAction,
    invoiceActions.GenerateInvoicePreviewAction,
    invoiceActions.DownloadInvoicesAction,
    invoiceActions.MarkInvoicesAsPaidAction,
    invoiceActions.RegenerateInvoiceFromSourceAction,
    (state) => ({
      ...state,
      loading: SuccessStatus.IsLoading
    })
  ),
  on(
    invoiceActions.CreateManualInvoiceSuccessfulAction,
    invoiceActions.RegenerateInvoiceFromSourceSuccessAction,
    invoiceActions.GenerateInvoicePreviewSuccessfulAction,
    invoiceActions.DownloadInvoiceSuccessAction,
    (state) => ({
      ...state,
      loading: SuccessStatus.Success
    })
  ),
  on(
    invoiceActions.CreateManualInvoiceCompleteAction,
    invoiceActions.GenerateInvoicePreviewCompleteAction,
    (state) => ({
      ...state,
      loading: null
    })
  ),
  on(
    invoiceActions.CreateManualInvoiceFailedAction,
    invoiceActions.GenerateInvoicePreviewFailedAction,
    invoiceActions.RegenerateInvoiceFromSourceFailureAction,
    invoiceActions.CreateFixedRateInvoiceFailedAction,
    invoiceActions.MarkInvoicesAsPaidFailureAction,
    (state, action) => ({
      ...state,
      loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
    })
  ),
  on(invoiceActions.MarkInvoicesAsPaidSuccessAction, (state, {invoiceIds}) => ({
    ...state,
    loading: SuccessStatus.Success,
    invoices: {
      ...state.invoices,
      data: state.invoices.data.map(s => ({
        ...s,
        status: invoiceIds.includes(s.invoiceId) ? InvoiceStatus.Paid : s.status
      })),
    }
  })),
  on(invoiceActions.GetInvoiceHistoryAction, (state) => ({
    ...state,
    isHistoryLoading: SuccessStatus.IsLoading
  })),
  on(invoiceActions.GetInvoiceHistorySuccessAction, (state, {history}) => ({
    ...state,
    isHistoryLoading: SuccessStatus.Success,
    history
  })),
  on(invoiceActions.GetInvoiceHistoryFailureAction, (state, action) => ({
    ...state,
    isHistoryLoading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
  })),
  on(invoiceActions.ResetInvoiceHistoryAction, (state) => ({
    ...state,
    isHistoryLoading: null,
    history: new PagedResponse<CompanyInvoiceHistoryItem>()
  })),
  on(invoiceActions.ResetInvoiceListAction, (state) => ({
    ...state,
    invoices: new PagedResponse<CompanyInvoice>()
  })),
  on(invoiceActions.LoadCompanyDSOSuccessAction, (state, action) => ({
    ...state,
    companyDSO: action.companyDSO
  })),
  on(invoiceActions.LoadClientDSOSuccessAction, (state, action) => ({
    ...state,
    clientDSO: action.clientDSO
  })),
  on(invoiceActions.LoadCompanyCollectionAction, (state, action) => ({
    ...state,
    isCompanyCollectionLoading: SuccessStatus.IsLoading
  })),
  on(invoiceActions.LoadCompanyCollectionSuccessAction, (state, action) => ({
    ...state,
    companyCollection: action.companyCollection,
    isCompanyCollectionLoading: SuccessStatus.Success
  })),
  on(invoiceActions.LoadCompanyCollectionFailureAction, (state, action) => ({
    ...state,
    isCompanyCollectionLoading: SuccessStatus.Error
  }))
);

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GETINVOICELIST: {
      return { ...state, loading: SuccessStatus.IsLoading };
    }

    case ActionTypes.GETINVOICELISTSUCCESS: {
      return {
        ...state,
        loading: SuccessStatus.Success,
        invoices: action.payload,
      };
    }

    case ActionTypes.GETINVOICELISTFAILURE: {
      return {
        ...state, loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
      }
    }

    case ActionTypes.CREATEFIXEDRATEINVOICEACTION:
    case ActionTypes.CREATEMANUALINVOICEACTION: {
      return Object.assign({}, state, {
        loading: SuccessStatus.IsLoading
      })
    }

    case ActionTypes.CREATEFIXEDRATEINVOICESUCCESSFULACTION: {
      return Object.assign({}, state, {
        loading: SuccessStatus.Success
      })
    }

    case ActionTypes.RESETINVOICELOADING: {
      return Object.assign({}, state, {
        loading: null
      });
    }

    default: {
      return invoiceReducer(state, action);
    }

  }
}

export const getInvoices = (state: State) => state.invoices;
export const getInvoiceLoading = (state: State) => state.loading;
export const getInvoiceHistory = (state: State) => state.history;
export const getInvoiceHistoryLoading = (state: State) => state.isHistoryLoading;
export const getCompanyDSO = (state: State) => state.companyDSO;
export const getClientDSO = (state: State) => state.clientDSO;
export const getCompanyCollection = (state: State) => state.companyCollection;
export const getCompanyCollectionLoading = (state: State) => state.isCompanyCollectionLoading;