import { Actions } from '../actions/loginuser';
import * as user from '../actions/loginuser';
import { RegisterUser } from '../../models/registeruser';
import { User } from '../../models/user';
import { UserCompanyRole } from '@cartwheel/web-components';
import { SuccessStatus } from 'app/shared/enums';

export interface State {
  forgotpassword: boolean;
  user: User;
  RegisterUser: RegisterUser,
  error?: string;
  authenticated: boolean;
  loaded: boolean;
  loading: SuccessStatus;
  token?: string;
  updatesuccess: boolean;
  roles?: Array<UserCompanyRole>;
  userViewRole?: UserCompanyRole;
}

export const initialState: State = {
  user: new User('', ''),
  RegisterUser: new RegisterUser(),
  forgotpassword: false,
  authenticated: null,
  loaded: false,
  loading: null,
  error: null,
  updatesuccess: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case user.ActionTypes.LOGIN: {
      return Object.assign({}, state, {
        loading: SuccessStatus.IsLoading,
        error: null
      });
    }
    case user.ActionTypes.LOGOUT: {
      return Object.assign({}, state, {
        authenticated: false,
        token: null,
        loaded: false,
        loading: null,
        error: null
      });
    }

    case user.ActionTypes.FORGOTPASSWORD: {
      const forgotpw: string = action.payload;
      return Object.assign({}, state, {
        user: {
          email: forgotpw
        },
        loading: SuccessStatus.IsLoading,
        loaded: false
      });
    }

    case user.ActionTypes.GETUSERROLESSUCCESSFUL: {
      const roles: Array<UserCompanyRole> = action.payload;
      return Object.assign({}, state, {
        roles,
        loading: SuccessStatus.Success
      });
    }

    case user.ActionTypes.SETUSERVIEWROLE: {
      return {
        ...state,
        userViewRole: action.payload
      }
    }

    case user.ActionTypes.SETEMAIL: {
      const newEmail: string = action.payload;
      return Object.assign({}, state, {
        user: {
          email: newEmail
        }
      });
    }

    case user.ActionTypes.GOOGLELOGIN: {
      const googleLogin: boolean = action.payload;
      return Object.assign({}, state, {
        googlelogin: googleLogin,
        loading: SuccessStatus.IsLoading
      });
    }

    case user.ActionTypes.GOOGLELOGINCOMPLETE: {
      return Object.assign({}, state, {
        loaded: true,
        authenticated: true,
        error: null,
        loading: null,
      });
    }

    case user.ActionTypes.LOGINSUCCESS: {
      const loginInfo = action.payload;
      return Object.assign({}, state, {
        token: loginInfo.access_token,
        authenticated: true,
        loading: SuccessStatus.Success,
        error: null
      });
    }

    case user.ActionTypes.SETACCESSTOKEN: {
      return {
        ...state,
        token: action.payload,
        authenticated: true
      }
    }

    case user.ActionTypes.LOGINFAILURE: {
      const newError = action.payload;
      let errorMessage = '';
      if (newError) {
        if (typeof newError === 'string') {
          errorMessage = newError;
        } else {
          errorMessage = 'There was an undefined error';
        }
      }
      return Object.assign({}, state, {
        error: errorMessage,
        loading: SuccessStatus.Error
      });
    }

    case user.ActionTypes.CHANGEFORGOTTENPASSWORD:
    case user.ActionTypes.CHANGEPASSWORDSUBMIT: {
      return Object.assign({}, state, {
        loading: SuccessStatus.IsLoading,
        error: null,
        updatesuccess: false
      });
    }

    case user.ActionTypes.CHANGEFORGOTTENPASSWORDSUCCESS: {
      return Object.assign({}, state, {
        loading: null,
        updatesuccess: true
      });
    }

    case user.ActionTypes.CHANGEPASSWORDSUBMITSUCCESSFUL: {
      return Object.assign({}, state, {
        loading: SuccessStatus.Success,
        updatesuccess: true
      });
    }
    case user.ActionTypes.RESETSETTINGS: {
      return Object.assign({}, state, {
        loading: null,
        updatesuccess: null,
        error: null,
        loaded: false
      });
    }
    case user.ActionTypes.CHANGEPASSWORDSUBMITERROR: {
      return {
        ...state,
        updatesuccess: false,
        error: action.payload.accountInActive ? null : action.payload as never as string,
        loading: SuccessStatus.Error
      }
    }
    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user;
export const getError = (state: State) => state.error;
export const getUpdateSuccess = (state: State) => state.updatesuccess;
export const getAuthenticated = (state: State) => state.authenticated;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getChangePasswordUser = (state: State) => state.RegisterUser;
export const getRoles = (state: State) => state.roles;
export const getUserRole = (state: State) => state.userViewRole;
