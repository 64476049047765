import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
  SEEDDATABASE: type('[Seed] Seed database with time entry data'),
  SEEDDATABASESUCCESS: type('[Seed] Seed database success')
};

export class SeedDatabaseAction implements Action {
  type = ActionTypes.SEEDDATABASE;

  constructor(public payload?: any) { }
}

export class SeedDatabaseSuccessAction implements Action {
  type = ActionTypes.SEEDDATABASESUCCESS;

  constructor(public payload?: any) { }
}


export type Actions = SeedDatabaseAction | SeedDatabaseSuccessAction;

