import { UUID } from 'angular2-uuid';

export class HistoryRequest {
    date: Date;
    clientId: UUID;
    userId: UUID;
    timeEntryIds: UUID[];
}
export class ReportHistory {
   historyData?: Array<ReportHistoryItem>;
   constructor(data?) {
       if (data && data.historyData) {
           this.historyData = data.historyData;
       }
   }
}

export class ReportHistoryItem {
    change: String;
    action: String;
    description: String;
    timestamp: String;
    notes: String;
    rowId: String;
    approvalid: String;
    constructor(data) {
        if (data) {
            if (data.change) {
                this.change = data.change;
            }
            if (data.action) {
                this.action = data.action;
            }
            if (data.description) {
                this.description = data.description;
            }
            if (data.notes) {
                this.notes = data.notes;
            }
            if (data.rowId) {
                this.rowId = data.rowId;
            }
            if (data.timestamp) {
                this.timestamp = data.timestamp;
            }
            if (data.approvalid) {
                this.approvalid = data.approvalid;
            }
        }
    }
}
