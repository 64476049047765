import { SuccessStatus } from "@cartwheel/web-components";
import { createReducer, on } from "@ngrx/store";
import { ApiErrorResponse } from "app/models/apierror";
import { Wagethreshold } from "app/models/wagethreshold";
import { ReportsAction } from "../actions/reports";
import { Status } from "app/shared/enums";

export interface State {
  wageThresholdDatas: Wagethreshold[];
  status: SuccessStatus;
  error: ApiErrorResponse;
}

export const initialState: State = {
  wageThresholdDatas: [],
  error: null,
  status: SuccessStatus.Disabled
}

export const reportsReducer = createReducer(initialState,
  on(ReportsAction.loadWageThresholdDataAction, (state, action) => ({
    ...state,
    status: SuccessStatus.IsLoading
  })),
  on(ReportsAction.loadWageThresholdDataSuccessAction, (state, action) => ({
    ...state,
    wageThresholdDatas: action.data,
    status: SuccessStatus.Disabled
  })),
  on(ReportsAction.loadWageThresholdDataFailureAction, (state, action) => ({
    ...state,
    error: action.error,
    status: SuccessStatus.Disabled
  })),
  on(ReportsAction.saveWageThresholdDataAction, (state) => ({
    ...state,
    status: SuccessStatus.IsLoading
  })),
  on(ReportsAction.saveWageThresholdDataSuccessAction, (state, action) => ({
    ...state,
    wageThresholdDatas: updateWageThresholdDatas(state.wageThresholdDatas, action.data),
    status: SuccessStatus.Success,
    error: null
  })),
  on(ReportsAction.saveWageThresholdDataFailureAction, (state, action) => ({
    ...state,
    status: SuccessStatus.Error,
    error: action.error
  })),
  on(ReportsAction.resetWageThresholdStatusAction, (state) => ({
    ...state,
    status: SuccessStatus.Disabled
  }))
);

const updateWageThresholdDatas = (state: Wagethreshold[], data: Wagethreshold[]): Wagethreshold[] => {
  let value = [...state];
  data.forEach(s => {
    const index = value.findIndex(t => t.id === s.id);
    if (index === -1) {
      value.push(s);
    } else if (s.accountStatus === Status.Active) {
      value[index] = s;
    } else {
      value.splice(index, 1);
    }
  });

  return value;
}

export const getWageThresholdData = (state: State) => state.wageThresholdDatas;
export const getWageThresholdDataError = (state: State) => state.error;
export const getWageThresholdDataStatus = (state: State) => state.status;