import { Project } from '../../models/project';
import { type } from '../../util';
import { Action, createAction, props } from '@ngrx/store';
import {ProjectTable} from '../../models/project-table';
import {TabSelection} from '../../shared/enums';
import { ApiErrorResponse } from 'app/models/apierror';
export const ActionTypes = {
  SELECTPROJECT: type('[Project] Select a Project'),
  LOADENTERPRISEPROJECTSFROMSERVER: type('[Project] Load Enterprise Projects from server'),
  LOADENTERPRISEPROJECTSFROMSERVERSUCCESSFUL: type('[Project] Loading Enterprise Projects from server was successful'),
  LOADENTERPRISEPROJECTSFROMSERVERFAILED: type('[Project] Loading Enterprise Projects from server failed'),
  LOADPROJECTSFROMSERVER: type('[Project] Loading Projects from server'),
  LOADPROJECTSFROMSERVERSUCCESSFUL: type('[Project] Loading Projects from server was successful'),
  LOADPROJECTSFROMSERVERFAILED: type('[Project] Loading Projects from server failed'),
  BATCHADDPROJECT: type('[Project] Add a group of projects'),
  BATCHADDPROJECTSUCCESSFUL: type('[Project] Successfully added batch of projects'),
  BATCHADDPROJECTFAILED: type('[Project] Failed adding batch of projects'),
  BATCHUPDATEPROJECT: type('[Project] Update a group of projects'),
  BATCHUPDATEPROJECTSUCCESSFUL: type('[Project] Successfully updated batch of projects'),
  BATCHUPDATEPROJECTFAILED: type('[Project] Failed updated batch of projects'),
  RESETPROJECTLOADING: type('[Project] Reset project loading status'),
  ADDNEWPROJECT: type('[Project] Adding new project'),
  ADDNEWPROJECTFAILED: type('[Project] Adding new project failed'),
  ADDNEWPROJECTSUCCESSFUL: type('[Project] Adding new project was successful'),
  MERGEPROJECTS: type('[Project] Merging projects retrieved from local DB and server'),
  UPDATEPROJECTTABLE: type('[Project] Update Project Table'),
  UPDATEPROJECTTAB: type('[Project] Update Project Tab'),
  EDITPROJECT: type('[Project] Edit Project'),
  EDITPROJECTSUCCESSFUL: type('[Project] Edit Project was successful'),
  EDITPROJECTFAILED: type('[Project] Edit Project was failed'),
  EDITPROJECTTWO: type('[Project] Edit Project Two'),
  OPENADDPROJECTDIALOG: type('[Project] Open Add New Project dialog'),
  OPENADDPROJECTDIALOGSUCCESS: type('[Project] Opening Add New Project dialog was successful'),
  OPENADDPROJECTDIALOGFAILED: type('[Project] Opening Add New Project dialog failed'),
  OPENADDTEAMPROJECTDIALOG: type('[Project] Open Add New Team Project dialog'),
  OPENADDTEAMPROJECTDIALOGSUCCESS: type('[Project] Opening Add New Team Project dialog was successful'),
  OPENADDTEAMPROJECTDIALOGFAILED: type('[Project] Opening Add New Team Project dialog failed'),
  SHOWICON: type('[Project] Show icon network status'),
  SHOWICONSUCCESS: type('[Project] Show icon network status was successful'),
  SHOWICONFAILED: type('[Project] Show icon network status failed'),
  HIDEICONSUCCESS: type('[Project] Hiding network status was successful'),
  HIDEICONFAILED: type('[Project] Hiding network status was failed'),
  LINKPROJECTTOEXTERNALAPPLICATION: type('[Project] Link project to external application')
};

export class SelectProjectAction implements Action {
  type = ActionTypes.SELECTPROJECT;

  constructor(public payload: Project) { }
}

export const LoadEnterpriseProjectsFromServerAction = createAction(ActionTypes.LOADENTERPRISEPROJECTSFROMSERVER);
// export const LoadEnterpriseProjectsFromServerSuccessfulAction = createAction(ActionTypes.LOADENTERPRISEPROJECTSFROMSERVERSUCCESSFUL, props<{ projects: Project[] }>());
export const LoadEnterpriseProjectsFromServerFailedAction = createAction(ActionTypes.LOADENTERPRISEPROJECTSFROMSERVERFAILED, props<{ error: any }>());

export class LoadEnterpriseProjectsFromServerSuccessfulAction implements Action {
  type = ActionTypes.LOADENTERPRISEPROJECTSFROMSERVERSUCCESSFUL;

  constructor(public payload: Project[]) { }
}
export class AddNewProject implements Action {
  type = ActionTypes.ADDNEWPROJECT;

  constructor(public payload: Project) { }
}


export class BatchAddProjectAction implements Action {
  type = ActionTypes.BATCHADDPROJECT;

  constructor(public payload: Project[]) { }
}

export class BatchAddProjectSuccesfulAction implements Action {
  type = ActionTypes.BATCHADDPROJECTSUCCESSFUL;

  constructor(public payload: any = {}) { }
}

export class BatchAddProjectFailureAction implements Action {
  type = ActionTypes.BATCHADDPROJECTFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class BatchUpdateProjectAction implements Action {
  type = ActionTypes.BATCHUPDATEPROJECT;

  constructor(public payload: Project[]) { }
}

export class BatchUpdateProjectSuccesfulAction implements Action {
  type = ActionTypes.BATCHUPDATEPROJECTSUCCESSFUL;

  constructor(public payload: any = {}) { }
}

export class BatchUpdateProjectFailureAction implements Action {
  type = ActionTypes.BATCHUPDATEPROJECTFAILED;

  constructor(public payload: ApiErrorResponse) { }
}


export class ResetProjectLoadingAction implements Action {
  type = ActionTypes.RESETPROJECTLOADING;

  constructor(public payload: any = {}) { }
}

export class AddNewProjectSuccessful implements Action {
  type = ActionTypes.ADDNEWPROJECTSUCCESSFUL;

  // I haven't decided on how to handle this, yet.
  constructor(public payload?: Project) { }
}

export class AddNewProjectFailed implements Action {
  type = ActionTypes.ADDNEWPROJECTFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class LoadProjectsFromServerAction implements Action {
  type = ActionTypes.LOADPROJECTSFROMSERVER;

  constructor(public payload: any) { }
}

export class LoadProjectsFromServerSuccessfulAction implements Action {
  type = ActionTypes.LOADPROJECTSFROMSERVERSUCCESSFUL;

  constructor(public payload: any) { }
}

export class LoadProjectsFromServerFailedAction implements Action {
  type = ActionTypes.LOADPROJECTSFROMSERVERFAILED;

  constructor(public payload: any) { }
}

export class MergeProjectsAction implements Action {
  type = ActionTypes.MERGEPROJECTS;

  constructor(public payload: any) { }
}

export class UpdateProjectTableAction implements Action {
  type = ActionTypes.UPDATEPROJECTTABLE;

  constructor(public payload?: ProjectTable) { }
}
export class UpdateProjectTabAction implements Action {
  type = ActionTypes.UPDATEPROJECTTAB;

  constructor(public payload: TabSelection) { }
}
export class EditProjectAction implements Action {
  type = ActionTypes.EDITPROJECT;

  constructor(public payload: Project) { }
}

export class EditProjectSuccessful implements Action {
  type = ActionTypes.EDITPROJECTSUCCESSFUL;

  constructor(public payload: Project) { }
}

export class EditNewProjectFailed implements Action {
  type = ActionTypes.EDITPROJECTFAILED;

  constructor(public payload: any) { }
}
export class OpenProjectDialogAction implements Action {
  type = ActionTypes.OPENADDPROJECTDIALOG;

  constructor(public payload?: any) { }
}

export const OpenAddTeamDialogAction = createAction(ActionTypes.OPENADDTEAMPROJECTDIALOG)
export const OpenAddTeamDialogSuccessAction = createAction(ActionTypes.OPENADDTEAMPROJECTDIALOGSUCCESS)

export class OpenProjectDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENADDPROJECTDIALOGSUCCESS;

  constructor(public payload: any) { }
}

export class OpenProjectDialogFailedAction implements Action {
  type = ActionTypes.OPENADDPROJECTDIALOGFAILED;

  constructor(public payload: any) { }
}

export class HideIconStatusSuccessful implements Action {
  type = ActionTypes.HIDEICONSUCCESS;

  constructor(public payload: any) { }
}
export class HideIconStatusFailed implements Action {
  type = ActionTypes.HIDEICONFAILED;

  constructor(public payload: any) { }
}

export class ShowIconStatus implements Action {
  type = ActionTypes.SHOWICON;

  constructor(public payload: any) { }
}
export class ShowIconStatusSuccessful implements Action {
  type = ActionTypes.SHOWICONSUCCESS;

  constructor(public payload: any) { }
}
export class ShowIconStatusFailed implements Action {
  type = ActionTypes.SHOWICONFAILED;

  constructor(public payload: any) { }
}

export class LinkProjectToExternalApplicationAction implements Action {
  type = ActionTypes.LINKPROJECTTOEXTERNALAPPLICATION;

  constructor(public payload: Project) { }
}

export type Actions = LoadProjectsFromServerFailedAction | AddNewProject |
  LoadProjectsFromServerSuccessfulAction | AddNewProjectSuccessful |
  AddNewProjectFailed | SelectProjectAction | LoadProjectsFromServerAction | BatchAddProjectAction |
  BatchAddProjectSuccesfulAction | BatchAddProjectFailureAction | LoadEnterpriseProjectsFromServerSuccessfulAction |
  ResetProjectLoadingAction | MergeProjectsAction | UpdateProjectTableAction |
  EditProjectAction | EditProjectSuccessful | EditNewProjectFailed | HideIconStatusSuccessful | HideIconStatusFailed |
  ShowIconStatus | ShowIconStatusSuccessful | ShowIconStatusFailed | UpdateProjectTabAction |
  LinkProjectToExternalApplicationAction;
