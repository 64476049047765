import { MetaReducer, createSelector } from '@ngrx/store';

import { ActionReducerMap } from '@ngrx/store';
// import the references to the reducer files, which has the state interfaces, and the reducers themselves
import * as fromLoginUser from './loginuser';
import * as fromLoginRecovery from './forgotpassword';
import * as fromSignUp from './signup';
import * as fromFtux from './ftux';
import * as fromGlobalSettings from './settings';
import * as fromClient from './client';
import * as fromDashboard from './dashboard';
import * as fromTimer from './timer';
import * as fromProject from './project';
import * as fromNotifications from './notifications';
import * as fromApprover from './approver';
import * as fromInvoice from './invoice';
import * as fromUser from './user';
import * as fromAlerts from './alerts';
import * as fromTimesheet from './timesheet';
import * as login from '../actions/loginuser';
import * as fromSeed from './seed';
import * as fromInvoiceDataSource from './invoicedatasource';
import * as fromCustomFields from './customfields';
import * as reports from './reports';

export interface State {
  login: fromLoginUser.State;
  forgotpassword: fromLoginRecovery.State;
  signup: fromSignUp.State;
  ftux: fromFtux.State;
  approver: fromApprover.State,
  globalSettings: fromGlobalSettings.State;
  client: fromClient.State;
  dashboard: fromDashboard.State;
  timer: fromTimer.State;
  project: fromProject.State;
  notifications: fromNotifications.State;
  invoice: fromInvoice.State;
  user: fromUser.State;
  alerts: fromAlerts.State;
  timesheet: fromTimesheet.State;
  seed: fromSeed.State;
  invoiceDataSource: fromInvoiceDataSource.State;
  customFields: fromCustomFields.State;
  reports: reports.State;
}

export const reducers: ActionReducerMap<State> = {
  login: fromLoginUser.reducer,
  forgotpassword: fromLoginRecovery.reducer,
  signup: fromSignUp.reducer,
  approver: fromApprover.reducer,
  ftux: fromFtux.reducer,
  globalSettings: fromGlobalSettings.reducer,
  client: fromClient.reducer,
  dashboard: fromDashboard.reducer,
  timer: fromTimer.reducer,
  project: fromProject.reducer,
  notifications: fromNotifications.reducer,
  invoice: fromInvoice.reducer,
  user: fromUser.reducer,
  alerts: fromAlerts.reducer,
  timesheet: fromTimesheet.reducer,
  seed: fromSeed.reducer,
  invoiceDataSource: fromInvoiceDataSource.reducer,
  customFields: fromCustomFields.customFieldsReducer,
  reports: reports.reportsReducer
};

// const developmentReducer: ActionReducer<State> = compose(storeFreeze, combineReducers)(reducers);
// const productionReducer: ActionReducer<State> = combineReducers(reducers);

// export function reducer(state: any, action: any) {
//   if (environment.production) {
//     return productionReducer(state, action);
//   } else {
//     return developmentReducer(state, action);
//   }
// }


export function logout(reducer) {
  return function newReducer(state, action) {
    if (action.type === login.ActionTypes.LOGOUTSUCCESS) {
      state = undefined;
    }
    const nextState = reducer(state, action);
    return nextState;
  }
}

export const metaReducers: MetaReducer<State>[] = [logout];




// export const metaReducers: MetaReducer<State>[] = !environment.production ? [storeFreeze] : [];

/**
 * This returns the state for the page that is being selected, using the state type for that state
 */
export const getLoginState = (state: State) => state.login;
export const getPWRecoveryState = (state: State) => state.forgotpassword;
export const getSignUpState = (state: State) => state.signup;
export const getFtuxState = (state: State) => state.ftux;
export const getApproverState = (state: State) => state.approver;
export const getGlobalSettingsState = (state: State) => state.globalSettings;
export const getClientState = (state: State) => state.client;
export const getDashboardState = (state: State) => state.dashboard;
export const getTimerState = (state: State) => state.timer;
export const getProjectState = (state: State) => state.project;
export const getNotificationsState = (state: State) => state.notifications;
export const getUserState = (state: State) => state.user;
export const getInvoiceState = (state: State) => state.invoice;
export const getAlertState = (state: State) => state.alerts;
export const getTimesheetState = (state: State) => state.timesheet;
export const getSeedState = (state: State) => state.seed;
export const getInvoiceDataSourceState = (state: State) => state.invoiceDataSource;
export const getCustomFieldsState = (state: State) => state.customFields;
export const getReportsDataState = (state: State) => state.reports;
/**
 * These return the specific state items register.from each state globally, by combining the selectors
 */

// Login User
export const getLogin = createSelector(getLoginState, fromLoginUser.getUser);
export const getLoginError = createSelector(getLoginState, fromLoginUser.getError);
export const getUpdateSuccess = createSelector(getLoginState, fromLoginUser.getUpdateSuccess);
export const getIsAuthenticated = createSelector(getLoginState, fromLoginUser.getAuthenticated);
export const getIsAuthenticationLoading = createSelector(getLoginState, fromLoginUser.getLoading);
export const getAuthenticationLoaded = createSelector(getLoginState, fromLoginUser.getLoaded);
export const getChangePasswordUser = createSelector(getLoginState, fromLoginUser.getChangePasswordUser);
export const getUserProfileRoles = createSelector(getLoginState, fromLoginUser.getRoles);
export const getUserRole = createSelector(getLoginState, fromLoginUser.getUserRole);


// Forgot Password
export const getRecoveryResultLoading = createSelector(getPWRecoveryState, fromLoginRecovery.getRecoveryLoading);
export const getRecoveryEmail = createSelector(getPWRecoveryState, fromLoginRecovery.getRecoveryEmail);
export const getRecoveryResultError = createSelector(getPWRecoveryState, fromLoginRecovery.getRecoveryResultError);

// Sign Up
export const getSignUpPageLoading = createSelector(getSignUpState, fromSignUp.getSignUpLoading);
export const getSignUpPageUser = createSelector(getSignUpState, fromSignUp.getSignUpUser);
export const getSignUpPageError = createSelector(getSignUpState, fromSignUp.getSignUpError);
export const getSignUpPageCompleted = createSelector(getSignUpState, fromSignUp.getSignUpCompleted);
export const getSignUpPageGoogleReg = createSelector(getSignUpState, fromSignUp.getSignUpGoogleReg);

// Ftux
export const getFtuxSecondSlideState = createSelector(getFtuxState, fromFtux.getSecondSlideState);
export const getFtuxThirdSlideState = createSelector(getFtuxState, fromFtux.getThirdSlideState);
export const getFtuxInitialSlideState = createSelector(getFtuxState, fromFtux.getInitialSlideState);
export const getFtuxImportLoading = createSelector(getFtuxState, fromFtux.getImportLading);
export const getFtuxProvidersState = createSelector(getFtuxState, fromFtux.getProvidersState);
export const getFtuxError = createSelector(getFtuxState, fromFtux.getFtuxError);
export const getFtuxLoading = createSelector(getFtuxState, fromFtux.getLoading);
export const getFtuxLoginComplete = createSelector(getFtuxState, fromFtux.getLoginComplete);


// Approver
export const getApproverAuthStatus = createSelector(getApproverState, fromApprover.getAuthStatus);
export const getApproverLoadingStatus = createSelector(getApproverState, fromApprover.getLoadingStatus);
export const getApproverError = createSelector(getApproverState, fromApprover.getError);
export const getApproverComplete = createSelector(getApproverState, fromApprover.getComplete);
export const getApproverProjectLoading = createSelector(getApproverState, fromApprover.getProjectLoading);
export const getApproverProjects = createSelector(getApproverState, fromApprover.getProjects);


// Global Settings
export const getGSettingsProfileAndGlobalState = createSelector(getGlobalSettingsState, fromGlobalSettings.getProfileAndGlobalState);
export const getGSettingsChangePasswordState = createSelector(getGlobalSettingsState, fromGlobalSettings.getChangePasswordState);
export const getGSettingsLoading = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsIsLoading);
export const getGSettingsInvoiceDialog = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsInvoiceDialog);
export const getGSettingsLoadingStatus = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsLoadingFields);
export const getGSettingsGlobalSettingsModel = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsGlobalSettingsModel);
export const getGSettingsActiveTab = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsActiveTab);
export const getGSettingsSelectedInvoiceProvider = createSelector(getGlobalSettingsState,
  fromGlobalSettings.getGSettingsSelectedInvoiceProvider);
export const getGSettingsWeekEnd = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsWeekEnd);
export const getGSettingsRetrievedFromServer = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsReceivedFromServer);
export const getGSettingsPageAccessDisabled = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsPageAccessDisabled);
export const getGSettingsUploadLogoStatus = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsUploadLogoStatus);
export const getGSettingsUploadLogoUrl = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsUploadLogoUrl);
export const getGSettingsTaxTypesList = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsTaxTypes);
export const getGSettingsTaxTypesStatus = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsTaxTypesStatus);
export const getGSettingsAccountActive = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsAccountActive);
export const getGSettingsHistoryStatus = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsHistoryStatus);
export const getGSettingsHistory = createSelector(getGlobalSettingsState, fromGlobalSettings.getGSettingsHistory);

// Clients
export const getClientLoading = createSelector(getClientState, fromClient.getClientsLoading);
export const getClientError = createSelector(getClientState, fromClient.getClientError);
export const getLoadedClients = createSelector(getClientState, fromClient.getClients);
export const getSelectedClient = createSelector(getClientState, fromClient.getSelectedClient);
export const getClientsLoadingMessage = createSelector(getClientState, fromClient.getClientLoadingMsg);
export const getClientSubTab = createSelector(getClientState, fromClient.getClientSubTab);
export const getClientTab = createSelector(getClientState, fromClient.getClientTab);
export const getClientExpenses = createSelector(getClientState, fromClient.getClientExpenses);
export const getInvoiceEmails = createSelector(getClientState, fromClient.getInvoiceEmails);
export const getDates = createSelector(getClientState, fromClient.getClientDates);
export const getRoundingOptions = createSelector(getClientState, fromClient.getRoundingOptions);
export const getTimeOptions = createSelector(getClientState, fromClient.getTimeOptions);
export const getDetailedClientCardInputs = createSelector(getClientState, fromClient.getDetailedClientCardInputs);
export const getClientTimeSheetTemplateStatus = createSelector(getClientState, fromClient.getTimeSheetTemplateStatus);
export const getClientTimeSheetTemplateError = createSelector(getClientState, fromClient.getTimeSheetTemplateError);
export const getTimeSheetExampleStatus = createSelector(getClientState, fromClient.getTimeSheetExampleStatus);
export const getTimeSheetExampleError = createSelector(getClientState, fromClient.getTimeSheetExampleError);

// Dashboard
export const getDashboardLoading = createSelector(getDashboardState, fromDashboard.getLoading);
export const getDashboardSelectedDateRange = createSelector(getDashboardState, fromDashboard.getSelectedDateRange);
export const getDashboardSelectedDate = createSelector(getDashboardState, fromDashboard.getSelectedDate);
export const getDashboardRole = createSelector(getDashboardState, fromDashboard.getCurrentRole);

// Timer
export const getTimerLoading = createSelector(getTimerState, fromTimer.getLoading);
export const getTimerRunningState = createSelector(getTimerState, fromTimer.getTimerState);
export const getTimeEntries = createSelector(getTimerState, fromTimer.getTimeEntries);
export const getRunningTimeEntry = createSelector(getTimerState, fromTimer.getCurrentTimeEntry);
export const getTimerSelectedClient = createSelector(getTimerState, fromTimer.getTimerSelectedClient);

// Project
export const getProjectLoading = createSelector(getProjectState, fromProject.getProjectsLoading);
export const getProjectsList = createSelector(getProjectState, fromProject.getProjects);
export const getProjectSelectedSingle = createSelector(getProjectState, fromProject.getSelectedProject);
export const getProjectError = createSelector(getProjectState, fromProject.getProjectError);
export const getProjectCompleted = createSelector(getProjectState, fromProject.getProjectComplete);
export const getProjectActiveTab = createSelector(getProjectState, fromProject.getActiveTab);

// Notifications
export const getNotificationsModel = createSelector(getNotificationsState, fromNotifications.getNotificationsModel);

// Invoices
export const getInvoices = createSelector(getInvoiceState, fromInvoice.getInvoices);
export const getInvoiceLoading = createSelector(getInvoiceState,fromInvoice.getInvoiceLoading);
export const getInvoiceHistory = createSelector(getInvoiceState, fromInvoice.getInvoiceHistory);
export const getInvoiceHistoryLoading = createSelector(getInvoiceState, fromInvoice.getInvoiceHistoryLoading);
export const getCompanyDSO = createSelector(getInvoiceState, fromInvoice.getCompanyDSO);
export const getClientDSO = createSelector(getInvoiceState, fromInvoice.getClientDSO);
export const getCompanyCollection = createSelector(getInvoiceState, fromInvoice.getCompanyCollection);
export const getCompanyCollectionLoading = createSelector(getInvoiceState, fromInvoice.getCompanyCollectionLoading);

// Invoice Data Source
export const getInvoiceDataSources = createSelector(getInvoiceDataSourceState, fromInvoiceDataSource.getInvoiceDataSources);
export const getInvoiceDataSourceError = createSelector(getInvoiceDataSourceState, fromInvoiceDataSource.getInvoiceDataSourceError);
export const getInvoiceDataSourceLoading = createSelector(getInvoiceDataSourceState, fromInvoiceDataSource.getInvoiceDataSourcesLoading);

// Users
export const getCompanyUsers = createSelector(getUserState, fromUser.getUsersList);
export const getCompanyLoading = createSelector(getUserState, fromUser.getUsersLoading);
export const getCompanyErrors = createSelector(getUserState, fromUser.getUsersError);

// Alerts
export const getAlerts = createSelector(getAlertState, fromAlerts.getAlerts);
export const getAlertFlag = createSelector(getAlertState, fromAlerts.getAlertFlag);

// "Timesheet"
export const getTimesheetHistory = createSelector(getTimesheetState, fromTimesheet.getReportHistory);
export const getReportPageLoading = createSelector(getTimesheetState, fromTimesheet.getReportLoading);
export const getReportPageCsvValues = createSelector(getTimesheetState, fromTimesheet.getReportCsv);

// Seed
export const getSeedLoading = createSelector(getSeedState, fromSeed.getLoading);

// Custom Fields
export const getCustomFieldsSchemas = createSelector(getCustomFieldsState, fromCustomFields.getCustomFieldSchemas);
export const getCustomFieldsEntries = createSelector(getCustomFieldsState, fromCustomFields.getCustomFieldEntries);
export const getCustomFieldsEntriesLoading = createSelector(getCustomFieldsState, fromCustomFields.getCustomFieldEntriesLoading);

//Reports
export const getWageThresholdData = createSelector(getReportsDataState, reports.getWageThresholdData);
export const getWageThresholdStatus = createSelector(getReportsDataState, reports.getWageThresholdDataStatus);
export const getWageThresholdError = createSelector(getReportsDataState, reports.getWageThresholdDataError);