import { Client, ClientError, ClientExpense, ClientExpensesRequest, ClientWithRole } from '../../models/Client';
import { Action, createAction, props } from '@ngrx/store';
import { type } from '../../util';
import { Approver } from '../../models/approver';
import { SubTabSelection, TabSelection } from '../../shared/enums';
import { UUID } from 'angular2-uuid';
import { ApiErrorResponse } from 'app/models/apierror';
import { CustomFieldEntryModel, UpsertCustomFieldEntryModel } from 'app/models/custom-fields';
export const ActionTypes = {
  SELECTCLIENT: type('[Client] Select Client'),
  OPENCLIENTADDDIALOG: type('[Client] Open Client Add dialog'),
  OPENCLIENTADDDIALOGSUCCESSFUL: type('[Client] Opening Client Add dialog successful'),
  OPENCLIENTADDDIALOGFAILED: type('[Client] Opening Client Add dialog failed'),
  LOADCLIENTSFROMDBSUCCESSFUL: type('[Client] Loading clients from local DB Succeeded'),
  LOADCLIENTSWITHOUTBILLEDRATES: type('[Client] Load all clients without billed rates'),
  LOADCLIENTSWITHOUTBILLEDRATESSUCCESSFUL: type('[Client] Successfully loaded clients without billed rates'),
  SAVECLIENTSTODB: type('[Client] Save Clients to local DB'),
  SAVECLIENTSTODBSUCCESS: type('[Client] Successfully saved clients to local DB'),
  SAVECLIENTSTODBFAILED: type('[Client] Failed to save clients to local DB'),
  SUBMITCLIENT: type('[Client] Submit client to server'),
  SUBMITCLIENTSUCCESSFUL: type('[Client] Submitting client to the server was successful'),
  SUBMITCLIENTFAILED: type('[Client] Submitting client to server failed'),
  EDITCLIENT: type('[Client] Edit Clients'),
  EDITCLIENTSUCCESSFUL: type('[Client] Client was successfully updated'),
  EDITCLIENTFAILED: type('[Client] Editing client failed'),
  UPDATECLIENTPAGE: type('[Client] Update Client Page'),
  UPDATECLIENTS: type('[Client] Update clients'),
  UPDATECLIENTSSUCCESSFUL: type('[Client] Clients were successfully updated'),
  UPDATECLIENTSFAILED: type('[Client] Updating Clients failed'),
  LOADCLIENTSFROMSERVER: type('[Client] Load clients from server'),
  LOADCLIENTSFROMSERVERFAILED: type('[Client] Loading clients from server failed'),
  LOADCLIENTSFROMSERVERSUCCESSFUL: type('[Client] Loading clients from server Succeeded'),
  LOADALLCLIENTS: type('[Client] Load clients from local DB and server'),
  OPENCLIENTCARD: type('[Client] Open Client Card'),
  OPENCLIENTCARDSUCCESS: type('[Client] Open Client Card success'),
  OPENCLIENTCARDFAILED: type('[Client] Open Client Card failed'),
  OPENENTERPRISECLIENTCARD: type('[Client] Open Enterprise Client Card'),
  OPENENTERPRISECLIENTCARDSUCCESS: type('[Client] Open Enterprise Client Card success'),
  OPENENTERPRISECLIENTCARDFAILED: type('[Client] Open Enterprise Client Card failed'),
  OPENDELETECLIENTDIALOG: type('[Client] Open Delete Client Dialog'),
  OPENDELETECLIENTDIALOGSUCCESS: type('[Client] Open Delete Client success'),
  OPENDELETECLIENTDIALOGFAILED: type('[Client] Open Delete Client failed'),
  UPDATECLIENTTABLE: type('[Client] Update Client Table'),
  UPDATECLIENTTAB: type('[Client] Update Client Table Tab'),
  UPDATECLIENTCARDSUBTAB: type('[Client] Update Client Card Sub Tab'),
  UPDATECLIENTTABLEACTIVEPAGE: type('[Client] Update Client Table Active Page'),
  UPDATECLIENTDROPDOWN: type('[Client] Update Client dropdown'),
  UPDATECLIENTCARDSETTINGS: type('[Client] Change Detailed Card Client Settings'),
  LOADENTERPRISECLIENTS: type('[Client] Get all enterprise clients for user'),
  LOADENTERPRISECLIENTSSUCCESS: type('[Client] Get all enterprise clients for user success'),
  LOADENTERPRISECLIENTSFAILURE: type('[Client] Get all enterprise clients for user failure'),
  OPENENTERPRISECLIENTADDDIALOG: type('[Client] Open Enterprise Client add dialog'),
  OPENENTERPRISECLIENTADDDIALOGSUCCESS: type('[Client] Open Enterprise Client add dialog success'),
  OPENENTERPRISECLIENTADDDIALOGFAILED: type('[Client] Open Enterprise Client add dialog failure'),
  LOADCLIENTEXPENSES: type('[Client] Get Client Expenses'),
  LOADCLIENTEXPENSESSUCCESS: type('[Client] Get Client expenses success'),
  LOADCLIENTEXPENSESFAILURE: type('[Client] Get Client expenses failure'),
  DOWNLOADCLIENTTIMESHEETTEMPLATE: type('[Client] Download Client TimeSheet Template'),
  DOWNLOADCLIENTTIMESHEETTEMPLATESUCCESS: type('[Client] Download Client TimeSheet Template success'),
  DOWNLOADCLIENTTIMESHEETTEMPLATEFAILURE: type('[Client] Download Client TimeSheet Template failure'),
  DOWNLOADCOMPANYTIMESHEETTEMPLATE: type('[Client] Download Company TimeSheet Template'),
  DOWNLOADCOMPANYTIMESHEETTEMPLATESUCCESS: type('[Client] Download Company TimeSheet Template success'),
  DOWNLOADCOMPANYTIMESHEETTEMPLATEFAILURE: type('[Client] Download Company TimeSheet Template failure'),
  DOWNLOADTIMESHEETEXAMPLE: type('[Client] Download TimeSheet Example'),
  DOWNLOADTIMESHEETEXAMPLESUCCESS: type('[Client] Download TimeSheet Example success'),
  DOWNLOADTIMESHEETEXAMPLEFAILURE: type('[Client] Download TimeSheet Example failure'),
  UPLOADTIMESHEETTEMPLATE: type('[Client] Upload TimeSheet Template'),
  UPLOADTIMESHEETTEMPLATESUCCESS: type('[Client] Upload TimeSheet Template success'),
  UPLOADTIMESHEETTEMPLATEFAILURE: type('[Client] Upload TimeSheet Template failure'),
  RESETTIMESHEETTEMPLATESTATUS: type('[Client] Reset TimeSheet Template status'),
  RESETTIMESHEETEXAMPLESTATUS: type('[Client] Reset TimeSheet Example status'),
};

export const DownloadCompanyTimeSheetTemplateAction = createAction(ActionTypes.DOWNLOADCOMPANYTIMESHEETTEMPLATE);
export const DownloadCompanyTimeSheetTemplateSuccessAction = createAction(ActionTypes.DOWNLOADCOMPANYTIMESHEETTEMPLATESUCCESS);
export const DownloadCompanyTimeSheetTemplateFailureAction = createAction(ActionTypes.DOWNLOADCOMPANYTIMESHEETTEMPLATEFAILURE, props<{error: ApiErrorResponse}>());
export const DownloadClientTimeSheetTemplateAction = createAction(ActionTypes.DOWNLOADCLIENTTIMESHEETTEMPLATE, props<{clientId: string}>());
export const DownloadClientTimeSheetTemplateSuccessAction = createAction(ActionTypes.DOWNLOADCLIENTTIMESHEETTEMPLATESUCCESS);
export const DownloadClientTimeSheetTemplateFailureAction = createAction(ActionTypes.DOWNLOADCLIENTTIMESHEETTEMPLATEFAILURE, props<{error: ApiErrorResponse}>());
export const DownloadTimeSheetExampleAction = createAction(ActionTypes.DOWNLOADTIMESHEETEXAMPLE, props<{clientId: string}>());
export const DownloadTimeSheetExampleSuccessAction = createAction(ActionTypes.DOWNLOADTIMESHEETEXAMPLESUCCESS);
export const DownloadTimeSheetExampleFailureAction = createAction(ActionTypes.DOWNLOADTIMESHEETEXAMPLEFAILURE, props<{error: ApiErrorResponse}>());
export const UploadTimeSheetTemplateAction = createAction(ActionTypes.UPLOADTIMESHEETTEMPLATE, props<{clientId: string, file: File}>());
export const UploadTimeSheetTemplateSuccessAction = createAction(ActionTypes.UPLOADTIMESHEETTEMPLATESUCCESS);
export const UploadTimeSheetTemplateFailureAction = createAction(ActionTypes.UPLOADTIMESHEETTEMPLATEFAILURE, props<{error: ApiErrorResponse}>());
export const ResetTimeSheetTemplateStatusAction = createAction(ActionTypes.RESETTIMESHEETTEMPLATESTATUS);
export const ResetTimeSheetExampleStatusAction = createAction(ActionTypes.RESETTIMESHEETEXAMPLESTATUS);

export class SelectClientAction implements Action {
  type = ActionTypes.SELECTCLIENT;

  constructor(public payload: Client) {}
}

export class SubmitClientAction implements Action {
  type = ActionTypes.SUBMITCLIENT;

  constructor(public payload: ClientWithRole) {}
}

export class SubmitClientSuccessfulAction implements Action {
  type = ActionTypes.SUBMITCLIENTSUCCESSFUL;

  constructor(public payload: Client, public customFields?: { entryToAdd: UpsertCustomFieldEntryModel, originalEntry: CustomFieldEntryModel }[]) {}
}

export class SubmitClientFailedAction implements Action {
  type = ActionTypes.SUBMITCLIENTFAILED;

  constructor(public payload: any) {}
}

// for the future, we might need different "open client add dialog" actions, based upon where the opening is done,
// since we might need to trigger different effects based upon it
export class OpenClientAddDialogAction implements Action {
  type = ActionTypes.OPENCLIENTADDDIALOG;
  constructor(public payload?: any) {}
}

export class OpenClientAddDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENCLIENTADDDIALOGSUCCESSFUL;
  constructor(public payload?: any) {}
}

export class OpenClientAddDialogFailedAction implements Action {
  type = ActionTypes.OPENCLIENTADDDIALOGFAILED;
  constructor(public payload?: any) {}
}

export class SaveClientsToDbAction implements Action {
  type = ActionTypes.SAVECLIENTSTODB;

  constructor(public payload?: Client | Client[]) {}
}

export class SaveClientsToDbFailedAction implements Action {
  type = ActionTypes.SAVECLIENTSTODBFAILED;

  constructor(public payload: any) {}
}

export class SaveClientsToDbSuccessfulAction implements Action {
  type = ActionTypes.SAVECLIENTSTODBSUCCESS;

  constructor(public payload: any) {}
}

export class LoadClientsFromServerAction implements Action {
  type = ActionTypes.LOADCLIENTSFROMSERVER;

  constructor(public payload: any) {}
}

export class LoadClientsFromServerSuccessfulAction implements Action {
  type = ActionTypes.LOADCLIENTSFROMSERVERSUCCESSFUL;

  constructor(public payload: Client[]) {}
}

export class LoadClientsFromServerFailedAction implements Action {
  type = ActionTypes.LOADCLIENTSFROMSERVERFAILED;

  constructor(public payload: any) {}
}

export class LoadClientsWithoutBilledRatesAction implements Action {
  type = ActionTypes.LOADCLIENTSWITHOUTBILLEDRATES;

  constructor(public payload: any | null) {}
}

export class LoadClientsWithoutBilledRatesSuccessAction implements Action {
  type = ActionTypes.LOADCLIENTSWITHOUTBILLEDRATESSUCCESSFUL;

  constructor(public payload: Client[]) {}
}

export class EditClientAction implements Action {
  type = ActionTypes.EDITCLIENT;

  constructor(public payload: Client) {}
}

export class EditClientSuccessAction implements Action {
  type = ActionTypes.EDITCLIENTSUCCESSFUL;

  constructor(public payload: Client) {}
}

export class EditClientFailureAction implements Action {
  type = ActionTypes.EDITCLIENTFAILED;

  constructor(public payload: ClientError) {}
}

export class UpdateClientPageAction implements Action {
  type = ActionTypes.UPDATECLIENTPAGE;

  constructor(public payload: Client | ClientError) {}
}

export class UpdateClientsAction implements Action {
  type = ActionTypes.UPDATECLIENTS;

  constructor(public payload: Client[]) {}
}

export class UpdateClientsSuccessAction implements Action {
  type = ActionTypes.UPDATECLIENTSSUCCESSFUL;

  constructor(public payload: Client[]) {}
}

export class UpdateClientsFailureAction implements Action {
  type = ActionTypes.UPDATECLIENTSFAILED;

  constructor(public payload: any) {}
}

export class LoadClientsFromLocalDbSuccessAction implements Action {
  type = ActionTypes.LOADCLIENTSFROMDBSUCCESSFUL;

  constructor(public payload: Client[]) {}
}

export class LoadAllClientsAction implements Action {
  type = ActionTypes.LOADALLCLIENTS;

  constructor(public payload?: UUID) {}
}

export class OpenClientDialogCardAction implements Action {
  type = ActionTypes.OPENCLIENTCARD;
  constructor(public payload?: any) {}
}
export class OpenClientCardSuccessAction implements Action {
  type = ActionTypes.OPENCLIENTCARDSUCCESS;

  constructor(public payload?: any) {}
}
export class OpenClientCardFailedAction implements Action {
  type = ActionTypes.OPENCLIENTCARDFAILED;
  constructor(public payload?: any) {}
}
export class OpenEnterpriseClientDialogCardAction implements Action {
  type = ActionTypes.OPENENTERPRISECLIENTCARD;
  constructor(public payload?: any) {}
}
export class OpenEnterpriseClientDialogCardSuccessAction implements Action {
  type = ActionTypes.OPENENTERPRISECLIENTCARDSUCCESS;
  constructor(public payload?: any) {}
}
export class OpenEnterpriseClientDialogCardFailedAction implements Action {
  type = ActionTypes.OPENENTERPRISECLIENTCARDFAILED;
  constructor(public payload?: any) {}
}
export class OpenDeleteClientDialogAction implements Action {
  type = ActionTypes.OPENDELETECLIENTDIALOG;

  constructor(public payload: Client) { }
}
export class OpenDeleteClientDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENDELETECLIENTDIALOGSUCCESS;
  constructor(public payload?: Client) { }
}
export class OpenDeleteClientDialogFailedAction implements Action {
  type = ActionTypes.OPENDELETECLIENTDIALOGFAILED;

  constructor(public payload?: Client) { }
}
export class OpenEnterpriseClientAddDialogAction implements Action {
  type = ActionTypes.OPENENTERPRISECLIENTADDDIALOG;
  constructor(public payload?: any) { }
}

export class OpenEnterpriseClientAddDialogSuccessAction implements Action {
  type = ActionTypes.OPENENTERPRISECLIENTADDDIALOGSUCCESS;
  constructor(public payload?: any) {}
}

export class OpenEnterpriseClientAddDialogFailedAction implements Action {
  type = ActionTypes.OPENENTERPRISECLIENTADDDIALOGFAILED;
  constructor(public payload?: any) {}
}

export class UpdateClientTableAction implements Action {
  type = ActionTypes.UPDATECLIENTTABLE;

  constructor(public payload?: any) {}
}
export class UpdateClientTabAction implements Action {
  type = ActionTypes.UPDATECLIENTTAB;

  constructor(public payload?: TabSelection) {}
}
export class UpdateClientCardSubTabAction implements Action {
  type = ActionTypes.UPDATECLIENTCARDSUBTAB;

  constructor(public payload?: SubTabSelection) {}
}
export class UpdateClientTableActivePageAction implements Action {
  type = ActionTypes.UPDATECLIENTTABLEACTIVEPAGE;

  constructor(public payload?: any) {}
}
export class UpdateClientDropDownAction implements Action {
  type = ActionTypes.UPDATECLIENTDROPDOWN;

  constructor(public payload?: any) {}
}
/* The action gets called when the user clicks on any of the 3 invoice provider buttons */
export class UpdateClientCardDetailSettings implements Action {
  type = ActionTypes.UPDATECLIENTCARDSETTINGS;

  constructor(public payload?: Approver) {}
}

export class LoadEnterpriseClientsAction implements Action  {
  type = ActionTypes.LOADENTERPRISECLIENTS;

  constructor(public payload: UUID) {}
}

export class LoadEnterpriseClientsSuccessAction implements Action {
  type = ActionTypes.LOADENTERPRISECLIENTSSUCCESS;

  constructor(public payload?: Client[]) {}
}

export class LoadEnterpriseClientsFailureAction implements Action {
  type = ActionTypes.LOADENTERPRISECLIENTSFAILURE;

  constructor(public payload?: Client[]) {}
}

export class LoadClientExpensesAction implements Action {
  type = ActionTypes.LOADCLIENTEXPENSES;

  constructor(public payload: ClientExpensesRequest) {}
}

export class LoadClientExpensesSuccessAction implements Action {
  type = ActionTypes.LOADCLIENTEXPENSESSUCCESS;

  constructor(public payload: ClientExpense[]) {}
}

export class LoadClientExpensesFailureAction implements Action {
  type = ActionTypes.LOADCLIENTEXPENSESFAILURE;

  constructor(public payload: ApiErrorResponse) {}
}

export type Actions =
  LoadClientsWithoutBilledRatesAction
  | OpenClientAddDialogSuccessfulAction
  | UpdateClientsAction
  | UpdateClientsSuccessAction
  | LoadClientsFromLocalDbSuccessAction
  | LoadClientsFromServerSuccessfulAction
  | OpenClientAddDialogFailedAction
  | UpdateClientsFailureAction
  | LoadClientsWithoutBilledRatesSuccessAction
  | SubmitClientAction
  | LoadAllClientsAction
  | SelectClientAction
  | OpenClientAddDialogAction
  | SubmitClientSuccessfulAction
  | SubmitClientFailedAction
  | OpenClientDialogCardAction
  | OpenClientCardSuccessAction
  | OpenClientCardFailedAction
  | OpenEnterpriseClientDialogCardAction
  | OpenEnterpriseClientAddDialogAction
  | OpenEnterpriseClientAddDialogSuccessAction
  | OpenEnterpriseClientAddDialogFailedAction
  | OpenEnterpriseClientDialogCardSuccessAction
  | OpenEnterpriseClientDialogCardFailedAction
  | UpdateClientTableAction
  | UpdateClientDropDownAction
  | UpdateClientCardDetailSettings
  | EditClientAction
  | EditClientSuccessAction
  | EditClientFailureAction
  | LoadEnterpriseClientsAction
  | LoadEnterpriseClientsSuccessAction
  | UpdateClientPageAction
  | UpdateClientTabAction
  | UpdateClientTableActivePageAction
  | UpdateClientCardSubTabAction
  | LoadClientExpensesAction
  | LoadClientExpensesSuccessAction
  | LoadClientExpensesFailureAction
  | typeof DownloadCompanyTimeSheetTemplateAction
  | typeof DownloadCompanyTimeSheetTemplateSuccessAction
  | typeof DownloadCompanyTimeSheetTemplateFailureAction
  | typeof DownloadClientTimeSheetTemplateAction
  | typeof DownloadClientTimeSheetTemplateSuccessAction
  | typeof DownloadClientTimeSheetTemplateFailureAction
  | typeof DownloadTimeSheetExampleAction
  | typeof DownloadTimeSheetExampleSuccessAction
  | typeof DownloadTimeSheetExampleFailureAction
  | typeof UploadTimeSheetTemplateAction
  | typeof UploadTimeSheetTemplateSuccessAction
  | typeof UploadTimeSheetTemplateFailureAction
  | typeof ResetTimeSheetTemplateStatusAction
  | typeof ResetTimeSheetExampleStatusAction;
