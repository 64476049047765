import { type } from '../../util';
import { Notification } from '../../models/notification';
import { Action } from '@ngrx/store';
import { ApiErrorResponse } from 'app/models/apierror';

export const ActionTypes = {
  UPDATENOTIFICATION: type('[Notifications] Update notifications model'),
  UPDATENOTIFICATIONSSUCCESS: type('[Notifications] Update notifications model was successful'),
  UPDATENOTIFICATIONSFAILED: type('[Notifications] Update notifications model failed'),
  ADDNOTIFICATION: type('[Notifications] Add notification'),
  ADDNOTIFICATIONITEMSUCCESS: type('[Notifications] Add notification was successful'),
  ADDNOTIFICATIONITEMFAILED: type('[Notifications] Add notification item failed'),
  READNOTIFICATION: type('[Notifications] Read notification'),
  DELETENOTIFICATION: type('[Notifications] Delete notification'),
  GETNOTIFICATIONS: type('[Notifications] Get notifications'),
  GETNOTIFICATIONSSUCCESS: type('[Notifications] Get notifications successful'),
  GETNOTIFICATIONSFAILURE: type('[Notifications] Failed to get notifications successful'),
};

export class GetNotificationsAction implements Action {
  type = ActionTypes.GETNOTIFICATIONS;

  constructor() { }
}

export class GetNotificationsSuccessAction implements Action {
  type = ActionTypes.GETNOTIFICATIONSSUCCESS;

  constructor(public payload: Notification[]) { }
}

export class GetNotificationsFailureAction implements Action {
  type = ActionTypes.GETNOTIFICATIONSFAILURE;

  constructor(public payload: ApiErrorResponse) { }
}

export class UpdateNotificationAction implements Action {
  type = ActionTypes.UPDATENOTIFICATION;

  constructor(public payload: Notification) { }
}

export class UpdateNotificationsSuccessAction implements Action {
  type = ActionTypes.UPDATENOTIFICATIONSSUCCESS;

  constructor(public payload: Notification) { }
}

export class UpdateNotificationsFailureAction implements Action {
  type = ActionTypes.UPDATENOTIFICATIONSFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class AddNotificationAction implements Action {
  type = ActionTypes.ADDNOTIFICATION;

  constructor(public payload: Notification) { }
}

export type Actions = UpdateNotificationAction | UpdateNotificationsSuccessAction
  | UpdateNotificationsFailureAction | AddNotificationAction
  | GetNotificationsAction
  | GetNotificationsSuccessAction;
