
import dayjs from 'dayjs';
import { Actions, ActionTypes } from '../actions/dashboard';
import { UserCompanyRole } from '@cartwheel/web-components';

export interface State {
  loading: boolean;
  selectedDate?: Date | null;
  selectedDateRange?: Date[] | null;
  currentRole: UserCompanyRole;
}

export const initialState: State = {
  loading: false,
  selectedDate: new Date(),
  selectedDateRange: [
    dayjs().startOf('week').subtract(1, 'week').startOf('day').toDate(), 
    dayjs().startOf('week').subtract(1, 'day').endOf('day').toDate()
  ],
  currentRole: null
};

export function reducer(state = initialState, action: Actions): State {

  switch (action.type) {
    case ActionTypes.LOADDASHBOARD: {
      const dateRange = action.payload;

      return {
        ...state,
        selectedDateRange: [
          dayjs(dateRange[0]).startOf('day').toDate(),
          dayjs(dateRange[1]).endOf('day').toDate()
        ]
      };
    }

    case ActionTypes.CHANGECURRENTROLE: {
      return {
        ...state,
        currentRole: action.payload
      };
    }

    default:
      return state;
  }

}

export const getLoading = (state: State) => state.loading;
export const getSelectedDateRange = (state: State) => state.selectedDateRange;
export const getSelectedDate = (state: State) => state.selectedDate;
export const getCurrentRole = (state: State) => state.currentRole;
