import { Actions, ActionTypes } from '../actions/project';
import { Project } from '../../models/project';
import { SuccessStatus, TabSelection } from '../../shared/enums';

export interface State {
  loading: boolean | SuccessStatus;
  projects: Project[];
  selectedTab?: TabSelection;
  selectedPage?: number;
  selectedProject?: Project;
  error?: any;
  complete?: boolean;
}

export const initialState: State = {
  loading: false,
  projects: new Array<Project>(),
  selectedTab: TabSelection.All,
  selectedPage: 0,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOADENTERPRISEPROJECTSFROMSERVERSUCCESSFUL:
    case ActionTypes.LOADPROJECTSFROMSERVERSUCCESSFUL: {
      const projects: Project[] = action.payload;
      let newData = [];
      // Search for the status on the project array to determine what project belongs on the ALL, ACTIVE, and ARCHIVED list.
      if (projects && projects.length > 0) {
        newData = projects.map(e => {
          const newProj = new Project(e);
          newProj.projectBilledHours = newProj.projectBilledHours ? newProj.projectBilledHours : 0;
          newProj.projectEarnings = newProj.projectEarnings ? newProj.projectEarnings : 0;
          newProj.projectStatus = newProj.projectStatus ? newProj.projectStatus : null;
          return newProj;
        });
      }
      return Object.assign({}, state, {
        projects: newData,
        loading: false,
        error: undefined,
      });
    }

    case ActionTypes.SELECTPROJECT: {
      const proj = new Project(action.payload);
      return Object.assign({}, state, {
        selectedProject: proj,
        loading: false,
        error: undefined
      });
    }

    case ActionTypes.ADDNEWPROJECT: {
      const newProj: Project = action.payload;

      return Object.assign({}, state, {
        selectedProject: newProj,
        projects: [...state.projects, newProj],
        loading: SuccessStatus.IsLoading,
        complete: false
      });
    }

    case ActionTypes.ADDNEWPROJECTSUCCESSFUL: {
      return Object.assign({}, state, {
        loading: SuccessStatus.Success,
        error: undefined,
        complete: true
      });
    }

    case ActionTypes.RESETPROJECTLOADING: {
      return Object.assign({}, state, {
        loading: false,
        complete: false
      })
    }

    case ActionTypes.ADDNEWPROJECTFAILED: {
      return Object.assign({}, state, {
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
        error: !action.payload.accountInActive ? action.payload : undefined,
        complete: true
      });
    }

    case ActionTypes.EDITPROJECT: {
      const project = action.payload;

      const projectPosition = state.projects.map(c => c.projectId).indexOf(project.projectId);
      let newSelectedProject = null;
      let newProjList = null;
      newSelectedProject = project;

      if (projectPosition >= 0) {
        newProjList = [
          ...state.projects.slice(0, projectPosition),
          Object.assign({}, state.projects[projectPosition], project),
          ...state.projects.slice(projectPosition + 1)
        ];
      } else {
        newProjList = new Array<Project>(...state.projects, project);
      }
      newProjList[projectPosition].projectStatus = SuccessStatus.IsLoading;

      return Object.assign({}, state, {
        projects: newProjList,
        loading: true,
      });
    }

    case ActionTypes.EDITPROJECTSUCCESSFUL: {
      const project = action.payload;
      const projectPosition = state.projects.map(c => c.projectId).indexOf(project.projectId);
      let newSelectedProject = null;
      let newProjList = null;
      newSelectedProject = project;
      if (projectPosition >= 0) {
        newProjList = [
          ...state.projects.slice(0, projectPosition),
          Object.assign({}, state.projects[projectPosition], project),
          ...state.projects.slice(projectPosition + 1)
        ];
      } else {
        newProjList = new Array<Project>(...state.projects, project);
      }
      newProjList[projectPosition].projectStatus = SuccessStatus.Success;

      return Object.assign({}, state, {
        projects: newProjList,
        loading: true
      });
    }
    case ActionTypes.EDITPROJECTFAILED: {
      const project = action.payload;
      console.table(project);
      const projectPosition = state.projects.map(c => c.projectId).indexOf(project.projectId);
      let newSelectedProject = null;
      let newProjList = null;
      newSelectedProject = project;
      if (projectPosition >= 0) {
        newProjList = [
          ...state.projects.slice(0, projectPosition),
          Object.assign({}, state.projects[projectPosition], project),
          ...state.projects.slice(projectPosition + 1)
        ];
      } else {
        newProjList = new Array<Project>(...state.projects, project);
      }
      newProjList[projectPosition].projectStatus = SuccessStatus.Error;

      return Object.assign({}, state, {
        projects: newProjList,
        loading: true
      });
    }

    case ActionTypes.HIDEICONSUCCESS: {
      const project = action.payload;
      const projectPosition = state.projects.map(c => c.projectId).indexOf(project.projectId);
      let newSelectedProject = null;
      let newProjList = null;
      newSelectedProject = project;
      if (projectPosition >= 0) {
        newProjList = [
          ...state.projects.slice(0, projectPosition),
          Object.assign({}, state.projects[projectPosition], project),
          ...state.projects.slice(projectPosition + 1)
        ];
      } else {
        newProjList = new Array<Project>(...state.projects, project);
      }
      newProjList[projectPosition].projectStatus = null;

      return Object.assign({}, state, {
        projects: newProjList,
        loading: true
      });
    }

    case ActionTypes.BATCHADDPROJECT: {
      return {
        ...state,
        loading: SuccessStatus.IsLoading
      }
    }

    case ActionTypes.BATCHADDPROJECTSUCCESSFUL: {
      return {
        ...state,
        loading: SuccessStatus.Success
      }
    }

    case ActionTypes.BATCHADDPROJECTFAILED: {
      return {
        ...state,
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
      }
    }

    case ActionTypes.RESETPROJECTLOADING: {
      return {
        ...state,
        loading: false
      }
    }

    case ActionTypes.UPDATEPROJECTTAB: {
      const tab = action.payload;
      if (tab === undefined) {
        return Object.assign({}, state, {
          selectedTab: TabSelection.All,
        });
      }
      return Object.assign({}, state, {
        selectedTab: tab,
      });
    }
    default:
      return state;
  }
}

export const getProjects = (state: State) => state.projects;
export const getProjectsLoading = (state: State) => state.loading;
export const getSelectedProject = (state: State) => state.selectedProject;
export const getProjectError = (state: State) => state.error;
export const getProjectComplete = (state: State) => state.complete;
export const getActiveTab = (state: State) => state.selectedTab;


