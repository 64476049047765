import { Actions, ActionTypes } from '../actions/notifications';
import { Notification } from '../../models/notification';

export interface State {
  notifications: Notification[];
  selectedNotificationItem: Notification;
}

export const initialState: State = {
    notifications: new Array<Notification>(),
    selectedNotificationItem: {} as Notification,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case ActionTypes.UPDATENOTIFICATIONSSUCCESS: {
      const index = state.notifications.findIndex(n => n.notificationId === action.payload.notificationId);
      return {
        ...state,
        notifications: [
          ...state.notifications.slice(0, index),
          action.payload,
          ...state.notifications.slice(index + 1)
        ]
      };
    }

    case ActionTypes.GETNOTIFICATIONSSUCCESS: {
      return {
        ...state,
        notifications: [...action.payload].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      }
    }

    case ActionTypes.ADDNOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      }
    }

    default:
      return state;
  }
}

export const getNotificationsModel = (state: State) => state.notifications;
