import {
  CustomFieldEntity,
  CustomFieldEntryModel,
  CustomFieldSchemaModel,
  DeleteCustomFieldEntryModel,
  DeleteCustomFieldSchemaModel,
  InsertCustomFieldSchemaModel,
  UpdateCustomFieldSchemaModel,
  UpsertCustomFieldEntryModel,
  ValidationProblemDetails,
} from 'app/models/custom-fields';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CustomFieldActions = createActionGroup({
  source: 'Custom Fields',
  events: {
    'Create Custom Field Schema': props<{ schemaToAdd: InsertCustomFieldSchemaModel, tempId: string }>(),
    'Create Custom Field Schema Failure': props<{ tempId: string, error?: ValidationProblemDetails }>(),
    'Create Custom Field Schema Success': props<{ addedSchema: CustomFieldSchemaModel, tempId: string }>(),
    'Delete Custom Field Schema': props<{ schemaToDelete: DeleteCustomFieldSchemaModel, index: number }>(),
    'Delete Custom Field Schema Failure': props<{ error?: ValidationProblemDetails }>(),
    'Delete Custom Field Schema Success': props<{ success: boolean, index: number }>(),
    'Get All Custom Field Schemas': emptyProps(),
    'Get All Custom Field Schemas Failure': props<{ error?: ValidationProblemDetails }>(),
    'Get All Custom Field Schemas Success': props<{ schemas: Array<CustomFieldSchemaModel> }>(),
    'Get Custom Field Schemas By Entity': props<{ entity: CustomFieldEntity }>(),
    'Get Custom Field Schemas By Entity Failure': props<{ error?: ValidationProblemDetails }>(),
    'Get Custom Field Schemas By Entity Success': props<{ schemas: Array<CustomFieldSchemaModel> }>(),
    'Update Custom Field Schema': props<{ originalSchema: CustomFieldSchemaModel, schemaToUpdate: UpdateCustomFieldSchemaModel, index: number }>(),
    'Update Custom Field Schema Failure': props<{ originalSchema: CustomFieldSchemaModel, index: number, error?: ValidationProblemDetails }>(),
    'Update Custom Field Schema Success': props<{ updatedSchema: CustomFieldSchemaModel, index: number }>(),
    'Get Custom Field Entries By Entity And Entity Ids': props<{ entity: CustomFieldEntity, entityIds?: string[] }>(),
    'Get Custom Field Entries By Entity And Entity Ids Failure': props<{ error?: ValidationProblemDetails }>(),
    'Get Custom Field Entries By Entity And Entity Ids Success': props<{ entries: Array<CustomFieldEntryModel> }>(),
    'Create Custom Field Entry': props<{ entryToAdd: UpsertCustomFieldEntryModel, originalEntry?: CustomFieldEntryModel }>(),
    'Create Custom Field Entry Failure': props<{ error?: ValidationProblemDetails, originalEntry?: CustomFieldEntryModel }>(),
    'Create Custom Field Entry Success': props<{ addedEntry: CustomFieldEntryModel, customFieldEntity: CustomFieldEntity }>(),
    'Update Custom Field Entry': props<{ entryToUpdate: UpsertCustomFieldEntryModel, originalEntry: CustomFieldEntryModel }>(),
    'Update Custom Field Entry Failure': props<{ error?: ValidationProblemDetails, originalEntry: CustomFieldEntryModel  }>(),
    'Update Custom Field Entry Success': props<{ updatedEntry: CustomFieldEntryModel, customFieldEntity: CustomFieldEntity }>(),
    'Delete Custom Field Entry': props<{ entryToDelete: DeleteCustomFieldEntryModel, originalEntry: CustomFieldEntryModel, defaultFieldValue: string }>(),
    'Delete Custom Field Entry Failure': props<{ error?: ValidationProblemDetails, originalEntry: CustomFieldEntryModel }>(),
    'Delete Custom Field Entry Success': props<{ success: boolean, deletedEntry: DeleteCustomFieldEntryModel, originalEntry: CustomFieldEntryModel, customFieldEntity: CustomFieldEntity }>(),
  }
});

type CreateCustomFieldEntrySuccessAction = ReturnType<typeof CustomFieldActions.createCustomFieldEntrySuccess>;
type UpdateCustomFieldEntrySuccessAction = ReturnType<typeof CustomFieldActions.updateCustomFieldEntrySuccess>;
type DeleteCustomFieldEntrySuccessAction = ReturnType<typeof CustomFieldActions.deleteCustomFieldEntrySuccess>;

export type CustomFieldEntrySuccessActions =
  | CreateCustomFieldEntrySuccessAction
  | UpdateCustomFieldEntrySuccessAction
  | DeleteCustomFieldEntrySuccessAction;