import { Action } from '@ngrx/store';
import { OAuthIdent, OAuthRequest } from '../../models/OAuthIdent';
import { type } from '../../util';
import { ExternalInvoice } from '../../models/external-invoice';
import { ExternalInvoicingSystems, SuccessStatus } from 'app/shared/enums';
import { PasswordAuthForm } from 'app/models/approver';
import { ApiErrorResponse } from 'app/models/apierror';

export const ActionTypes = {
  GOTOSECONDSLIDE: type('[Ftux] Go to the second slide'),
  GOTOTHRIRDSLIDE: type('[Ftux] Go to third slide'),
  GETREQUESTTOKEN: type('[Ftux] Submitting request for request token'),
  GETREQUESTTOKENERROR: type('[Ftux] Error retrieving request token'),
  STARTUSERAUTHORIZATION: type('[Ftux] Start user authorization'),
  USERAUTHORIZATIONSUCCESSFUL: type('[Ftux] User Authorization was successful'),
  USERAUTHORIZATIONERROR: type('[Ftux] There was an User Authorization Error'),
  GENERATEUSERTOKEN: type('[Ftux] Generating user token on server'),
  GENERATEUSERTOKENERROR: type('[Ftux] There was an error with generating the user token'),
  GENERATEUSERTOKENSUCCESSFUL: type('[Ftux] Successfully Generated user token'),
  GENERATEUSERTOKENCOMPLETE: type('[Ftux] Completed actions for token generation'),
  IMPORTEXTERNALCLIENTS: type('[Ftux] Start importing external client'),
  FTUXCOMPLETE: type('[Ftux] FTUX Process complete'),
  DISCONNECTPROVIDER: type('[Ftux] Disconnect provider'),
  DISCONNECTPROVIDERSUCCESSFUL: type('[Ftux] Disconnecting provider successful'),
  DISCONNECTPROVIDERERROR: type('[Ftux] Disconnecting provider error'),
  GETCURRENTPROVIDERSUCCESSFUL: type('[Ftux] Successfully retrieved provider from server'),
  EXTERNALINVOICEPROVIDERLOGIN: type('[Ftux] Logging in to external provider'),
  EXTERNALINVOICEPROVIDERLOGINSUCCESS: type('[Ftux] Successfully logged in to external provider'),
  EXTERNALINVOICEPROVIDERLOGINFAILURE: type('[Ftux] Failed to log in to external provider'),
  EXTERNALINVOICEPROVIDERLOGINCOMPLETE: type('[Ftux] Log in attempt complete (resetting UI)'),
  RESETEXTERNALPROVIDERS: type('[Ftux] Reset external providers'),
  DISABLEPROVIDERS: type('[Ftux] Disable providers while loading settings'),
  SETEXTERNALPROVIDERSTATUS: type('[Ftux] Set status for a single provider')
};

export class GenerateUserTokenAction implements Action {
  type = ActionTypes.GENERATEUSERTOKEN;

  constructor(public payload: OAuthIdent) { }
}

export class GenerateUserTokenSuccessfulAction implements Action {
  type = ActionTypes.GENERATEUSERTOKENSUCCESSFUL;

  constructor(public payload: ProviderSuccess) { }
}

export class GenerateUserTokenErrorAction implements Action {
  type = ActionTypes.GENERATEUSERTOKENERROR;

  constructor(public payload: ProviderError) { }
}

export class GenerateUserTokenCompleteAction implements Action {
  type = ActionTypes.GENERATEUSERTOKENCOMPLETE;

  constructor(public payload?: any) { }
}

export class GoToSecondSlideAction implements Action {
  type = ActionTypes.GOTOSECONDSLIDE;

  constructor(public payload: string = '') { }
}

export class GoToThirdSlideAction implements Action {
  type = ActionTypes.GOTOTHRIRDSLIDE;

  constructor(public payload: string = '') { }
}

export class GetExternalRequestTokenAction implements Action {
  type = ActionTypes.GETREQUESTTOKEN;
  /**
   *
   * @param payload This should be the string name for the provider. ex: quickbooks for QuickBooks
   */
  constructor(public payload: OAuthRequest) { }
}

export class GetRequestTokenErrorAction implements Action {
  type = ActionTypes.GETREQUESTTOKENERROR;

  constructor(public payload: ProviderError) { }
}

export class ImportExternalClientsAction implements Action {
  type = ActionTypes.IMPORTEXTERNALCLIENTS;
  constructor(public payload: string) { }
}

export class StartUserAuthorizationAction implements Action {
  type = ActionTypes.STARTUSERAUTHORIZATION;

  constructor(public payload: OAuthRequest) { }
}

export class ExternalInvoiceProviderLoginAction implements Action {
  type = ActionTypes.EXTERNALINVOICEPROVIDERLOGIN;

  constructor(public payload: PasswordAuthForm) { }
}
export class ExternalInvoiceProviderLoginSuccessAction implements Action {
  type = ActionTypes.EXTERNALINVOICEPROVIDERLOGINSUCCESS;

  constructor(public payload: { provider: string, result: any, billComTeamProvider: boolean }) { }
}
export class ExternalInvoiceProviderLoginFailureAction implements Action {
  type = ActionTypes.EXTERNALINVOICEPROVIDERLOGINFAILURE;

  constructor(public payload: { provider: string, error: ApiErrorResponse, billComTeamProvider: boolean }) { }
}
export class ExternalInvoiceProviderLoginCompleteAction implements Action {
  type = ActionTypes.EXTERNALINVOICEPROVIDERLOGINCOMPLETE;

  constructor(public payload: { provider: string, closeDialog: boolean, billComTeamProvider: boolean }) { }
}

export class UserAuthorizationErrorAction implements Action {
  type = ActionTypes.USERAUTHORIZATIONERROR;

  constructor(public payload: ApiErrorResponse & { service: string }) { }
}

export class UserAuthorizationSuccessAction implements Action {
  type = ActionTypes.USERAUTHORIZATIONSUCCESSFUL;

  constructor(public payload: string) { }
}

export class FtuxCompleteAction implements Action {
  type = ActionTypes.FTUXCOMPLETE;

  constructor(public payload: boolean) { }
}

export class DisconnectProviderAction implements Action {
  type = ActionTypes.DISCONNECTPROVIDER;

  constructor(public payload: ExternalInvoice) { }
}

export class DisconnectProviderSuccessfulAction implements Action {
  type = ActionTypes.DISCONNECTPROVIDERSUCCESSFUL;

  constructor(public payload: ExternalInvoice) { }
}

export class DisconnectProviderErrorAction implements Action {
  type = ActionTypes.DISCONNECTPROVIDERERROR;

  constructor(public payload: ProviderError) { }
}

export class GetCurrentProviderSuccessfulAction implements Action {
  type = ActionTypes.GETCURRENTPROVIDERSUCCESSFUL;

  constructor(public payload: ExternalInvoicingSystems[]) { }
}

export class SetExternalProviderStatusAction implements Action {
  type = ActionTypes.SETEXTERNALPROVIDERSTATUS;

  constructor(public payload: {provider: ExternalInvoicingSystems, status: SuccessStatus}) { }
}


export class ResetExternalProvidersAction implements Action {
  type = ActionTypes.RESETEXTERNALPROVIDERS;

  constructor(public payload?: any) { }
}

export class DisableProvidersAction implements Action {
  type = ActionTypes.DISABLEPROVIDERS;

  constructor(public payload?: any) { }
}

export type Actions = GoToSecondSlideAction | FtuxCompleteAction |
  GoToThirdSlideAction | GetRequestTokenErrorAction | GenerateUserTokenSuccessfulAction | GetExternalRequestTokenAction |
  StartUserAuthorizationAction | UserAuthorizationErrorAction | GenerateUserTokenErrorAction | ImportExternalClientsAction |
  UserAuthorizationSuccessAction | GenerateUserTokenAction | DisconnectProviderAction |
  DisconnectProviderSuccessfulAction | DisconnectProviderErrorAction | GetCurrentProviderSuccessfulAction |
  ExternalInvoiceProviderLoginAction | ExternalInvoiceProviderLoginSuccessAction |
  ExternalInvoiceProviderLoginFailureAction | ExternalInvoiceProviderLoginCompleteAction |
  ResetExternalProvidersAction | GenerateUserTokenCompleteAction | DisableProvidersAction |
  SetExternalProviderStatusAction;

export interface ProviderSuccess {
  provider: string;
  result: boolean;
}

export interface ProviderError extends ApiErrorResponse {
  provider: string;
}
