import { type } from '../../util';
import { Action } from '@ngrx/store';
import { InitiateClientApproval } from '../../models/approver';
import { TimeEntry } from 'app/models/timeentry';
import { ReportRequest } from 'app/models/ReportRequest';
import { HistoryRequest } from 'app/models/ReportHistory';
import { BulkTeamTimeUpload } from 'app/models/bulkteamtimeupload';
import { ApiErrorResponse } from 'app/models/apierror';

export const ActionTypes = {
  GENERATEEXCELFILEFROMDETAILVIEW: type('[Timesheet] Generate Excel file from the detailed view'),
  GENERATEEXCELFILEFROMDETAILVIEWSUCCESSFUL: type(
    '[Timesheet] Generating Excel file from the detailed view was successful'
  ),
  GENERATEEXCELFILEFROMDETAILVIEWFAILED: type('[Timesheet] Generating Excel file from the detailed view failed'),
  GENERATEPDFFILEFROMDETAILVIEW: type('[Timesheet] Generating PDf from detail view'),
  GENERATEPDFFILEFROMDETAILVIEWSUCCESSFUL: type('[Timesheet] Generating PDf from detail view was successful'),
  GENERATEPDFFILEFROMDETAILVIEWFAILED: type('[Timesheet] Generating PDf from detail view failed'),
  GENERATEOVERVIEWEXCEL: type('[Timesheet] Generate excel overview report'),
  GENERATEOVERVIEWEXCELFAILED: type('[Timesheet] Generating excel overview report failed'),
  GENERATEOVERVIEWEXCELSUCCESSFUL: type('[Timesheet] Generating excel overview report succeeded'),
  GENERATEOVERVIEWPDF: type('[Timesheet] Generate PDF from overview'),
  GENERATEOVERVIEWPDFSUCCESSFUL: type('[Timesheet] Generating PDF from overview was successful'),
  GENERATEOVERVIEWPDFFAILED: type('[Timesheet] Generating PDF from overview failed'),
  SAVETIMESHEETTOLOCALDBSUCCESS: type('[Timesheet] Saving timesheet to local db'),
  STARTAPPROVALPROCESS: type('[Timesheet] Start approval process'),
  STARTAPPROVALPROCESSSUCCESSFUL: type('[Timesheet] Starting approval process successful'),
  STARTAPPROVALPROCESSFAILED: type('[Timesheet] Start approval process failed'),

  OPENTIMESHEETHISTORYDIALOG: type('[Timesheet] Show timesheet history'),
  OPENTIMESHEETHISTORYDIALOGSUCCESS: type('[Timesheet] Show timesheet history success'),
  OPENTIMESHEETHISTORYDIALOGFAILURE: type('[Timesheet] Show timesheet history failure'),

  OPENBULKTIMEENTRYADDDIALOG: type('[Timesheet] Open Bulk Team Time entry add'),
  OPENBULKTIMEENTRYADDDIALOGSUCCESS: type('[Timesheet] Open Bulk Team Time entry Add dialog succeeded'),
  OPENBULKTIMEENTRYADDDIALOGFAILED: type('[Timesheet] Open Bulk Team Time entry Add dialog failed'),

  PARSEBULKENTRIES: type('[Timesheet] Parse Bulk Entries'),
  PARSEBULKENTRIESSUCCESSFUL: type('[Timesheet] Parsing bulk entries succeeeded'),
  PARSEBULKENTRIESFAILED: type('[Timesheet] Parsing bulk entries Failed'),

  SUBMITBULKENTRIESTOSERVER: type('[Timesheet] Submit bulk entries to server'),
  SUBMITBULKENTRIESTOSERVERSUCCESSFUL: type('[Timesheet] Submitting bulk entries to server succeeded'),
  SUBMITBULKENTRIESTOSERVERFAILED: type('[Timesheet] Submitting bulk entries to server failed'),

  FETCHTIMESHEETHISTORYFROMSERVER: type('[Timesheet] Fetch timesheet history from server'),
  FETCHTIMESHEETHISTORYFROMSERVERSUCCESS: type('[Timesheet] Fetch timesheet history from server success'),
  FETCHTIMESHEETHISTORYFROMSERVERFAILURE: type('[Timesheet] Fetch timesheet history from server failure'),

  SHOWDELETETIMEENTRIESDIALOG: type('[Timesheet] Show choice dialog for deleting time entries')

};

export class SubmitBulktEntriesToServerAction implements Action {
  type = ActionTypes.SUBMITBULKENTRIESTOSERVER;

  constructor(public payload: BulkTeamTimeUpload[]) { }
}

export class SubmitBulktEntriesToServerSuccessfulAction implements Action {
  type = ActionTypes.SUBMITBULKENTRIESTOSERVERSUCCESSFUL;

  constructor(public payload: string) { }
}

export class SubmitBulktEntriesToServerFailedAction implements Action {
  type = ActionTypes.SUBMITBULKENTRIESTOSERVERFAILED;

  constructor(public payload?: ApiErrorResponse) { }
}

export class ParseBulkTimeEntriesForUploadAction implements Action {
  type = ActionTypes.PARSEBULKENTRIES;
  constructor(public payload: File) { }
}

export class ParseBulkTimeEntriesForUploadSuccessfulAction implements Action {
  type = ActionTypes.PARSEBULKENTRIESSUCCESSFUL;
  constructor(public payload: string[][]) { }
}

export class ParseBulkTimeEntriesForUploadFailedAction implements Action {
  type = ActionTypes.PARSEBULKENTRIESFAILED;
  constructor(public payload?: ApiErrorResponse) { }
}

export class OpenBulkTimeEntryAddDialogAction implements Action {
  type = ActionTypes.OPENBULKTIMEENTRYADDDIALOG;
  constructor(public payload: File) { }
}

export class OpenBulkTimeEntryAddDialogSuccessAction implements Action {
  type = ActionTypes.OPENBULKTIMEENTRYADDDIALOGSUCCESS;
  constructor(public payload?: any) { }
}

export class OpenBulkTimeEntryAddDialogFailedAction implements Action {
  type = ActionTypes.OPENBULKTIMEENTRYADDDIALOGFAILED;
  constructor(public payload?: any) { }
}


export class StartApprovalProcessAction implements Action {
  type = ActionTypes.STARTAPPROVALPROCESS;
  constructor(public payload: InitiateClientApproval) { }
}

export class StartApprovalProcessFailedAction implements Action {
  type = ActionTypes.STARTAPPROVALPROCESSFAILED;
  constructor(public payload: any) { }
}

export class StartApprovalProcessSucceededAction implements Action {
  type = ActionTypes.STARTAPPROVALPROCESSSUCCESSFUL;
  constructor(public payload: any) { }
}

export class GenerateOverviewExcelFileAction implements Action {
  type = ActionTypes.GENERATEOVERVIEWEXCEL;

  constructor(public payload: ReportRequest) { }
}

export class GenerateOverviewExcelFileFailedAction implements Action {
  type = ActionTypes.GENERATEOVERVIEWEXCELFAILED;

  constructor(public payload?: any) { }
}

export class GenerateOverviewExcelFileSuccessfulAction implements Action {
  type = ActionTypes.GENERATEOVERVIEWEXCELSUCCESSFUL;

  constructor(public payload?: any) { }
}

export class GenerateOverviewPdfFileAction implements Action {
  type = ActionTypes.GENERATEOVERVIEWPDF;

  constructor(public payload: ReportRequest) { }
}

export class GenerateOverviewPdfFileFailedAction implements Action {
  type = ActionTypes.GENERATEOVERVIEWPDFFAILED;

  constructor(public payload?: any) { }
}

export class GenerateOverviewPdfFileSuccessfulAction implements Action {
  type = ActionTypes.GENERATEOVERVIEWPDFSUCCESSFUL;

  constructor(public payload?: any) { }
}

export class GenerateDetailedExcelFileAction implements Action {
  type = ActionTypes.GENERATEEXCELFILEFROMDETAILVIEW;

  constructor(public payload: ReportRequest) { }
}


export class GenerateDetailedExcelFileSuccessAction implements Action {
  type = ActionTypes.GENERATEEXCELFILEFROMDETAILVIEWSUCCESSFUL;

  constructor(public payload?: any) { }
}

export class GenerateDetailedExcelFileFailedAction implements Action {
  type = ActionTypes.GENERATEEXCELFILEFROMDETAILVIEWFAILED;

  constructor(public payload?: any) { }
}

export class GenerateDetailedPdfFileAction implements Action {
  type = ActionTypes.GENERATEPDFFILEFROMDETAILVIEW;

  constructor(public payload: ReportRequest) { }
}


export class GenerateDetailedPdfFileSuccessAction implements Action {
  type = ActionTypes.GENERATEPDFFILEFROMDETAILVIEWSUCCESSFUL;

  constructor(public payload?: any) { }
}

export class GenerateDetailedPdfFileFailedAction implements Action {
  type = ActionTypes.GENERATEPDFFILEFROMDETAILVIEWFAILED;

  constructor(public payload?: any) { }
}

export class OpenTimesheetHistoryDialogAction implements Action {
  type = ActionTypes.OPENTIMESHEETHISTORYDIALOG;

  constructor(public payload: any) { }
}

export class OpenTimesheetHistoryDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENTIMESHEETHISTORYDIALOGSUCCESS;
  constructor(public payload: any) { }
}

export class OpenTimesheetHistoryDialogFailureAction implements Action {
  type = ActionTypes.OPENTIMESHEETHISTORYDIALOGFAILURE;
  constructor(public payload: any) { }
}

export class FetchTimesheetHistoryFromServerAction implements Action {
  type = ActionTypes.FETCHTIMESHEETHISTORYFROMSERVER;
  constructor(public payload: HistoryRequest) { }
}

export class FetchTimesheetHistoryFromServerSuccessAction implements Action {
  type = ActionTypes.FETCHTIMESHEETHISTORYFROMSERVERSUCCESS;
  constructor(public payload: any) { }
}

export class FetchTimesheetHistoryFromServerFailureAction implements Action {
  type = ActionTypes.FETCHTIMESHEETHISTORYFROMSERVERFAILURE;
  constructor(public payload: any) { }
}

export class ShowDeleteTimeEntriesDialogAction implements Action {
  type = ActionTypes.SHOWDELETETIMEENTRIESDIALOG;
  constructor(public payload: TimeEntry[]) { }
}



export type Actions =
  StartApprovalProcessAction |
  StartApprovalProcessSucceededAction |
  StartApprovalProcessFailedAction
  | GenerateOverviewExcelFileAction
  | GenerateOverviewExcelFileFailedAction
  | GenerateOverviewExcelFileSuccessfulAction
  | GenerateOverviewPdfFileAction
  | GenerateOverviewPdfFileFailedAction
  | GenerateOverviewPdfFileSuccessfulAction
  | GenerateDetailedExcelFileAction
  | GenerateDetailedExcelFileFailedAction
  | GenerateDetailedExcelFileSuccessAction
  | GenerateDetailedPdfFileAction
  | GenerateDetailedPdfFileFailedAction
  | GenerateDetailedPdfFileSuccessAction
  | OpenTimesheetHistoryDialogAction
  | OpenTimesheetHistoryDialogSuccessfulAction
  | OpenTimesheetHistoryDialogFailureAction
  | FetchTimesheetHistoryFromServerAction
  | FetchTimesheetHistoryFromServerSuccessAction
  | OpenBulkTimeEntryAddDialogAction
  | SubmitBulktEntriesToServerAction
  | SubmitBulktEntriesToServerFailedAction
  | SubmitBulktEntriesToServerSuccessfulAction
  | OpenBulkTimeEntryAddDialogSuccessAction
  | OpenBulkTimeEntryAddDialogFailedAction
  | FetchTimesheetHistoryFromServerFailureAction
  | ParseBulkTimeEntriesForUploadAction
  | ParseBulkTimeEntriesForUploadFailedAction
  | ParseBulkTimeEntriesForUploadSuccessfulAction;
