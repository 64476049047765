import { Action, createAction, props } from '@ngrx/store';
import { type } from '../../util';
import { ClientDSO, CompanyCollection, CompanyDSO, CompanyInvoice, CompanyInvoiceHistoryItem, CompanyInvoiceHistoryPagedRequest, CompanyInvoicePagedRequest } from 'app/models/company-invoice';
import { InvoiceRequest } from 'app/models/invoicerequest';
import { Project } from 'app/models/project';
import { ManualInvoiceRequest, ManualInvoiceRequestPreview } from 'app/models/ReportRequest';
import { PagedResponse } from 'app/models/pagination';
import { ApiErrorResponse } from 'app/models/apierror';

export const ActionTypes = {
  GETINVOICELIST: type('[Invoice] get company invoices list'),
  GETINVOICELISTSUCCESS: type('[Invoice] get company invoices list success'),
  GETINVOICELISTFAILURE: type('[Invoice] get company invoice list failure'),
  RESETINVOICELIST: type('[Invoice] Reset invoice list'),
  MARKINVOICESASPAID: type('[Invoice] mark invoices as paid'),
  MARKINVOICESASPAIDSUCCESS: type('[Invoice] mark invoices as paid success'),
  MARKINVOICESASPAIDFAILURE: type('[Invoice] mark invoices as paid failure'),
  CREATEINVOICEACTION: type('[Invoice] Create Invoice'),
  CREATEINVOICESUCCESSFULACTION: type('[Invoice] Creating Invoice Successful'),
  CREATEINVOICEFAILEDACTION: type('[Invoice] Creating Invoice Failed'),
  CREATEFIXEDRATEINVOICEACTION: type('[Invoice] Create Fixed Rate Invoice'),
  CREATEFIXEDRATEINVOICESUCCESSFULACTION: type('[Invoice] Creating Fixed Rate Invoice Successful'),
  CREATEFIXEDRATEINVOICEFAILEDACTION: type('[Invoice] Creating Fixed Rate Invoice Failed'),
  CREATEMANUALINVOICEACTION: type('[Invoice] Create Manual Invoice'),
  CREATEMANUALINVOICEACTIONSUCCESSFULACTION: type('[Invoice] Creating Manual Invoice Successful'),
  CREATEMANUALINVOICEACTIONFAILEDACTION: type('[Invoice] Creating Manual Invoice Failed'),
  CREATEMANUALINVOICECOMPLETEACTION: type('[Invoice] Creating Manual Invoice Complete'),
  OPENCREATEFIXEDRATEINVOICEDIALOG: type('[Invoice] Open Generate Manual Dialog'),
  OPENCREATEFIXEDRATEINVOICEDIALOGFAILED: type('[Invoice] Opening Generate Fixed Rate Dialog failed'),
  OPENCREATEFIXEDRATEINVOICEDIALOGSUCCESS: type('[Invoice] Opening Generate Fixed Rate Dialog succeeded'),
  // RESENDINVOICEACTION: type('[Invoice] Resend invoice '),
  // RESENDINVOICEFAILEDACTION: type('[Invoice] Resending invoice failed'),
  // RESENDINVOICESUCCESSFULACTION: type('[Invoice] Resend invoice succeeded'),
  RESETINVOICELOADING: type('[Invoice] Resetting Loading status'),
  DOWNLOADINVOICES: type('[Invoice] Download Invoices'),
  DOWNLOADINVOICESSUCCESS: type('[Invoice] Download Invoice Success'),
  DOWNLOADINVOICESFAILURE: type('[Invoice] Download Invoice Failure'),
  OPENGENERATEMANUALINVOICEDIALOG: type('[Invoice] Opening Manual Invoice Dialog'),
  OPENGENERATEMANUALINVOICEDIALOGFAILED: type('[Invoice] Opening Manual Invoice Dialog failed'),
  OPENGENERATEMANUALINVOICEDIALOGSUCCEEDED: type('[Invoice] Opening Manual Invoice Dialog succeeded'),
  GENERATEINVOICEPREVIEW: type('[Invoice] Create invoice preview'),
  GENERATEINVOICEPREVIEWFAILED: type('[Invoice] Creating invoice preview failed'),
  GENERATEINVOICEPREVIEWSUCCEEDED: type('[Invoice] Creating invoice preview succeeded'),
  GENERATEINVOICEPREVIEWCOMPLETE: type('[Invoice] Creating Invoice Preview Complete'),
  OPENINVOICEINFORMATIONDIALOG: type('[Invoice] Open Invoice Information Dialog'),
  OPENINVOICEINFORMATIONDIALOGSUCCESS: type('[Invoice] Open Invoice Information Dialog Success'),
  OPENINVOICEINFORMATIONDIALOGFAILED: type('[Invoice] Open Invoice Information Dialog Failed'),
  GETINVOICEHISTORY: type('[Invoice] Get Invoice History'),
  GETINVOICEHISTORYSUCCESS: type('[Invoice] Get Invoice History Success'),
  GETINVOICEHISTORYFAILURE: type('[Invoice] Get Invoice History Failure'),
  ResetInvoiceHistory: type('[Invoice] Reset Invoice History'),
  REGENERATEINVOICEFROMSOURCE: type('[Invoice] Regenerate Invoice From Source'),
  REGENERATEINVOICEFROMSOURCESUCCESS: type('[Invoice] Regenerate Invoice From Source Success'),
  REGENERATEINVOICEFROMSOURCEFAILURE: type('[Invoice] Regenerate Invoice From Source Failure'),
  LOADCOMPANYDSO: type('[Invoice] Get CompanyDSO'),
  LOADCOMPANYDSOSUCCESS: type('[Invoice] Get CompanyDSO Success'),
  LOADCOMPANYDSOFAILURE: type('[Invoice] Get CompanyDSO Failure'),
  LOADCLIENTDSO: type('[Invoice] Get ClientDSO'),
  LOADCLIENTDSOSUCCESS: type('[Invoice] Get ClientDSO Success'),
  LOADCLIENTDSOFAILURE: type('[Invoice] Get ClientDSO Failure'),
  LOADCOMPANYCOLLECTION: type('[Invoice] Get Company Collection'),
  LOADCOMPANYCOLLECTIONSUCCESS: type('[Invoice] Get Company Collection Success'),
  LOADCOMPANYCOLLECTIONFAILURE: type('[Invoice] Get Company Collection Failure')
}

export const OpenGenerateFixedRateInvoiceDialog = createAction(ActionTypes.OPENCREATEFIXEDRATEINVOICEDIALOG, props<{ project: Project }>());
export const OpenGenerateFixedRateInvoiceDialogSucceeded = createAction(ActionTypes.OPENCREATEFIXEDRATEINVOICEDIALOGSUCCESS);
export const OpenGenerateFixedRateInvoiceDialogFailed = createAction(ActionTypes.OPENCREATEFIXEDRATEINVOICEDIALOGFAILED);

export const CreateFixedRateInvoiceAction = createAction(ActionTypes.CREATEFIXEDRATEINVOICEACTION, props<{ project: Project, companyId: string, invoiceTotal: number }>());
export const CreateFixedRateInvoiceFailedAction = createAction(ActionTypes.CREATEFIXEDRATEINVOICEFAILEDACTION, props<{ payload: ApiErrorResponse }>());
export const CreateFixedRateInvoiceSuccessfulAction = createAction(ActionTypes.CREATEFIXEDRATEINVOICESUCCESSFULACTION, props<{ nonce: Date }>());

export const ResetInvoiceLoadingAction = createAction(ActionTypes.RESETINVOICELOADING);

export const OpenGenerateManualInvoiceDialogAction = createAction(ActionTypes.OPENGENERATEMANUALINVOICEDIALOG);
export const OpenGenerateManualInvoiceDialogFailedAction = createAction(ActionTypes.OPENGENERATEMANUALINVOICEDIALOGFAILED, props<{ error: any }>());
export const OpenGenerateManualInvoiceDialogSucceededAction = createAction(ActionTypes.OPENGENERATEMANUALINVOICEDIALOGSUCCEEDED);

export const CreateManualInvoiceAction = createAction(ActionTypes.CREATEMANUALINVOICEACTION, props<{
  invoiceData: ManualInvoiceRequest,
  companyId: string
}>());
export const CreateManualInvoiceFailedAction = createAction(ActionTypes.CREATEMANUALINVOICEACTIONFAILEDACTION, props<{ payload: ApiErrorResponse }>());
export const CreateManualInvoiceSuccessfulAction = createAction(ActionTypes.CREATEMANUALINVOICEACTIONSUCCESSFULACTION)
export const CreateManualInvoiceCompleteAction = createAction(ActionTypes.CREATEMANUALINVOICECOMPLETEACTION);
export const GenerateInvoicePreviewAction = createAction(ActionTypes.GENERATEINVOICEPREVIEW,
  props<{ invoiceData: ManualInvoiceRequestPreview, companyId: string }>());
export const GenerateInvoicePreviewFailedAction = createAction(ActionTypes.GENERATEINVOICEPREVIEWFAILED, props<{ payload: ApiErrorResponse }>());
export const GenerateInvoicePreviewSuccessfulAction = createAction(ActionTypes.GENERATEINVOICEPREVIEWSUCCEEDED);
export const GenerateInvoicePreviewCompleteAction = createAction(ActionTypes.GENERATEINVOICEPREVIEWCOMPLETE);

export const DownloadInvoicesAction = createAction(ActionTypes.DOWNLOADINVOICES, props<{ invoices: CompanyInvoice[] }>());
export const DownloadInvoiceSuccessAction = createAction(ActionTypes.DOWNLOADINVOICESSUCCESS);
export const DownloadInvoiceFailureAction = createAction(ActionTypes.DOWNLOADINVOICESFAILURE);

export const OpenInvoiceInformationDialogAction = createAction(ActionTypes.OPENINVOICEINFORMATIONDIALOG, props<{ invoice: CompanyInvoice }>());
export const OpenInvoiceInformationDialogSuccessAction = createAction(ActionTypes.OPENINVOICEINFORMATIONDIALOGSUCCESS);
export const OpenInvoiceInformationDialogFailedAction = createAction(ActionTypes.OPENINVOICEINFORMATIONDIALOGFAILED);

export const MarkInvoicesAsPaidAction = createAction(ActionTypes.MARKINVOICESASPAID, props<{ invoiceIds: string[], filterOptions: CompanyInvoicePagedRequest }>());
export const MarkInvoicesAsPaidSuccessAction = createAction(ActionTypes.MARKINVOICESASPAIDSUCCESS, props<{ invoiceIds: string[] }>());
export const MarkInvoicesAsPaidFailureAction = createAction(ActionTypes.MARKINVOICESASPAIDFAILURE, props<{payload: ApiErrorResponse}>());

export const GetInvoiceHistoryAction = createAction(ActionTypes.GETINVOICEHISTORY, props<{payload: CompanyInvoiceHistoryPagedRequest}>());
export const GetInvoiceHistorySuccessAction = createAction(ActionTypes.GETINVOICEHISTORYSUCCESS, props<{ history: PagedResponse<CompanyInvoiceHistoryItem> }>());
export const GetInvoiceHistoryFailureAction = createAction(ActionTypes.GETINVOICEHISTORYFAILURE, props<{ payload: ApiErrorResponse }>());
export const ResetInvoiceHistoryAction = createAction(ActionTypes.ResetInvoiceHistory);

export const RegenerateInvoiceFromSourceAction = createAction(ActionTypes.REGENERATEINVOICEFROMSOURCE, props<{ invoice: CompanyInvoice, sendInvoiceEmail: boolean }>());
export const RegenerateInvoiceFromSourceSuccessAction = createAction(ActionTypes.REGENERATEINVOICEFROMSOURCESUCCESS);
export const RegenerateInvoiceFromSourceFailureAction = createAction(ActionTypes.REGENERATEINVOICEFROMSOURCEFAILURE, props<{ payload: ApiErrorResponse }>());

export const LoadCompanyDSOAction = createAction(ActionTypes.LOADCOMPANYDSO, props<{ startDate: Date, endDate: Date}>());
export const LoadCompanyDSOSuccessAction = createAction(ActionTypes.LOADCOMPANYDSOSUCCESS, props<{ companyDSO: CompanyDSO}>());
export const LoadCompanyDSOFailureAction = createAction(ActionTypes.LOADCOMPANYDSOFAILURE, props<{ error: ApiErrorResponse}>());

export const LoadClientDSOAction = createAction(ActionTypes.LOADCLIENTDSO, props<{ startDate: Date, endDate: Date}>());
export const LoadClientDSOSuccessAction = createAction(ActionTypes.LOADCLIENTDSOSUCCESS, props<{ clientDSO: Array<ClientDSO>}>());
export const LoadClientDSOFailureAction = createAction(ActionTypes.LOADCLIENTDSOFAILURE, props<{ error: ApiErrorResponse}>());

export const LoadCompanyCollectionAction = createAction(ActionTypes.LOADCOMPANYCOLLECTION, props<{ startDate: Date, endDate: Date }>());
export const LoadCompanyCollectionSuccessAction = createAction(ActionTypes.LOADCOMPANYCOLLECTIONSUCCESS, props<{ companyCollection: CompanyCollection }>());
export const LoadCompanyCollectionFailureAction = createAction(ActionTypes.LOADCOMPANYCOLLECTIONFAILURE, props<{ error: ApiErrorResponse }>());

export const ResetInvoiceListAction = createAction(ActionTypes.RESETINVOICELIST);

export class CreateInvoiceAction implements Action {
  type = ActionTypes.CREATEINVOICEACTION;

  constructor(public payload: InvoiceRequest) { }
}

export class CreateInvoiceSuccessfulAction implements Action {
  type = ActionTypes.CREATEINVOICESUCCESSFULACTION;

  constructor(public payload?: any) { }
}

export class CreateInvoiceFailedAction implements Action {
  type = ActionTypes.CREATEINVOICEFAILEDACTION;

  constructor(public payload?: ApiErrorResponse) { }
}

export class GetInvoiceListAction implements Action {
  type = ActionTypes.GETINVOICELIST;

  constructor(public payload?: CompanyInvoicePagedRequest) { }
}

export class GetInvoiceListSuccessAction implements Action {
  type = ActionTypes.GETINVOICELISTSUCCESS;

  constructor(public payload: PagedResponse<CompanyInvoice>) { }
}

export class GetInvoiceListFailureAction implements Action {
  type = ActionTypes.GETINVOICELISTFAILURE;

  constructor(public payload?: ApiErrorResponse) { }
}

export type Actions =
  GetInvoiceListAction |
  GetInvoiceListSuccessAction |
  typeof CreateFixedRateInvoiceSuccessfulAction |
  typeof CreateFixedRateInvoiceAction |
  typeof CreateFixedRateInvoiceFailedAction |
  typeof ResetInvoiceLoadingAction |
  typeof CreateManualInvoiceAction |
  typeof CreateManualInvoiceFailedAction |
  typeof CreateManualInvoiceSuccessfulAction |
  typeof OpenGenerateManualInvoiceDialogAction |
  typeof OpenGenerateManualInvoiceDialogSucceededAction |
  typeof OpenGenerateManualInvoiceDialogFailedAction |
  typeof GenerateInvoicePreviewAction |
  typeof GenerateInvoicePreviewFailedAction |
  typeof GenerateInvoicePreviewSuccessfulAction |
  typeof GenerateInvoicePreviewCompleteAction |
  typeof OpenInvoiceInformationDialogAction |
  typeof OpenInvoiceInformationDialogSuccessAction |
  typeof OpenInvoiceInformationDialogFailedAction |
  typeof MarkInvoicesAsPaidAction |
  typeof MarkInvoicesAsPaidSuccessAction |
  typeof MarkInvoicesAsPaidFailureAction |
  typeof DownloadInvoicesAction |
  typeof DownloadInvoiceSuccessAction |
  typeof DownloadInvoiceFailureAction |
  typeof GetInvoiceHistoryAction |
  typeof GetInvoiceHistorySuccessAction |
  typeof GetInvoiceHistoryFailureAction |
  typeof ResetInvoiceHistoryAction |
  typeof RegenerateInvoiceFromSourceAction |
  typeof RegenerateInvoiceFromSourceSuccessAction |
  typeof RegenerateInvoiceFromSourceFailureAction |
  typeof LoadCompanyDSOAction |
  typeof LoadCompanyDSOSuccessAction |
  typeof LoadCompanyDSOFailureAction |
  typeof LoadClientDSOAction |
  typeof LoadClientDSOSuccessAction |
  typeof LoadClientDSOFailureAction |
  typeof LoadCompanyCollectionAction |
  typeof LoadCompanyCollectionSuccessAction |
  typeof LoadCompanyCollectionFailureAction |
  typeof ResetInvoiceListAction |
  GetInvoiceListFailureAction |
  CreateInvoiceAction |
  CreateInvoiceFailedAction |
  CreateInvoiceSuccessfulAction;

