import { ClientStatus, CustomProcessFrequencyType, DayOfWeek, TimeOfDay, TimeRounding, InvoiceFrequency, InvoiceDueInterval, TimeUnit } from '../shared/enums';
import { Approver } from './approver';
import { Project } from './project';
import { UserViewModel } from './UserViewModel';
import { UUID } from 'angular2-uuid';
import { TaxType } from './user-settings';
import { UserCompanyRole } from '@cartwheel/web-components';
import { ApiErrorResponse } from './apierror';
import { CustomFieldEntryModel, UpsertCustomFieldEntryModel } from './custom-fields';

export class Client {
  address1: string;
  address2: string;
  customInvoiceFrequency: CustomInvoiceFrequency;
  approvers: Array<Approver> = [];
  approvalReminderInterval: number;
  approvalReminderIntervalUnit: TimeUnit;
  assignedUsers?: UserViewModel[];
  billedRate: number;
  clientID?: string;
  clientName: string;
  clientSettings: ClientSettings;
  createdByCompanyId?: string;
  creationDateTime: Date;
  externalId?: string;
  externalProvider?: string;
  invoiceApprovers: Array<Approver> = [];
  invoiceDueInterval: InvoiceDueInterval;
  invoiceEmails?: string[] = [];
  invoiceFrequency: InvoiceFrequency;
  invoicePrefix: string;
  invoiceTerms: string;
  maxReminders: number;
  monthEarnings?: number;
  overtimeThreshold?: number;
  primaryContact: Contact;
  projects: Project[];
  reportDay: DayOfWeek;
  sendInvoiceOnDueDate: boolean;
  sendInvoiceReminders: boolean;
  status?: ClientStatus;
  timeOfDay: TimeOfDay;
  timeRounding: TimeRounding;
  userId?: string;
  userInvoiceEmails?: string[] = [];
  taxRate?: TaxType;
  timeZone?: string;

  constructor(data?: any) {
    if (data) {
      if (data.address1) {
        this.address1 = data.address1
      }

      if (data.address2) {
        this.address2 = data.address2;
      }

      if (data.clientID) {
        this.clientID = data.clientID;
      }
      if (data.clientName) {
        this.clientName = data.clientName;
      }
      if (data.primaryContact) {
        this.primaryContact = data.primaryContact;
      }
      if (data.billedRate >= 0) {
        this.billedRate = +data.billedRate;
      }
      if (data.overtimeThreshold) {
        this.overtimeThreshold = data.overtimeThreshold;
      }
      if (data.reportDay !== null && data.reportDay !== undefined) {
        this.reportDay = DayOfWeek[DayOfWeek[data.reportDay]];
      }
      if (data.timeOfDay !== null && data.timeOfDay !== undefined) {
        this.timeOfDay = TimeOfDay[TimeOfDay[data.timeOfDay]];
      }
      if (data.timeRounding !== null && data.timeRounding !== undefined) {
        this.timeRounding = TimeRounding[TimeRounding[data.timeRounding]];
      }
      if (data.invoiceDueInterval !== null && data.invoiceDueInterval !== undefined) {
        this.invoiceDueInterval = data.invoiceDueInterval;
      }

      if (data.projects) {
        this.projects = data.projects;
      }
      if (data.externalId) {
        this.externalId = data.externalId;
      }
      if (data.externalProvider) {
        this.externalProvider = data.externalProvider;
      }
      if (data.status !== null && data.status !== undefined) {
        this.status = data.status;
      }

      if (data.approvers) {
        this.approvers = data.approvers;
      }

      if (data.invoiceEmails) {
        this.invoiceEmails = data.invoiceEmails;
      }

      if (data.userInvoiceEmails) {
        this.userInvoiceEmails = data.userInvoiceEmails;
      }

      if (data.creationDateTime) {
        this.creationDateTime = data.creationDateTime;
      }

      if (data.createdByCompanyId) {
        this.createdByCompanyId = data.createdByCompanyId;
      }
      // This is an enum, and can possibly be 0 so we must explicitly check if it's undefined
      if (data.invoiceFrequency !== null && data.invoiceFrequency !== undefined) {
        this.invoiceFrequency = data.invoiceFrequency;
      }
      if (data.userId) {
        this.userId = data.userId;
      }
      if (data.assignedUsers) {
        this.assignedUsers = data.assignedUsers;
      }

      if (data.invoiceTerms) {
        this.invoiceTerms = data.invoiceTerms;
      }
      if (data.taxRate !== undefined) {
        this.taxRate = data.taxRate;
      }
      if (data.invoicePrefix) {
        this.invoicePrefix = data.invoicePrefix;
      }
      if (data.customInvoiceFrequency) {
        this.customInvoiceFrequency = data.customInvoiceFrequency;
      }
      this.clientSettings = data?.clientSettings ?? null;
      this.maxReminders = data.maxReminders !== undefined ? data.maxReminders : null;
      this.sendInvoiceReminders = !!data.sendInvoiceReminders;
      this.sendInvoiceOnDueDate = !!data.sendInvoiceOnDuedate;
      this.timeZone = data.timeZone;
    }
  }
}


export interface ClientWithRole {
  role: UserCompanyRole;
  customFields?: { entryToAdd: UpsertCustomFieldEntryModel, originalEntry: CustomFieldEntryModel }[];
  client: Client;
}
export interface CustomInvoiceFrequency {
  interval: number;
  frequencyType: TimeUnit;
  invoiceStart: Date;
}
export class HarvestClient extends Client {
  projects: Project[];
}

export class TogglClient extends Client {
  projects: Project[];
}

export class ClientError {
  error: ApiErrorResponse;
  client: Client;

  constructor(data?: any) {
    if (data) {
      this.error = data.error;
      this.client = data.client;
    }
  }
}

export class Contact {
  firstName: string;
  lastName: string;
  email: string;
  companyId: UUID;
  id?: string | UUID;

  constructor(data?: any) {
    if (data) {
      if (data.firstName) {
        this.firstName = data.firstName;
      }
      if (data.lastName) {
        this.lastName = data.lastName;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.companyId) {
        this.companyId = data.companyId;
      }
      if (data.id) {
        this.id = data.id;
      }
    }
  }
}

export class ClientExpensesRequest {
  clientids?: Array<string | UUID>;
  startDate: Date;
  endDate: Date;
}

export class ClientExpense {
  userId: string;
  expenseAmount: number;
  startDate: Date;
  endDate: Date;
  clientId: string;

  constructor(data?: any) {
    if (data?.userId) {
      this.userId = data.userId;
    }
    if (data?.expenseAmount) {
      this.expenseAmount = data.expenseAmount;
    }
    if (data?.startDate) {
      this.startDate = data.startDate;
    }
    if (data?.endDate) {
      this.endDate = data.endDate;
    }
    if (data?.clientId) {
      this.clientId = data.clientId;
    }
  }
}

export class ClientSettings {
  dataLoadSettings?: DataLoadSettings;
  public timesheetApprovalSettings?: TimesheetApprovalSettings;
  timesheetReminderSettings?: TimesheetReminderSettings;

  constructor(data?: ClientSettings) {
    if (data?.dataLoadSettings) {
      this.dataLoadSettings = data.dataLoadSettings;
    }
    if (data?.timesheetApprovalSettings) {
      this.timesheetApprovalSettings = data.timesheetApprovalSettings;
    }
    if (data?.timesheetReminderSettings) {
      this.timesheetReminderSettings = data.timesheetReminderSettings;
    }
  }
}

export class DataLoadSettings {
  processDayOfWeek: DayOfWeek;
  processFrequency: CustomProcessFrequency;
  lookbackFrequency: CustomProcessFrequency;
  lastDataLoadDate: Date;

  constructor(data: any) {
    if (data?.processDayOfWeek !== undefined && data?.processDayOfWeek !== null) {
      this.processDayOfWeek = data.processDayOfWeek;
    }
    if (data?.processFrequency) {
      this.processFrequency = data.processFrequency;
    }
    if (data?.lookbackFrequency) {
      this.lookbackFrequency = data.lookbackFrequency;
    }
    if (data?.lastDataLoadDate && data?.lastDataLoadDate !== "0001-01-01T00:00:00+00:00") {
      this.lastDataLoadDate = data.lastDataLoadDate;
    }
  }
}

export class CustomProcessFrequency {
  interval: number;
  frequencyType: TimeUnit;
  processStart: Date;

  constructor(data: any) {
    if (data?.interval !== undefined && data?.interval !== null) {
      this.interval = data.interval;
    }
    if (data?.frequencyType !== undefined && data?.frequencyType !== null) {
      this.frequencyType = data.frequencyType;
    }
    if (data?.processStart) {
      this.processStart = data.processStart;
    }
  }
}

export class TimesheetApprovalSettings {
  sendReminders: boolean;

  constructor(data: any) {
    if (data?.sendReminders !== undefined && data?.sendReminders !== null) {
      this.sendReminders = data.sendReminders;
    }
  }
}

export class TimesheetReminderSettings {
  reminderFrequencyType: CustomProcessFrequencyType;
  interval: number;

  constructor(data: any) {
    if (data?.reminderFrequencyType !== undefined && data?.reminderFrequencyType !== null) {
      this.reminderFrequencyType = data.reminderFrequencyType;
    }
    if (data?.interval !== undefined && data?.interval !== null) {
      this.interval = data.interval;
    }
  }
}
