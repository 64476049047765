import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Actions, ActionTypes } from '../actions/timesheet';
import { ReportHistoryItem } from '../../models/ReportHistory';
import { SuccessStatus } from 'app/shared/enums';
import { BulkTeamTimeUpload } from 'app/models/bulkteamtimeupload';

// dayjs plugins
dayjs.extend(advancedFormat);

export interface State {
  reportHistory?: ReportHistoryItem[];
  loading: SuccessStatus;
  error?: any;
  parsedCsvValues?: string[][];
  bulkEntries?: BulkTeamTimeUpload[];
}

export const initialState: State = {
  loading: SuccessStatus.IsLoading,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case ActionTypes.FETCHTIMESHEETHISTORYFROMSERVERSUCCESS:
      const results = action.payload.results;
      let auditLogList = results.map(function (r) {
        const payloadObject = Object.assign({}, r.auditLog);
        if (r.approval) {
          const approvalStatus = r.approval.status;
          if (approvalStatus !== null && approvalStatus !== undefined) {
            if (approvalStatus === 0) {
              payloadObject.change = 'Approval';
            }
            if (approvalStatus === 1) {
              payloadObject.change = 'Rejection';
            }
          }
          payloadObject.timestamp = r.approval.approvalDateTime;
          // payloadObject.notes = r.approval.notes;
          payloadObject.approvalid = r.approval.approvalId;
        }
        return payloadObject;
      });

      auditLogList = auditLogList.filter(function (item, itemIdx) {
        if (item.approvalid) {
          const currIdx = auditLogList.findIndex(function (currItem, cIDx) {
            if (currItem.approvalid === item.approvalid && currItem.notes === item.notes) {
              return currItem;
            }
          });
          if (currIdx === itemIdx) {
            return item;
          }
        } else {
          return item;
        }
      }).sort(function (a, b) {
        if (new Date(b.timestamp) < new Date(a.timestamp)) {
          return 1;
        } else {
          return -1;
        }
      });

      let hist = new Array<ReportHistoryItem>();
      const reportHistoryData = auditLogList;
      if (Array.isArray(reportHistoryData)) {
        hist = reportHistoryData.map(historyItem => {
          historyItem.type = historyItem.change;
          historyItem.timestamp = dayjs(historyItem.timestamp).format('MMMM Do YYYY, h:mm:ss a');
          return new ReportHistoryItem(historyItem);
        });
      } else {
        hist.push(reportHistoryData);
      }
      return {
        ...state,
        reportHistory: hist,

      };
    case ActionTypes.OPENBULKTIMEENTRYADDDIALOG: {
      return {
        loading: SuccessStatus.IsLoading
      }
    }

    case ActionTypes.PARSEBULKENTRIESSUCCESSFUL: {
      return {
        loading: SuccessStatus.Enabled,
        parsedCsvValues: action.payload
      }
    }
    case ActionTypes.PARSEBULKENTRIESFAILED: {
      return {
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
        parsedCsvValues: null
      }
    }

    case ActionTypes.SUBMITBULKENTRIESTOSERVER: {
      return {
        loading: SuccessStatus.IsLoading,
        bulkEntries: action.payload
      }
    }

    case ActionTypes.SUBMITBULKENTRIESTOSERVERSUCCESSFUL: {
      return {
        loading: SuccessStatus.Success,
        bulkEntries: null
      }
    }

    case ActionTypes.SUBMITBULKENTRIESTOSERVERFAILED: {
      return {
        loading: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
        bulkEntries: null
      }
    }

    default:
      return state;
  }
}

export const getReportHistory = (state: State) => state.reportHistory;
export const getReportLoading = (state: State) => state.loading;
export const getReportCsv = (state: State) => state.parsedCsvValues;
