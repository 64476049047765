import { HistoryItemType, InvoiceStatus, InvoiceType } from 'app/shared/enums';
import { ManualInvoiceLine } from './ReportRequest';
import { UUID } from 'angular2-uuid';

export class CompanyInvoice {
  invoiceId?: string;
  companyId?: string;
  clientId?: string;
  clientName?: string;
  externalId?: string;
  sagaId?: string;
  userId?: string;
  amount?: number;
  formattedAmount?: string;
  formattedInvoiceNumber: string
  hours?: number;
  createdAt?: Date;
  updatedAt?: Date;
  destination?: string;
  status?: InvoiceStatus;
  formattedStatus?: string;
  provider?: string;
  dueAt?: Date;
  invoiceUsername: string;
  invoiceStartDate?: Date;
  invoiceEndDate?: Date;
  projectId?: string;
  lineItems: ManualInvoiceLine[];
  invoicePrefix: string;
  subtotal: number;
  externalFriendlyID: number;

  constructor(data?: any) {
    if (data) {
      if (data.invoiceId) {
        this.invoiceId = data.invoiceId;
      }
      if (data.companyId) {
        this.companyId = data.companyId;
      }
      if (data.clientId) {
        this.clientId = data.clientId;
      }
      if (data.clientName) {
        this.clientName = data.clientName;
      }
      if (data.externalId) {
        this.externalId = data.externalId;
      }
      if (data.sagaId) {
        this.sagaId = data.sagaId;
      }
      if (data.userId) {
        this.userId = data.userId;
      }
      if (data.amount !== null && data.amount !== undefined) {
        this.amount = data.amount;
      }
      if (data.hours) {
        this.hours = data.hours;
      }
      if (data.projectId) {
        this.projectId = data.projectId;
      }
      if (data.invoiceStartDate) {
        this.invoiceStartDate = data.invoiceStartDate;
      }
      if (data.invoiceEndDate) {
        this.invoiceEndDate = data.invoiceEndDate;
      }
      if (data.createdAt) {
        this.createdAt = data.createdAt;
      }
      if (data.updatedAt) {
        this.updatedAt = data.updatedAt;
      }
      if (data.destination) {
        this.destination = data.destination;
      }
      if (data.status !== null && data.status !== undefined) {
        this.status = data.status;
      }
      if (data.provider) {
        this.provider = data.provider;
      }
      if (data.dueAt) {
        this.dueAt = data.dueAt;
      }
      if (data.lineItems) {
        this.lineItems = data.lineItems;
      }
      if (data.invoicePrefix) {
        this.invoicePrefix = data.invoicePrefix;
      }
      if (data.subtotal) {
        this.subtotal = data.subtotal;
      }
      if (data.externalFriendlyID) {
        this.externalFriendlyID = data.externalFriendlyID;
      }
    }
  }
}


export class CompanyInvoiceFilterOptions {
  dueAt: { start: Date; end: Date; } = { start: null, end: null };
  createdAt: { start: Date; end: Date; } = { start: null, end: null };
  amount: { min: number; max: number; } = { min: null, max: null };
  status: InvoiceStatus = null;
  clientNames: string[] = [];
  keyword: string = '.';
  prefix: string = '';

  constructor(data?: any) {
    if (data?.dueAt) {
      this.dueAt = data.dueAt;
    }
    if (data?.createdAt) {
      this.createdAt = data.createdAt;
    }
    if (data?.amount) {
      this.amount = data.amount;
    }
    if (data?.status) {
      this.status = data.status;
    }
    if (data?.clientNames) {
      this.clientNames = data.clientNames;
    }
    if (data?.keyword) {
      this.keyword = data.keyword;
    }
    if (data?.prefix) {
      this.prefix = data.prefix;
    }
  }
}

export class CompanyInvoiceHistoryItem {
  historyDescription: string;
  historyDateTime: Date;
  historyItemType: HistoryItemType
}

export interface CompanyInvoiceHistoryPagedRequest {
  limit?: number;
  page?: number;
  companyId?: string | UUID;
  invoiceId: string;
  filterBy?: HistoryItemType;

}

export class CompanyInvoicePagedRequest {
  limit?: number = 8;
  page?: number = 1;
  companyId?: string | UUID;
  invoiceType?: InvoiceType;
  invoiceStatus?: InvoiceStatus;
  createdAtStartDate?: Date;
  createdAtEndDate?: Date;
  dueAtStartDate?: Date;
  dueAtEndDate?: Date;
  amountMin?: number;
  amountMax?: number;
  clientNames?: string[];
  prefix?: string;
  sortByColumn?: string;
  isSortDescending?: boolean;
  includePdfFiles?: boolean;
  keyword?: string;

  constructor(data?: any) {
    if (data?.limit !== null && data?.limit !== undefined) {
      this.limit = data.limit;
    }
    if (data?.page !== null && data?.page !== undefined) {
      this.page = data.page;
    }
    if (data?.companyId) {
      this.companyId = data.companyId;
    }
    if (data?.invoiceType !== undefined && data?.invoiceType !== null) {
      this.invoiceType = data.invoiceType;
    }
    if (data?.invoiceStatus !== undefined && data?.invoiceStatus !== null) {
      this.invoiceStatus = data.invoiceStatus;
    }
    if (data?.createdAtStartDate) {
      this.createdAtStartDate = data.createdAtStartDate;
    }
    if (data?.createdAtEndDate) {
      this.createdAtEndDate = data.createdAtEndDate;
    }
    if (data?.dueAtStartDate) {
      this.dueAtStartDate = data.dueAtStartDate;
    }
    if (data?.dueAtEndDate) {
      this.dueAtEndDate = data.dueAtEndDate;
    }
    if (data?.amountMin !== null && data?.amountMin !== undefined && data?.amountMin !== "") {
      this.amountMin = data.amountMin;
    }
    if (data?.amountMax !== null && data?.amountMax !== undefined && data?.amountMin !== "") {
      this.amountMax = data.amountMax;
    }
    if (data?.clientNames) {
      this.clientNames = data.clientNames;
    }
    if (data?.prefix !== null && data?.prefix !== undefined && data?.prefix !== '') {
      this.prefix = data.prefix;
    }
    if (data?.sortByColumn) {
      this.sortByColumn = data.sortByColumn;
    }
    if (data?.isSortDescending !== undefined && data?.isSortDescending !== null) {
      this.isSortDescending = data?.isSortDescending;
    }
    if (data?.includePdfFiles !== undefined && data?.includePdfFiles !== null) {
      this.includePdfFiles = data?.includePdfFiles;
    }
    if (data?.keyword !== null && data?.keyword !== undefined && data?.keyword !== '') {
      this.keyword = data.keyword;
    }
  }
}

export class CompanyDSO {
  dso?: number = 0;
  currentDso: number = 0;
  overdueInvoicePercentage: number = 0;
  averageDaysOutstanding: number = 0;
  startDate: Date = new Date();
  endDate: Date = new Date();
  companyId?: string = '';
  totalPaidInvoices?: number = 0;
  totalUnpaidInvoices?: number = 0;

  constructor(data?: any) {
    if (data?.dso !== undefined && data?.dso !== null) {
      this.dso = data.dso;
    }
    if (data?.currentDso !== undefined && data?.currentDso !== null) {
      this.currentDso = data.currentDso;
    }
    if (data?.overdueInvoicePercentage !== undefined && data?.overdueInvoicePercentage !== null) {
      this.overdueInvoicePercentage = data.overdueInvoicePercentage;
    }
    if (data?.averageDaysOutstanding !== undefined && data?.averageDaysOutstanding !== null) {
      this.averageDaysOutstanding = data.averageDaysOutstanding;
    }
    if(data?.startDate) {
      this.startDate = data.startDate;
    }
    if(data?.endDate) {
      this.endDate = data.endDate;
    }
    if (data?.companyId) {
      this.companyId = data.companyId;
    }
    if (data?.totalPaidInvoices !== undefined && data?.totalPaidInvoices !== null) {
      this.totalPaidInvoices = data.totalPaidInvoices;
    }
    if (data?.totalUnpaidInvoices !== undefined && data?.totalUnpaidInvoices !== null) {
      this.totalUnpaidInvoices = data.totalUnpaidInvoices;
    }
  }
}

export class ClientDSO {
  clientId?: string = '';
  clientName?: string = '';
  dso?: CompanyDSO;
  constructor(data?: any) {
    if (data?.clientId) {
      this.clientId = data.clientId;
    }
    if (data?.clientName !== undefined && data?.clientName !== null) {
      this.clientName = data.clientName;
    }
  }
}

export class CollectionData {
  dueDate: string;
  collectedOnTime: number = 0;
  uncollectedWithinTerms: number = 0;
  uncollectedPastTerms: number = 0;

  constructor(data?: any) {
    if (data?.dueDate) {
      this.dueDate = data.dueDate;
    }
    if (data?.collectedOnTime !== undefined && data?.collectedOnTime !== null) {
      this.collectedOnTime = data.collectedOnTime;
    }
    if (data?.uncollectedWithinTerms !== undefined && data?.uncollectedWithinTerms !== null) {
      this.uncollectedWithinTerms = data.uncollectedWithinTerms;
    }
    if (data?.uncollectedPastTerms !== undefined && data?.uncollectedPastTerms !== null) {
      this.uncollectedPastTerms = data.uncollectedPastTerms;
    }
  }
}

export class CompanyCollection {
  amountDue: number = 0;
  collectedOnTime: number = 0;
  collectedLate: number = 0;
  totalClients: number = 0;
  totalInvoiced: number = 0;
  totalInvoices: number = 0;
  uncollectedWithinTerms: number = 0;
  data: CollectionData[];

  constructor(data?: any) {
    if (data?.amountDue !== undefined && data?.amountDue !== null) {
      this.amountDue = data.amountDue;
    }
    if (data?.collectedOnTime !== undefined && data?.collectedOnTime !== null) {
      this.collectedOnTime = data.collectedOnTime;
    }
    if (data?.collectedLate !== undefined && data?.collectedLate !== null) {
      this.collectedLate = data.collectedLate;
    }
    if (data?.totalClients !== undefined && data?.totalClients !== null) {
      this.totalClients = data.totalClients;
    }
    if (data?.totalInvoiced !== undefined && data?.totalInvoiced !== null) {
      this.totalInvoiced = data.totalInvoiced;
    }
    if (data?.totalInvoices !== undefined && data?.totalInvoices !== null) {
      this.totalInvoices = data.totalInvoices;
    }
    if (data?.uncollectedWithinTerms !== undefined && data?.uncollectedWithinTerms !== null) {
      this.uncollectedWithinTerms = data.uncollectedWithinTerms;
    }
    if (data?.data?.length) {
      this.data = data.data;
    }
  }
}

export type BarChartDataType = {
  name: string;
  series: {
    name: string;
    value: number;
  }[]
}