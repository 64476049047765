import { ActionTypes, Actions } from '../actions/approver';
import { ApplicationTypes, SuccessStatus } from '../../shared/enums';
import { ApplicationAuthStatus, ApplicationLoadingStatus } from '../../models/approver';
import { Project } from '../../models/project';

export interface State {
  error?: string;
  loadingStatus: ApplicationLoadingStatus;
  projectLoadingStatus: SuccessStatus;
  authStatus: ApplicationAuthStatus
  complete: boolean;
  projects: Project[];
}

export const initialState: State = {
  loadingStatus: {
    [ApplicationTypes.Harvest]: false,
    [ApplicationTypes.Toggl]: false,
    [ApplicationTypes.ADP]: false
  },
  authStatus: {
    [ApplicationTypes.Harvest]: false,
    [ApplicationTypes.Toggl]: false,
    [ApplicationTypes.ADP]: false
  },
  projectLoadingStatus: null,
  projects: null,
  error: null,
  complete: null
};

export const disabledApplications = {
  [ApplicationTypes.Harvest]: SuccessStatus.Disabled,
  [ApplicationTypes.Toggl]: SuccessStatus.Disabled,
  [ApplicationTypes.ADP]: SuccessStatus.Disabled
}

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.HARVESTLOGIN: {
      return {
        ...state,
        error: null,
        loadingStatus: {
          ...disabledApplications,
          [ApplicationTypes.Harvest]: SuccessStatus.IsLoading
        }
      }
    }

    case ActionTypes.HARVESTLOGINSUCCESS: {
      return {
        ...state,
        error: null,
        authStatus: {
          ...state.authStatus,
          [ApplicationTypes.Harvest]: true
        }
      }
    }

    case ActionTypes.HARVESTLOGINFAILURE: {
      return {
        ...state,
        error: action.payload.message,
        loadingStatus: {
          ...initialState.loadingStatus,
          [ApplicationTypes.Harvest]: SuccessStatus.Error
        },
        complete: false
      }
    }

    case ActionTypes.GETAUTHSTATUSSUCCESS: {
      const authenticatedProviders = {};
      action.payload.forEach(provider => {
        authenticatedProviders[ApplicationTypes[provider]] = true
      });
      return {
        ...state,
        authStatus: {
          ...initialState.authStatus,
          ...authenticatedProviders
        }
      }
    }

    case ActionTypes.GETAUTHSTATUSFAILURE: {
      return {
        ...state,
        error: action.payload.message,
        authStatus: initialState.authStatus
      }
    }

    case ActionTypes.APPROVERAPPLICATIONLOGIN: {
      return {
        ...state,
        error: null,
        loadingStatus: {
          ...disabledApplications,
          [ApplicationTypes[action.payload.provider]]: SuccessStatus.IsLoading
        }
      }
    }

    case ActionTypes.APPROVERAPPLICATIONLOGINCOMPLETE: {
      return {
        ...state,
        complete: null,
        loadingStatus: initialState.loadingStatus
      }
    }

    case ActionTypes.APPROVERAPPLICATIONLOGINSUCCESS: {
      return {
        ...state,
        error: null,
        loadingStatus: initialState.loadingStatus,
        authStatus: {
          ...state.authStatus,
          [ApplicationTypes[action.payload]]: true
        },
        complete: true
      }
    }

    case ActionTypes.APPROVERAPPLICATIONLOGINFAILURE: {
      return {
        ...state,
        error: action.payload.error.message,
        loadingStatus: {
          ...initialState.loadingStatus,
          [ApplicationTypes[action.payload.provider]]: SuccessStatus.Error
        },
        complete: false
      }
    }

    case ActionTypes.LOADCLIENTSFROMPROVIDER: {
      return {
        ...state,
        loadingStatus: {
          ...disabledApplications,
          [action.payload]: SuccessStatus.IsLoading
        }
      }
    }

    case ActionTypes.LOADCLIENTSFROMPROVIDERSUCCESS: {
      return {
        ...state,
        loadingStatus: {
          ...disabledApplications,
          [action.payload.provider]: SuccessStatus.IsLoading
        }
      }
    }

    case ActionTypes.LOADCLIENTSFROMPROVIDERFAILURE: {
      return {
        ...state,
        loadingStatus: {
          ...initialState.loadingStatus,
          [action.payload.provider]: SuccessStatus.Error
        }
      }
    }

    case ActionTypes.APPROVERCHOICECOMPLETE: {
      return {
        ...state,
        complete: true,
        loadingStatus: initialState.loadingStatus,
        // authStatus: {
        //   ...state.authStatus,
        //   [action.payload]: true
        // }
      }
    }

    case ActionTypes.RESETAPPROVERPROCESS: {
      return {
        ...state,
        complete: null
      }
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getLoadingStatus = (state: State) => state.loadingStatus;
export const getAuthStatus = (state: State) => state.authStatus;
export const getComplete = (state: State) => state.complete;
export const getProjectLoading = (state: State) => state.projectLoadingStatus;
export const getProjects = (state: State) => state.projects;
