import { AlertType, ApplicationTypes, ExternalInvoicingSystems } from '../shared/enums';
import { UUID } from 'angular2-uuid';

export class Alert {
  name: string;
  type: AlertType;
  applicationType?: ApplicationTypes;
  id: number;
  clientId?: UUID;
  providerType?: ExternalInvoicingSystems;
  companyId?: UUID;

  constructor(data?: any) {
    if (data) {
      this.name = data.name;
      this.type = data.type;
      this.applicationType = data.applicationType;
      this.clientId = data.clientId;
      if (data.id) {
        this.id = data.id;
      }
      if (data.companyId) {
        this.companyId = data.companyId;
      }
      if (data.providerType !== null && data.providerType !== undefined) {
        this.providerType = data.providerType;
      }
    }
  }
}
