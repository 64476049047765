import { Action } from '@ngrx/store';
import { type } from '../../util';
import { Alert } from 'app/models/alerts';

export const ActionTypes = {
  ADDALERT: type('[Alert] Adding an alert'),
  REMOVEALERT: type('[Alert] Removing an alert'),
  REMOVEALLALERTS: type('[Alert] Remove all alerts'),
  SETALERTFLAG: type('[Alert] Set flag to represent an action taken from the alert component'),
};

export class AddAlertAction implements Action {
  type = ActionTypes.ADDALERT;

  constructor(public payload: Alert) { }
};

export class RemoveAlertAction implements Action {
  type = ActionTypes.REMOVEALERT;

  constructor(public payload: Alert) { }
};

export class RemoveAllAlertsAction implements Action {
  type = ActionTypes.REMOVEALLALERTS;

  constructor(public payload?: any) { }
}

export class SetAlertFlagAction implements Action {
  type = ActionTypes.SETALERTFLAG;
  constructor(public payload: boolean) { }
};

export type Actions =
  | AddAlertAction
  | RemoveAlertAction
  | RemoveAllAlertsAction
  | SetAlertFlagAction;
