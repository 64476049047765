import { SuccessStatus, Status, ApplicationTypes } from '../shared/enums';

export class Project {
  projectId?: string;
  projectName?: string;
  projectDescription?: string;
  clientId?: string;
  createdByCompanyID?: string;
  creationDateTime?: Date;
  projectEarnings?: number;
  projectBilledHours?: number;
  status?: Status;
  projectOptions?: string;
  projectBeingEdited?: false;
  projectStatus?: SuccessStatus;
  harvestTaskId?: string;
  harvestProjectId?: string;
  harvestAccountId?: string;
  togglProjectId?: string;
  adpProjectId?: string;
  appType?: ApplicationTypes;
  projectTotal: number;
  projectType: ProjectType;
  totalPaid: number;

  constructor(data?) {
    if (data) {
      if (data.projectId) {
        this.projectId = data.projectId;
      }
      if (data.projectName) {
        this.projectName = data.projectName;
      }
      if (data.projectDescription) {
        this.projectDescription = data.projectDescription;
      }
      if (data.clientId) {
        this.clientId = data.clientId;
      }
      if (data.projectType >= 0) {
        this.projectType = data.projectType;
      }

      if (data.projectTotal >= 0) {
        this.projectTotal = data.projectTotal;
      }

      if (data.totalPaid >= 0) {
        this.totalPaid = data.totalPaid;
      }

      if (data.createdByCompanyID) {
        this.createdByCompanyID = data.createdByCompanyID;
      }
      if (data.totalPaid !== 0) {
        this.totalPaid = data.totalPaid;
      }

      if (data.projectTotal !== 0) {
        this.projectTotal = data.projectTotal;
      }
      if (data.creationDateTime) {
        this.creationDateTime = data.creationDateTime;
      }
      if (data.status) {
        this.status = data.status;
      }
      if (data.projectOptions) {
        this.projectOptions = data.projectOptions;
      }
      if (data.projectBeingEdited) {
        this.projectBeingEdited = data.projectBeingEdited;
      }
      if (data.projectStatus) {
        this.projectStatus = data.projectStatus;
      }
      if (data.harvestAccountId) {
        this.harvestAccountId = data.harvestAccountId;
      }
      if (data.harvestProjectId) {
        this.harvestProjectId = data.harvestProjectId;
      }
      if (data.harvestTaskId) {
        this.harvestTaskId = data.harvestTaskId;
      }
      if (data.togglProjectId) {
        this.togglProjectId = data.togglProjectId;
      }
      if (data.adpProjectId) {
        this.adpProjectId = data.adpProjectId;
      }
      if (data.appType) {
        this.appType = data.appType;
      }
    }
  }
}

export enum ProjectType {
  fixedRate = 'fixedRate',
  hourly = 'hourly'
}
