export enum PwInputType {
  password,
  text
}

export enum ExternalInvoicingSystems {
  QuickBooks,
  FreshBooks,
  Xero,
  Harvest,
  Adp,
  Toggl,
  Veem,
  BillCom,
  Qwil,
  Gigwage,
  Cartwheel,
  Paper,
  Paidiem,
  SageIntacct
}

export enum ConnectionStatus {
  connect,
  disconnect,
  loading
}

export enum FormGroups {
  registrationForm,
  newPasswordForm,
  changePasswordForm
}

export enum TimeOfDay {
  SevenAM,
  EightAM,
  NineAM,
  TenAM,
  TwoPM,
  ThreePM,
  FourPM
}

export enum InvoiceFrequency {
  BiWeeklyOnTheFirstAndFifteenth,
  AtTheEndOfTheMonth,
  EveryFriday,
  Custom
}

export enum TimeUnit {
  Month,
  Week,
  Day
}

export enum BillingStatus {
  Unpaid,
  Paid
}

export enum UserDayReminder {
  Everyday,
  Weekdays
}

export enum UserWeekReminder {
  Yes,
  No
}

export enum ScreenSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}

//
// Summary:
//     Specifies the day of the week.
export enum DayOfWeek {
  //
  // Summary:
  //     Indicates Sunday.
  Sunday = 0,
  //
  // Summary:
  //     Indicates Monday.
  Monday = 1,
  //
  // Summary:
  //     Indicates Tuesday.
  Tuesday = 2,
  //
  // Summary:
  //     Indicates Wednesday.
  Wednesday = 3,
  //
  // Summary:
  //     Indicates Thursday.
  Thursday = 4,
  //
  // Summary:
  //     Indicates Friday.
  Friday = 5,
  //
  // Summary:
  //     Indicates Saturday.
  Saturday = 6
}

export enum TimeRounding {
  ToNearestMinute,
  ToNearestQuarterHour,
  ToNearestHalfHour,
  ToNearestHour,
  UpToNearestMinute,
  UpToNearestQuarterHour,
  UpToNearestHalfHour,
  UpToNearestHour,
  DownToNearestMinute,
  DownToNearestQuarterHour,
  DownToNearestHalfHour,
  DownToNearestHour
}

export enum TabSelection {
  All,
  Active,
  Archived
}

export enum GSettingsTabSelection {
  Profile,
  Global,
  Billing,
  Advanced,
  History
}

export enum SubTabSelection {
  Settings,
  Projects,
  Invoices,
  Approvers
}

export enum Status {
  Active, // Aren't archived or deleted.
  Inactive, // Are only archived
  Deleted //
}

export enum ClientStatus {
  Active,
  Archived,
  Deleted
}

export enum SuccessStatus {
  IsLoading,
  Success,
  Error,
  Disabled,
  Enabled,
  AccountInActive
}

export enum GlobalSettingsModelArrayPosition {
  firstName,
  lastName,
  emailAddress,
  defaultWeekEnd,
  defaultBillRate,
  timeZone
}
export enum NotificationStatus {
  Unread = 'Unread',
  Read = 'Read'
}

export enum NotificationType {
  ProviderDisconnect = 'ProviderDisconnect'
}

export enum ApplicationTypes {
  Harvest,
  Toggl,
  ADP
}

export enum ApprovalType {
  Application,
  User,
  Contact
}

export enum ApprovalNotificationType {
  Approval,
  Notify
}

export enum ReportType {
  Activty = 0,
  Overview = 1,
  Invoice = 2
}

export enum FileType {
  Excel = 0,
  PDF = 1
}

export enum AccountActive {
  inactive = 0,
  active = 1
}

export enum ApprovalStatus {
  Unsent,
  Pending,
  Approved,
  Denied,
  Invalid
}

export enum PasswordType {
  apiToken = 'apiToken',
  password = 'password'
}

export enum UserRole {
  SuperAdmin,
  Admin,
  Manager,
  User
}

export enum UserPosition {
  Employee,
  Contractor
}

export enum ReportHistoryType {
  insert = 'insert',
  update = 'update',
  reject = 'reject',
  approve = 'approve'
}

export enum ProjectImportAction {
  Import = 'Import',
  DontImport = 'Do Nothing'
}

export enum UserStatus {
  Unconfirmed,
  Confirmed
}

export enum TimesheetGroupings {
  Clients,
  Users
}

export enum AlertType {
  ApplicationConnection,
  MissingEmail
}

export enum BillingPlan {
  Monthly = 'Montly',
  Yearly = 'Yearly'
}

export enum InvoiceStatus {
  Unpaid,
  Paid,
  Pending
}


export enum TeamBulkImportColumns {
  ClientName,
  Project,
  Username,
  StartDate,
  Hours
}


export enum EventCategory {
  Init,
  TeamDashboard,
  UserDashboard,
  Clients,
  Reports,
  Users,
  Projects,
  Invoices,
  Settings,
  Timer
}

// <option>7 Days</option>
// <option>14 Days</option>
// <option>30 Days</option>
// <option>45 Days</option>
// <option>60 Days</option>
// <option>75 Days</option>
// <option>90 Days</option>
export enum InvoiceDueInterval {
  SevenDays = "1. 7 Days",
  FourteenDays = "2. 14 Days",
  ThirtyDays = "3. 30 Days",
  FourtyFiveDays = "4. 45 Days",
  SixtyDays = "5. 60 Days",
  SeventyFive = "6. 75 Days",
  Ninty = "7. 90 Days"
}

export enum HistoryItemType {
  InvoiceCreated,
  InvoiceEmailSent,
  InvoiceEmailDelivered,
  InvoiceEmailOpened,
  InvoiceLinkClicked,
  InvoicePaymentFailed,
  InvoicePaymentSucceeded,
}

export enum InvoiceType {
  Client,
  User,
  FixedRate,
  Manual,
  Recurring
}

export enum CustomProcessFrequencyType {
  Days = 0,
  Months = 1,
  Weeks = 2
}

export enum BillingPlans {
  Monthly,
  Yearly
}

export enum ActionType {
  Create,
  Update,
  Delete,
  Info
}
