import { createReducer, on } from '@ngrx/store';
import { SuccessStatus } from 'app/shared/enums';
import * as actions from '../actions/forgotpassword';

export interface State {
    loading: SuccessStatus;
    error?: string;
    email?: string;
}

export const initialState: State = {
    loading: null,
};

export const forgotPasswordReducer = createReducer(initialState,
    on(actions.ForgotPasswordAction, (state, action) => ({
        ...state,
        email: action.payload,
        loading: SuccessStatus.IsLoading,
        error: null,
    })),
    on(actions.PasswordConfirmationErrorAction, (state, action) => ({
        ...state,
        error: action.payload,
        loading: SuccessStatus.Error
    })),
    on(actions.PasswordConfirmationSuccessAction, (state) => ({
        ...state,
        error: null,
        loading: SuccessStatus.Success
    })),
    on(actions.ResetPasswordConfirmationAction, (state) => ({
        ...state,
        error: null,
        loading: null
    }))
);
export function reducer(state = initialState, action: actions.Actions): State {
    return forgotPasswordReducer(state, action);
}

export const getRecoveryResultError = (state: State) => state.error;
export const getRecoveryEmail = (state: State) => state.email;
export const getRecoveryLoading = (state: State) => state.loading;
