
import { Actions, ActionTypes } from '../actions/seed';
import { SuccessStatus } from 'app/shared/enums';

export interface State {
    loading: boolean | SuccessStatus;
}

export const initialState: State = {
    loading: false,

}

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.SEEDDATABASE: {
            return {
              ...state,
              loading: SuccessStatus.IsLoading
            }
        }

        case ActionTypes.SEEDDATABASESUCCESS: {
          return {
            ...state,
            loading: false
          }
        }

        default:
            return state;
  }
}

export const getLoading = (state: State) => { return state.loading; }

