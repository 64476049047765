import { ExternalInvoicingSystems, SuccessStatus } from '../shared/enums';

export class ExternalInvoice {
  name: string;
  imageSrc: string;

  constructor(data?) {
    if (data) {
      if (data.name) {
        this.name = data.name;
      }
      if (data.src) {
        this.imageSrc = data.src;
      }
    }
  }
}


export class ProviderState {
  [ExternalInvoicingSystems.QuickBooks]: Provider;
  [ExternalInvoicingSystems.Cartwheel]: Provider;
  // [ExternalInvoicingSystems.Veem]: Provider;
  // [ExternalInvoicingSystems.FreshBooks]: Provider;
  [ExternalInvoicingSystems.Xero]: Provider;
  // [ExternalInvoicingSystems.Qwil]: Provider;
  // [ExternalInvoicingSystems.BillCom]: Provider;
}

export class Provider {
  connectionStatus: SuccessStatus;
}

