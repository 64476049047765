import { Action } from '@ngrx/store';
import { Client } from '../../models/Client';
import { TimeEntry } from '../../models/timeentry';
import { type } from '../../util';
import { UUID } from 'angular2-uuid';
import { ApiErrorResponse } from 'app/models/apierror';

export const ActionTypes = {
  OPENTIMERDIALOG: type('[Timer] Open start Timer dialog'),
  OPENTIMERDIALOGSUCCESS: type('[Timer] Opening Timer dialog was successful'),
  OPENTIMERDIALOGFAILED: type('[Timer] Opening Timer dialog failed'),
  STARTTIMER: type('[Timer] Start Timer'),
  STARTTIMERSUCCESS: type('[Timer] Started Timer successfully'),
  REPORTSTARTTIMER: type('[Timer] Reporting that timer was started'),
  REPORTSTARTTIMERSUCCESSFUL: type('[Timer] Reporting that timer was started was successful'),
  REPORTSTARTTIMERFAILED: type('[Timer] Reporting that timer was started failed'),
  REPORENDTIMER: type('[Timer] Reporting that timer was ended'),
  REPORTENDTIMERSUCCESSFUL: type('[Timer] Reporting that timer was ended successfully'),
  REPORTENDTIMERFAILED: type('[Timer] Reporting that timer ending failed'),
  STARTTIMERFAILED: type('[Timer] Starting Timer failed'),
  ENDTIMER: type('[Timer] Ending Timer'),
  ENDTIMERSUCCESS: type('[Timer] Ending Timer was successful'),
  ENDTIMERNODISPTACHSUCCESS: type('[Timer] Ending Timer without dispatching was successful'),
  ENDTIMERFAILED: type('[Timer] Ending Timer failed'),
  SAVETIMEENTRYTOLOCALDB: type('[Timer] Save time entry to local DB'),
  SAVETIMEENTRYTOLOCALDBFAILED: type('[Timer] Saving time entry to local DB failed'),
  SAVETIMEENTRYTOOLOCALDBSUCCESSFUL: type('[Timer] Saving time entry to local DB succeeded'),
  LOADCOMPANYTIMEENTRIESFROMLOCALDB: type('[Timer] Load all time entries from a company for date range'),
  LOADCOMPANYTIMEENTRIESFROMLOCALDBFAILED: type('[Timer] Load all time entries from a company failed'),
  LOADCOMPANYTIMEENTRIESFROMLOCALDBSUCCESSFUL: type('[Timer] Load all time entries from a company success'),
  LOADCOMPANYTIMEENTRIESFROMSERVER: type('[Timer] Load company\'s time entries from server'),
  LOADCOMPANYTIMEENTRIESFROMSERVERSUCCESSFUL: type('[Timer] Loading company\'s time entries from server was successful'),
  LOADCOMPANYTIMEENTRIESFROMSERVERFAILED: type('[Timer] Loading company\'s time entries from server failed'),
  LOADTIMEENTRIESFROMLOCALDB: type('[Timer] Load time entries from local DB'),
  LOADTIMEENTRIESFROMLOCALDBSUCCESSFUL: type('[Timer] Load time entries from local DB was successful'),
  LOADTIMEENTRIESFROMLOCALDBFAILED: type('[Timer] Load time entries from local DB failed'),
  SAVETIMEENTRYTOSERVER: type('[Timer] Save time entry to the server'),
  SAVETIMEENTRYTOSERVERSUCCESSFUL: type('[Timer] Saving time entry to server was successful'),
  SAVETIMEENTRYTOSERVERFAILED: type('[Timer] Saving time entry to the server failed'),
  LOADTIMEENTRIESFROMSERVER: type('[Timer] Load time entries from server'),
  LOADTIMEENTRIESFROMSERVERSUCCESSFUL: type('[Timer] Loading time entries from server was successful'),
  LOADTIMEENTRIESFROMSERVERFAILED: type('[Timer] Loading time entries from server failed'),
  CHECKTIMERRUNNINGONSERVER: type('[Timer] Check if timer is running'),
  CHECKTIMERRUNNINGOONSERVERSUCCESSFUL: type('[Timer] Checking if timer was running on server successful'),
  CHECKTIMERRUNNINGONSERVERFAILED: type('[Timer] Checking if timer was running on server failed'),
  MERGETIMEENTRIES: type('[Timer] Merge time entries from server and local DB'),
  OPENADDTIMEDIALOG: type('[Timer] Open Add time dialog'),
  SUBMITTIMEENTRY: type('[Timer] Submit timeentry'),
  SUBMITTIMEENTRYSUCCESSFUL: type('[Timer] Submitting timeentry was successful'),
  SUBMITTIMEENTRYFAILED: type('[Timer] Submitting timeentry failed'),
  DELETETIMEENTRY: type('[Timer] Submit delete timeEntry'),
  DELETETIMEENTRYSUCCESSFUL: type('[Timer] Submitting delete timeEntry was successful'),
  DELETETIMEENTRYFAILED: type('[Timer] Submitting delete timeEntry failed'),
  EDITTIMEENTRIES: type('[Timer] Change timeEntries'),
  EDITTIMEENTRIESSUCCESSFUL: type('[Timer] Change timeEntries succeeded'),
  EDITTIMEENTRIESFAILED: type('[Timer] Change timeEntries failed'),
  TIMERSTOPPEDFROMSERVER: type('[Timer] Notification from server to stop timer receieved')
};

export class CheckIfTimerRunningAction implements Action {
  type = ActionTypes.CHECKTIMERRUNNINGONSERVER;

  constructor(public payload?: any) { }
}

export class CheckIfTimerRunningSuccessfulAction implements Action {
  type = ActionTypes.CHECKTIMERRUNNINGOONSERVERSUCCESSFUL;

  constructor(public payload: any) { }
}

export class CheckIfTimerRunningFailedAction implements Action {
  type = ActionTypes.CHECKTIMERRUNNINGONSERVERFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class OpenTimerDialogAction implements Action {
  type = ActionTypes.OPENTIMERDIALOG;

  constructor(public payload?: TimeEntry) { }
}

export class OpenTimerDialogSuccessfulAction implements Action {
  type = ActionTypes.OPENTIMERDIALOGSUCCESS;

  constructor(public payload: TimeEntry) { }
}

export class OpenTimerDialogFailedAction implements Action {
  type = ActionTypes.OPENTIMERDIALOGFAILED;

  constructor(public payload: any) { }
}

export class StartTimerAction implements Action {
  type = ActionTypes.STARTTIMER;

  constructor(public payload: [Partial<TimeEntry>, Client]) { }
}

export class StartTimerSuccessfulAction implements Action {
  type = ActionTypes.STARTTIMERSUCCESS;

  constructor(public payload: any) { }
}

export class ReportStartTimer implements Action {
  type = ActionTypes.REPORTSTARTTIMER;

  constructor(public payload: TimeEntry) { }
}

export class ReportStartTimerSuccessful implements Action {
  type = ActionTypes.REPORTSTARTTIMERSUCCESSFUL;

  constructor(public payload?: any) { }
}

export class ReportStartTimerFailed implements Action {
  type = ActionTypes.REPORTSTARTTIMERFAILED;

  constructor(public payload?: any) { }
}

export class ReportEndTimer implements Action {
  type = ActionTypes.REPORENDTIMER;

  constructor(public payload: TimeEntry) { }
}

export class ReportEndTimerSuccessful implements Action {
  type = ActionTypes.REPORTENDTIMERSUCCESSFUL;

  constructor(public payload?: any) { }
}

export class ReportEndTimerFailed implements Action {
  type = ActionTypes.REPORTENDTIMERFAILED;

  constructor(public payload?: any) { }
}

export class TimerStoppedFromServerAction implements Action {
  type = ActionTypes.TIMERSTOPPEDFROMSERVER;

  constructor(public payload: TimeEntry) { }
}

export class StartTimerFailedAction implements Action {
  type = ActionTypes.STARTTIMERFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class EndTimerAction implements Action {
  type = ActionTypes.ENDTIMER;

  constructor(public payload: TimeEntry) { }
}

export class EndTimerSuccessfulAction implements Action {
  type = ActionTypes.ENDTIMERSUCCESS;

  constructor(public payload: TimeEntry) { }
}

export class EndTimerNoDispatchSuccessfulAction implements Action {
  type = ActionTypes.ENDTIMERNODISPTACHSUCCESS;

  constructor(public payload: TimeEntry) { }
}

export class EndTimerFailedAction implements Action {
  type = ActionTypes.ENDTIMERFAILED;

  constructor(public payload: any) { }
}

export class SaveTimeEntryToLocalDBAction implements Action {
  type = ActionTypes.SAVETIMEENTRYTOLOCALDB;

  constructor(public payload: any) { }
}

export class SaveTimeEntryToLocalDBSuccessAction implements Action {
  type = ActionTypes.SAVETIMEENTRYTOOLOCALDBSUCCESSFUL;

  constructor(public payload: TimeEntry | TimeEntry[]) { }
}

export class SaveTimeEntryToLocalDBFailedAction implements Action {
  type = ActionTypes.SAVETIMEENTRYTOLOCALDBFAILED;

  constructor(public payload?: ApiErrorResponse) { }
}

export class LoadTimeEntriesFromLocalDBAction implements Action {
  type = ActionTypes.LOADTIMEENTRIESFROMLOCALDB;

  constructor(public payload: [Date, Date, UUID]) { }
}

export class LoadTimeEntriesFromLocalDBSuccessAction implements Action {
  type = ActionTypes.LOADTIMEENTRIESFROMLOCALDBSUCCESSFUL;

  constructor(public payload: TimeEntry[]) { }
}

export class LoadTimeEntriesFromLocalDBFailedAction implements Action {
  type = ActionTypes.LOADTIMEENTRIESFROMLOCALDBFAILED;

  constructor(public payload?: any) { }
}

export class LoadCompanyTimeEntriesFromLocalDbAction implements Action {
  type = ActionTypes.LOADCOMPANYTIMEENTRIESFROMLOCALDB;

  constructor(public payload: [Date, Date, UUID]) { }
}

export class LoadCompanyTimeEntriesFromLocalDbFailureAction implements Action {
  type = ActionTypes.LOADCOMPANYTIMEENTRIESFROMLOCALDBFAILED;

  constructor(public payload?: any) { }
}

export class LoadCompanyTimeEntriesFromServerAction implements Action {
  type = ActionTypes.LOADCOMPANYTIMEENTRIESFROMSERVER;

  constructor(public payload: [Date, Date, UUID]) { }
}

export class LoadCompanyTimeEntriesFromServerFailureAction implements Action {
  type = ActionTypes.LOADCOMPANYTIMEENTRIESFROMSERVERFAILED;

  constructor(public payload?: any) { }
}

export class SaveTimeEntryToServerAction implements Action {
  type = ActionTypes.SAVETIMEENTRYTOSERVER;

  constructor(public payload: any) { }
}

export class SaveTimeEntryToServerSuccessfulAction implements Action {
  type = ActionTypes.SAVETIMEENTRYTOSERVERSUCCESSFUL;

  constructor(public payload: any) { }
}

export class SaveTimeEntryToServerFailedAction implements Action {
  type = ActionTypes.SAVETIMEENTRYTOSERVERFAILED;

  constructor(public payload: any) { }
}

export class LoadTimeEntriesFromServerAction implements Action {
  type = ActionTypes.LOADTIMEENTRIESFROMSERVER;

  constructor(public payload: [Date, Date, UUID]) { }
}

export class LoadTimeEntriesFromServerSuccessAction implements Action {
  type = ActionTypes.LOADTIMEENTRIESFROMSERVERSUCCESSFUL;

  constructor(public payload: TimeEntry[]) { }
}

export class LoadTimeEntriesFromServerFailedAction implements Action {
  type = ActionTypes.LOADTIMEENTRIESFROMSERVERFAILED;

  constructor(public payload: any) { }
}

export class MergeTimeEntriesAction implements Action {
  type = ActionTypes.MERGETIMEENTRIES;

  constructor(public payload: [TimeEntry[], TimeEntry[]]) { }
}

export class OpenAddTimeDialog implements Action {
  type = ActionTypes.OPENADDTIMEDIALOG;

  constructor(public payload?: any) { }
}

export class SubmitTimeEntryAction implements Action {
  type = ActionTypes.SUBMITTIMEENTRY;

  constructor(public payload: TimeEntry) { }
}

export class SubmitTimeEntrySuccessfulAction implements Action {
  type = ActionTypes.SUBMITTIMEENTRYSUCCESSFUL;

  constructor(public payload: TimeEntry) { }
}

export class SubmitTimeEntryFailedAction implements Action {
  type = ActionTypes.SUBMITTIMEENTRYFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class DeleteTimeEntryAction implements Action {
  type = ActionTypes.DELETETIMEENTRY;

  constructor(public payload: any) { }
}

export class DeleteTimeEntrySuccessfulAction implements Action {
  type = ActionTypes.DELETETIMEENTRYSUCCESSFUL;

  constructor(public payload: TimeEntry) { }
}

export class DeleteTimeEntryFailedAction implements Action {
  type = ActionTypes.DELETETIMEENTRYFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export class EditTimeEntriesAction implements Action {
  type = ActionTypes.EDITTIMEENTRIES;

  constructor(public payload: TimeEntry[]) { }
}

export class EditTimeEntriesSuccessfulAction implements Action {
  type = ActionTypes.EDITTIMEENTRIESSUCCESSFUL;

  constructor(public payload: TimeEntry[]) { }
}

export class EditTimeEntriesFailedAction implements Action {
  type = ActionTypes.EDITTIMEENTRIESFAILED;

  constructor(public payload: ApiErrorResponse) { }
}

export type Actions =
  | StartTimerAction
  | StartTimerSuccessfulAction
  | EndTimerFailedAction
  | EndTimerSuccessfulAction
  | EndTimerAction
  | StartTimerFailedAction
  | SaveTimeEntryToLocalDBSuccessAction
  | SaveTimeEntryToLocalDBAction
  | SaveTimeEntryToLocalDBFailedAction
  | LoadTimeEntriesFromLocalDBAction
  | LoadTimeEntriesFromLocalDBSuccessAction
  | LoadTimeEntriesFromLocalDBFailedAction
  | SaveTimeEntryToServerAction
  | SaveTimeEntryToServerSuccessfulAction
  | SaveTimeEntryToServerFailedAction
  | LoadTimeEntriesFromServerAction
  | LoadTimeEntriesFromServerSuccessAction
  | LoadTimeEntriesFromServerFailedAction
  | MergeTimeEntriesAction
  | OpenAddTimeDialog
  | SubmitTimeEntryAction
  | SubmitTimeEntrySuccessfulAction
  | SubmitTimeEntryFailedAction
  | DeleteTimeEntryAction
  | DeleteTimeEntrySuccessfulAction
  | DeleteTimeEntryFailedAction
  | ReportEndTimer
  | ReportEndTimerFailed
  | ReportEndTimerSuccessful
  | EditTimeEntriesAction
  | EditTimeEntriesSuccessfulAction
  | EditTimeEntriesFailedAction
  | OpenTimerDialogSuccessfulAction
  | CheckIfTimerRunningSuccessfulAction
  | CheckIfTimerRunningFailedAction
  | CheckIfTimerRunningAction
  | EndTimerNoDispatchSuccessfulAction
  | TimerStoppedFromServerAction;
