import { createAction, props } from '@ngrx/store';
import { type } from '../../util';

/**
 * These are the action types that are exported for the LoginUser action
 */
export const ActionTypes = {
  FORGOTPASSWORD: type('[Forgot Password] Sending Forgot Password'),
  PASSWORDCONFIRMATIONSUCCESS: type('[Forgot Password] Password Confirmation Sent'),
  PASSWORDCONFIRMATIONERROR: type('[Forgot Password] Password Confirmation Error'),
  EMAILVALIDATIONERROR: type('[Forgot Password] Invalid email selected'),
  RESETPASSWORDCONFIRMATION: type('[Forgot Password] Reset Password Confirmation')
};

export const ForgotPasswordAction = createAction(ActionTypes.FORGOTPASSWORD, props<{payload: string}>())
export const PasswordConfirmationSuccessAction = createAction(ActionTypes.PASSWORDCONFIRMATIONSUCCESS);
export const PasswordConfirmationErrorAction = createAction(ActionTypes.PASSWORDCONFIRMATIONERROR, props<{payload: string}>());
export const ResetPasswordConfirmationAction = createAction(ActionTypes.RESETPASSWORDCONFIRMATION);


export type Actions =
  typeof PasswordConfirmationErrorAction |
  typeof ForgotPasswordAction |
  typeof PasswordConfirmationSuccessAction |
  typeof ResetPasswordConfirmationAction;

