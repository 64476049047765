import { ExternalInvoicingSystems, BillingStatus, DayOfWeek, AccountActive, Status, TimeOfDay } from '../shared/enums';
import { Contact } from './Client';
import { CompanySettings } from './companysettings';
import { UUID } from 'angular2-uuid';

export class UserSettings {
  accountActive: AccountActive;
  address1: string;
  address2: string;
  billingCreationDate: String;
  billingStatus: BillingStatus;
  companyName: string;
  companySettings: CompanySettings;
  creationDateTime: Date;
  currency: string;
  defaultInvoiceProvider: ExternalInvoicingSystems;
  email: string;
  externalInvoicingSystems: ExternalInvoicingSystems[] = [];
  externalInvoiceDatasources: ExternalInvoicingSystems[] = [];
  firstName: string;
  globalBilledRate: number;
  logoUrl: string;
  invoiceApprovalSettings: InvoiceApprovalSettings;
  isTrialBannerHidden: boolean;
  timeZone: string;
  lastName: string;
  locale: string;
  primaryContact: Contact;
  runningTimer: number;
  subscriptionDetails: UserSubscriptionDetails;
  taxTypes: TaxType[];
  teamProvider: string;
  userId?: string;
  usersInCompany: number;
  weekStart: DayOfWeek;
  weekEnd: DayOfWeek;

  constructor(data?) {
    if (data) {
      if (data.firstName) {
        this.firstName = data.firstName;
      }
      if (data.lastName) {
        this.lastName = data.lastName;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.weekStart !== null && data.weekStart !== undefined) {
        this.weekStart = data.weekStart;
      }
      if (data.currency) {
        this.currency = data.currency;
      }
      if (data.locale) {
        this.locale = data.locale;
      }
      if (data.weekEnd) {
        this.weekEnd = data.weekEnd;
      }
      if (data.globalBilledRate !== null && data.globalBilledRate !== undefined) {
        this.globalBilledRate = data.globalBilledRate;
      }
      if (data.timeZone) {
        this.timeZone = data.timeZone;
      }
      if (data.externalInvoicingSystems !== null && data.externalInvoicingSystems !== undefined) {
        this.externalInvoicingSystems = data.externalInvoicingSystems;
      }
      if (data.externalInvoiceDatasources) {
        this.externalInvoiceDatasources = data.externalInvoiceDatasources;
      }
      if (data.runningTimer) {
        this.runningTimer = data.runningTimer;
      }
      if (data.creationDateTime) {
        this.creationDateTime = data.creationDateTime;
      }
      if (data.companyName) {
        this.companyName = data.companyName;
      }
      if (data.billingCreationDate) {
        this.billingCreationDate = data.billingCreationDate;
      }
      if (data.billingStatus !== null && data.billingStatus !== undefined) {
        this.billingStatus = data.billingStatus;
      }
      if (data.usersInCompany) {
        this.usersInCompany = data.usersInCompany;
      }
      if (data.isTrialBannerHidden) {
        this.isTrialBannerHidden = data.isTrialBannerHidden;
      }
      if (data.subscriptionDetails) {
        this.subscriptionDetails = data.subscriptionDetails;
      }
      if (data.accountActive !== null && data.accountActive !== undefined) {
        this.accountActive = data.accountActive;
      }
      if (data.companySettings) {
        this.companySettings = data.companySettings;
      }
      if (data.userId) {
        this.userId = data.userId;
      }
      if (data.teamProvider) {
        this.teamProvider = data.teamProvider;
      }
      if (data.defaultInvoiceProvider) {
        this.defaultInvoiceProvider = data.defaultInvoiceProvider;
      }
      if (data.logoUrl) {
        this.logoUrl = data.logoUrl;
      }
      if (data.address1) {
        this.address1 = data.address1;
      }

      if (data.address2) {
        this.address2 = data.address2
      }
      if (data.taxTypes) {
        this.taxTypes = data.taxTypes;
      }
      if (data.primaryContact) {
        this.primaryContact = data.primaryContact;
      }
      
    }
  }
}

export class TaxType {
  id?: string;
  taxTypeName: string;
  taxPercentage: number;
  showTaxRate: boolean = false;
  companyId: UUID;
  status: Status;

  constructor(data?) {
    if (data) {
      if (data.id) {
        this.id = data.id;
      }
      if (data.taxTypeName) {
        this.taxTypeName = data.taxTypeName;
      }
      if (data.taxPercentage) {
        this.taxPercentage = data.taxPercentage;
      }
      if (data.showTaxRate) {
        this.showTaxRate = data.showTaxRate;
      }
      if (data.companyId) {
        this.companyId = data.companyId;
      }
      if (data.status) {
        this.status = data.status;
      }
    }
  }
}

export class InvoiceApprovalSettings {
  sendApprovals: boolean;
  reportDay?: DayOfWeek;
  timeOfDay?: TimeOfDay;
}

export class UserSubscriptionDetails {
  id: String;
  customerId: string;
  currencyCode: string;
  planUnitPrice: number;
  planId: string;
  currentTermStart: Date;
  nextBillingAt: Date;
  createdAt: Date;
  activatedAt: Date;
}