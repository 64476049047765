import { Actions, ActionTypes } from '../actions/ftux';
import { OAuthIdent } from '../../models/OAuthIdent';
import { SuccessStatus, ExternalInvoicingSystems } from '../../shared/enums';
import { ProviderState, Provider } from '../../models/external-invoice';

export interface State {
  showSecondScreen: boolean;
  showThirdScreen: boolean;
  showInitial: boolean;
  providers: ProviderState;
  importLoading: boolean;
  error: string;
  loading: boolean;
  complete: boolean;
}

export const initialState: State = {
  showInitial: true,
  showSecondScreen: false,
  showThirdScreen: false,
  importLoading: false,
  loading: false,
  error: null,
  providers: null,
  complete: null
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case ActionTypes.GOTOSECONDSLIDE: {
      return Object.assign({}, state, {
        showInitial: false,
        showSecondScreen: true,
        showThirdScreen: false,
      });
    }
    case ActionTypes.GOTOTHRIRDSLIDE: {
      return Object.assign({}, state, {
        showInitial: false,
        showSecondScreen: false,
        showThirdScreen: true,
      });
    }

    case ActionTypes.GENERATEUSERTOKEN: {
      const authInfo: OAuthIdent = action.payload;
      return Object.assign({}, state, {
        error: initialState.error,
        showInitial: false,
        showSecondScreen: false,
        showThirdScreen: true,
        loading: true,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[authInfo.provider]]: {
            connectionStatus: SuccessStatus.IsLoading
          }
        })
      });
    }

    case ActionTypes.STARTUSERAUTHORIZATION: {
      return Object.assign({}, state, {
        loading: true,
        error: initialState.error,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.serviceName]]: {
            connectionStatus: SuccessStatus.IsLoading
          }
        })
      });
    }

    case ActionTypes.USERAUTHORIZATIONERROR: {
      return Object.assign({}, state, {
        loading: false,
        error: !action.payload.accountInActive ? `There was an error connecting your ${action.payload.service} account.\nTry again later.` : null,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.service]]: {
            connectionStatus: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
          }
        })
      });
    }

    case ActionTypes.GENERATEUSERTOKENSUCCESSFUL: {
      return Object.assign({}, state, {
        error: null,
        loading: false,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: SuccessStatus.Success
          }
        }),
        complete: true
      });
    }

    case ActionTypes.GENERATEUSERTOKENERROR: {
      return Object.assign({}, state, {
        error: null,
        loading: false,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive
          }
        }),
        complete: false
      });
    }

    case ActionTypes.GENERATEUSERTOKENCOMPLETE: {
      return Object.assign({}, state, {
        error: null,
        loading: false,
        complete: null
      });
    }


    case ActionTypes.EXTERNALINVOICEPROVIDERLOGIN: {
      return Object.assign({}, state, {
        error: null,
        loading: false,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: SuccessStatus.IsLoading
          }
        })
      });
    }

    case ActionTypes.EXTERNALINVOICEPROVIDERLOGINSUCCESS: {
      return Object.assign({}, state, {
        error: null,
        loading: false,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: (action.payload.billComTeamProvider) ? SuccessStatus.Enabled : SuccessStatus.Success
          }
        }),
        complete: true
      });
    }

    case ActionTypes.EXTERNALINVOICEPROVIDERLOGINFAILURE: {
      return Object.assign({}, state, {
        error: null,
        loading: false,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: (action.payload.billComTeamProvider)
              ? SuccessStatus.Enabled
              : (!action.payload.error.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive)
          }
        }),
        complete: false
      });
    }

    case ActionTypes.EXTERNALINVOICEPROVIDERLOGINCOMPLETE: {
      const provider = state.providers[ExternalInvoicingSystems[action.payload.provider]] as Provider;
      const connectionStatus = (provider.connectionStatus === SuccessStatus.Error || action.payload.billComTeamProvider)
        ? SuccessStatus.Enabled
        : provider.connectionStatus;

      return Object.assign({}, state, {
        error: null,
        loading: false,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: { connectionStatus }
        }),
        complete: null
      });
    }

    case ActionTypes.GETREQUESTTOKEN: {
      const provider = action.payload;
      return Object.assign({}, state, {
        error: initialState.error,
        loading: true,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[provider.serviceName]]: {
            connectionStatus: SuccessStatus.IsLoading
          }
        })
      });
    }

    case ActionTypes.GENERATEUSERTOKENERROR: {
      return Object.assign({}, state, {
        providers: Object.assign({}, state.providers, {
          loading: false,
          error: !action.payload.accountInActive ? `There was an error connecting your ${action.payload.provider} account.\nTry again later.` : null,
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
          }
        })
      });
    }

    case ActionTypes.GETREQUESTTOKENERROR: {
      return Object.assign({}, state, {
        loading: false,
        error: !action.payload.accountInActive ? `There was an error connecting your ${action.payload.provider} account.\nTry again later.` : null,
        providers: Object.assign({}, state.providers, {
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: !action.payload.accountInActive ? SuccessStatus.Error : SuccessStatus.AccountInActive,
          }
        })
      });
    }

    case ActionTypes.DISCONNECTPROVIDER: {
      return {
        ...state,
        loading: true,
        providers: {
          ...state.providers,
          [ExternalInvoicingSystems[action.payload.name]]: {
            connectionStatus: SuccessStatus.IsLoading
          }
        }
      }
    }

    case ActionTypes.DISCONNECTPROVIDERSUCCESSFUL: {
      return {
        ...state,
        loading: false,
        error: null,
        providers: {
          ...state.providers,
          [ExternalInvoicingSystems[action.payload.name]]: {
            connectionStatus: SuccessStatus.Enabled
          }
        }
      }
    }

    case ActionTypes.SETEXTERNALPROVIDERSTATUS: {
      return {
        ...state,
        providers: {
          ...state.providers,
          [action.payload.provider]: {
            connectionStatus: action.payload.status
          }
        }
      }
    }

    case ActionTypes.DISCONNECTPROVIDERERROR: {
      return {
        ...state,
        loading: false,
        error: !action.payload.accountInActive ? 'Error disconnecting provider. Please try again.' : null,
        providers: {
          ...state.providers,
          [ExternalInvoicingSystems[action.payload.provider]]: {
            connectionStatus: SuccessStatus.Success
          }
        }
      }
    }

    case ActionTypes.DISABLEPROVIDERS: {
      return {
        ...state,
        loading: true,
        providers: Object.assign({}, {
          [ExternalInvoicingSystems.QuickBooks]: {
            connectionStatus: SuccessStatus.Disabled
          },
          [ExternalInvoicingSystems.Cartwheel]: {
            connectionStatus: SuccessStatus.Disabled
          },
          // ,
          // [ExternalInvoicingSystems.Veem]: {
          //   connectionStatus: SuccessStatus.Disabled
          // },
          // [ExternalInvoicingSystems.FreshBooks]: {
          //   connectionStatus: SuccessStatus.Disabled,
          // },
          [ExternalInvoicingSystems.Xero]: {
            connectionStatus: SuccessStatus.Disabled
          },
          // [ExternalInvoicingSystems.Qwil]: {
          //   connectionStatus: SuccessStatus.Disabled
          // },
          // [ExternalInvoicingSystems.BillCom]: {
          //   connectionStatus: SuccessStatus.Disabled
          // },
        })
      }
    }

    case ActionTypes.GETCURRENTPROVIDERSUCCESSFUL: {
      const connectedProviders = action.payload.map(externalInvoiceSystem => ({
        [externalInvoiceSystem]: {
          connectionStatus: SuccessStatus.Success
        }
      }));

      return {
        ...state,
        error: null,
        loading: false,
        // Must use object assign here
        providers: Object.assign({}, {
          [ExternalInvoicingSystems.QuickBooks]: {
            connectionStatus: SuccessStatus.Enabled
          },
          [ExternalInvoicingSystems.Cartwheel]: {
            connectionStatus: SuccessStatus.Enabled
          },
          // ,
          // [ExternalInvoicingSystems.Veem]: {
          //   connectionStatus: SuccessStatus.Enabled
          // },
          // [ExternalInvoicingSystems.FreshBooks]: {
          //   connectionStatus: SuccessStatus.Enabled,
          // },
          [ExternalInvoicingSystems.Xero]: {
            connectionStatus: SuccessStatus.Enabled
          },
          // [ExternalInvoicingSystems.Qwil]: {
          //   connectionStatus: SuccessStatus.Enabled
          // },
          // [ExternalInvoicingSystems.BillCom]: {
          //   connectionStatus: SuccessStatus.Enabled
          // }
        }, ...connectedProviders)
      }
    }

    case ActionTypes.RESETEXTERNALPROVIDERS: {
      return {
        ...state,
        providers: {
          [ExternalInvoicingSystems.QuickBooks]: {
            connectionStatus: SuccessStatus.Enabled
          },
          [ExternalInvoicingSystems.Cartwheel]: {
            connectionStatus: SuccessStatus.Enabled
          },
          // [ExternalInvoicingSystems.Veem]: {
          //   connectionStatus: SuccessStatus.Enabled
          // },
          // [ExternalInvoicingSystems.FreshBooks]: {
          //   connectionStatus: SuccessStatus.Enabled,
          // },
          [ExternalInvoicingSystems.Xero]: {
            connectionStatus: SuccessStatus.Enabled
          }
          // [ExternalInvoicingSystems.Qwil]: {
          //   connectionStatus: SuccessStatus.Enabled
          // },
          // [ExternalInvoicingSystems.BillCom]: {
          //   connectionStatus: SuccessStatus.Enabled
          // }
        }
      }
    }

    case ActionTypes.IMPORTEXTERNALCLIENTS: {
      return Object.assign({}, state, {
        importLoading: true
      });
    }

    default:
      return state;
  }
}

export const getSecondSlideState = (state: State) => state.showSecondScreen;
export const getThirdSlideState = (state: State) => state.showThirdScreen;
export const getProvidersState = (state: State) => state.providers;
export const getInitialSlideState = (state: State) => state.showInitial;
export const getImportLading = (state: State) => state.importLoading;
export const getLoading = (state: State) => state.loading;
export const getFtuxError = (state: State) => state.error;
export const getLoginComplete = (state: State) => state.complete;
